import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Breadcrumbs.scss';

const MAX_LENGTH = 12;

const Breadcrumbs = ({ steps, relative, className }: any) => {
  return (
    <div className={`${styles.container} ${className}`} style={relative ? { margin: 0, width: '100%' } : {}}>
      <Link to="/" className={styles.home}><i className="icon-home" /></Link>
      <div className={styles['items-container']}>
        {
          steps.reverse().map((s, i) => {
            return <Link to={s.url} style={{ zIndex: 100 + i }} className={`${i > 0 ? 'show-for-large' : ''} ${styles.item} ${i === 0 ? styles['last-of-mobile'] : ''}`} key={`breadcrumb-${i}`}>{s.title.length > MAX_LENGTH ? `${s.title.substring(0, MAX_LENGTH)}...` : s.title}</Link>;
          })
        }
      </div>
    </div>
  );
};

Breadcrumbs.propTypes = {
  className: PropTypes.string,
  steps: PropTypes.array,
  relative: PropTypes.bool
};

Breadcrumbs.defaultProps = {
  className: '',
  steps: [],
  relative: false
};

export default Breadcrumbs;
