import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';

import Spinner from '../common/spinner/Spinner';
import DetailForm from './DetailForm';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  deviceId: string,
  recipeId?: string,
  onCompleted: Function
}

type State = {
};

class Detail extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  render() {
    const { lang } = this.context;

    return (
      <Query query={queries.recipes.detail} variables={{ id: this.props.recipeId }} skip={!this.props.recipeId} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.recipeId) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;

          return (
            <Query query={queries.devices.detail} variables={{ id: this.props.deviceId }} fetchPolicy="network-only">
              {(meta) => {
                if (meta.loading) return <Spinner absolute />;
                if (meta.error) return `Error: ${meta.error.toString()}`;
                if (!meta.data) return null;

                return <DetailForm recipe={data ? data.recipe : null} device={meta.data.device} meta={meta.data.calybraPressingData} onCompleted={this.props.onCompleted} />;
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

export default Detail;
