import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import styles from './Aside.scss';

type Props = {
  device: any
}

type State = {
  alarms: Array<any>,
  updatedAt: any
};

class LiveAlarms extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    stompClient: PropTypes.object
  }

  state = {
    alarms: [],
    updatedAt: null
  }

  componentDidMount() {
    const { lang } = this.context;

    sessionStorage.setItem(`${this.props.device.remoteId}:alarms`, '[]');
    const setAlarms = (res) => {
      if (window.VERBOSE_LIVE_MSG) {
        console.log('alarms', res.content);  // eslint-disable-line
      }
      if (res.content && res.content.message && res.content.message instanceof Array) {
        this.setState({ alarms: res.content.message, updatedAt: moment() });
        const transalted = res.content.message.map(a => lang('alarms', a).s);
        sessionStorage.setItem(`${this.props.device.remoteId}:alarms`, JSON.stringify(transalted));
      }
    };
    const clearAlarms = () => {
      this.setState({ alarms: [], updatedAt: moment() });
      sessionStorage.setItem(`${this.props.device.remoteId}:alarms`, '[]');
    };
    this.context.stompClient.connect().then(() => {
      this.context.stompClient.streamFrom([
        { deviceId: this.props.device.remoteId, channel: 'feedback', topic: 'live-alarms', callback: setAlarms },
        { deviceId: this.props.device.remoteId, channel: 'feedback', topic: 'live-clear-alarms', callback: clearAlarms }
      ]).then((streams) => {
        this.streams = streams;
      }).catch(() => {
        console.error('Unable to subscribe to the alarm streams'); // eslint-disable-line
      });
    });
  }

  componentWillUnmount() {
    this.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
  }

  streams = []
  timerRefresh = null

  render() {
    const { lang } = this.context;
    const { alarms, updatedAt } = this.state;

    return (
      <div className="grid-x" style={{ width: '100%' }}>
        <div className="small-12 cell"><span className={styles.title}>{lang('device', 'live-alarms').s}</span></div>
        <div className={`${styles.container} small-12 cell`}>
          { alarms.length === 0 ?
            <div className={styles.placeholder}>
              <span>{lang('global', 'no-alarm').s}</span>
            </div> : null
          }
          { alarms.map((a) => {
            return (
              <div className={`${styles.item} ${styles.alarm}`} key={a}>
                <span>{lang('alarms', a).s}</span>
              </div>
            );
            })
          }
        </div>
        <span className={styles.footer}>{`${lang('global', 'last-update').s}: ${updatedAt ? updatedAt.format('HH:mm:ss') : lang('global', 'NA').s}`}</span>
      </div>
    );
  }
}

export default LiveAlarms;
