import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import moment from 'moment';
import _ from 'lodash';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';

import Spinner from '../common/spinner/Spinner';
import { Permissions } from '../../state/authorization';

import styles from './Production.scss';

type Props = {
  history: any,
  print?: boolean
}

type State = {
  history: any
};

const hisotyrDeleteMutation = gql`
  mutation deleteProductionHistory($id: ID!) {
    deleteProductionHistory(id: $id) { id }
  }
`;

class ProductionHistory extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    can: PropTypes.func,
    stompClient: PropTypes.object,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  componentWillMount() {
    if (this.props.history) {
      this.setState({ history: this.props.history });
    }
    const updateHistory = (res) => {
      if (window.VERBOSE_LIVE_MSG) {
        console.log('update history 1', res.content);  // eslint-disable-line
      }
      if (res.content && res.content.message && res.content.message.productionHistoryId && res.content.message.productionHistoryId === this.props.history.id) {
        const { history } = this.state;

        let json;
        try {
          json = JSON.parse(history.status);
        } catch (reason) {
          return;
        }

        let idx = null;
        if (res.content.message.currentRecipeIndex !== null && res.content.message.currentRecipeIndex !== undefined) {
          idx = `${res.content.message.currentRecipeIndex}`;
          if (!json[idx]) {
            json[idx] = {};
          }
          json[idx].status = 'pending';
        }
        if (res.content.message.recipeIndex !== null && res.content.message.recipeIndex !== undefined) {
          idx = `${res.content.message.recipeIndex}`;
          if (!json[idx]) {
            json[idx] = {};
          }
          json[idx].status = 'pending';
        }
        if (res.content.message.prevRecipeIndex !== null && res.content.message.prevRecipeIndex !== undefined) {
          idx = `${res.content.message.prevRecipeIndex}`;
          json[idx].status = 'completed';
        }

        if (res.content.message.currentRecipeStartTime) {
          json[idx].start_time = res.content.message.currentRecipeStartTime;
        }
        if (res.content.message.currentRecipeUserCode) {
          json[idx].user_code = res.content.message.currentRecipeUserCode;
        }
        if (res.content.message.producedPieces) {
          json[idx].produced_pieces = res.content.message.producedPieces;
        }
        if (res.content.message.lybra && res.content.message.lybra.goodPieces) {
          if (!json[idx].lybra) {
            json[idx].lybra = { good_pieces: res.content.message.lybra.goodPieces };
          } else {
            json[idx].lybra.good_pieces = res.content.message.lybra.goodPieces;
          }
        }
        if (res.content.message.workingTime) {
          json[idx].working_time = res.content.message.workingTime;
        }
        if (res.content.message.idleTime) {
          json[idx].idle_time = res.content.message.idleTime;
        }
        if (res.content.message.prevRecipeEndTime) {
          json[idx].end_time = res.content.message.prevRecipeEndTime;
        }

        this.setState({ history: { ...history, status: JSON.stringify(json) } });
      }
    };
    this.context.stompClient.connect().then(() => {
      this.context.stompClient.streamFrom([
        { deviceId: this.props.history.device.remoteId, channel: 'feedback', topic: 'live-production-status', callback: updateHistory },
      ]).then((streams) => {
        this.streams = streams;
      }).catch(() => {
        console.error('Unable to subscribe to the history streams'); // eslint-disable-line
      });
    });
  }

  componentWillUnmount() {
    this.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
  }

  onHistoryDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('history', 'delete-confirm-title').s,
      message: this.context.lang('history', 'delete-confirm').s,
      buttons: [
        {
          label: this.context.lang('global', 'yes').s,
          onClick: () => action({ variables: { id: (this.props.history || {}).id } })
        },
        { label: this.context.lang('global', 'no').s }
      ]
    });
  }

  onDeleteComplete = () => {
    const { dispatch, push } = this.context;

    dispatch(push(`/devices/${this.state.history.device.id}/production`));
  }

  streams = []

  render() {
    const { lang, can } = this.context;
    const { print } = this.props;

    let json;
    try {
      json = JSON.parse(this.state.history.status);
    } catch (reason) {
      return <div>Production status cannot be retrieved</div>;
    }

    if (window.VERBOSE_LIVE_MSG) {
      console.log('update history 2', json);  // eslint-disable-line
    }

    // const workingTimes = this.state.history.planning.planningItems.map((p, index) => {
    //   const status = json[`${index}`];
    //   let startTime = 0;
    //   if (status && status.start_time) {
    //     startTime = status.start_time;
    //   } else {
    //     return 0;
    //   }
    //   let endTime = 0;
    //   if (status && status.end_time) {
    //     endTime = status.end_time;
    //   } else {
    //     return 0;
    //   }
    //   return Math.floor(((endTime - startTime) / 1000) / 60);
    // });

    const batchStartTime = Math.min(...this.state.history.planning.planningItems.map((p, index) => {
      const status = json[`${index}`];
      if (status && status.start_time) {
        return status.start_time;
      }
      return Number.MAX_SAFE_INTEGER;
    }));

    const batchEndTime = Math.max(...this.state.history.planning.planningItems.map((p, index) => {
      const status = json[`${index}`];
      if (status && status.end_time) {
        return status.end_time;
      }
      return 0;
    }));

    let userCode = 'N/A';
    if (this.state.history.user !== null && this.state.history.user.code !== null) {
      userCode = `${this.state.history.user.firstName} ${this.state.history.user.lastName}`;
    } else if (this.state.history.userCode) {
      ({ userCode } = this.state.history);
    }

    const totalProductionTime = Math.floor((batchEndTime - batchStartTime) / 1000 / 60);

    return (
      <div className="page full-page" style={print ? { padding: 0 } : {}}>
        { print ? null : <Breadcrumbs steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${this.state.history.device.model} - ${this.state.history.device.serial}`, url: `/devices/${this.state.history.device.id}` }, { title: lang('device', 'production').s, url: `/devices/${this.state.history.device.id}/production` }]} relative /> }
        <div className="grid-x">
          <div className="cell small-12">{ print ? null : <Link target="_blank" to={`/print/production-history/${this.state.history.id}`} className="outline-button float-right show-for-large">{lang('global', 'print').s}</Link> }</div>

          <div className="cell small-12" style={{ margin: '1rem 0' }}>
            <div><b>{`${lang('planning', 'planning-ref').s}: `}</b>{this.state.history.planning.number}</div>
            <div><b>{`${lang('production', 'name').s}: `}</b>{this.state.history.planning.description}</div>
            <div><b>{`${lang('production', 'plan-download').s}: `}</b>{userCode}</div>
            <div><b>{`${lang('production', 'start').s}: `}</b>{moment(this.state.history.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            <div><b>{`${lang('production', 'end').s}: `}</b>{batchEndTime ? moment(batchEndTime).format('YYYY-MM-DD HH:mm:ss') : ''}</div>
          </div>

          <div className="cell small-12">
            <div className={styles.table}>
              <div className={`small-12 cell ${styles.header}`} style={{ minWidth: '60rem' }}>
                <span style={{ flex: '1.5' }}>{ lang('recipe', 'recipe').s }</span>
                <span style={{ flex: '1' }}>{ lang('global', 'operator').s }</span>
                <span style={{ flex: '1.5' }}>{ lang('planning', 'start-time').s }</span>
                <span style={{ flex: '1.5' }}>{ lang('planning', 'time').s }</span>
                <span style={{ flex: '1' }}>{ lang('planning', 'pieces').s }</span>
                <span style={{ flex: '0.5' }}>{ lang('recipe', 'status').s }</span>
                {/* <span style={{ flex: '0.5' }}>{ lang('recipe', 'report').s }</span> */}
              </div>
              <div className={`${styles['large-row']} small-12 cell`}>
                {
                  this.state.history.planning.planningItems.map((p) => {
                    const status = json[`${p.order}`];
                    // const status = json[`${index}`];

                    const piecesToDo = p.targetPieces || parseInt((p.tanks * p.tankWeight) / ((p.recipe.profile.weight || p.recipe.profile.target) / 1000), 10);
                    let piecesDone = 0;
                    if (status && status.produced_pieces !== null) {
                      piecesDone = status.produced_pieces;
                    }

                    let startTime = '';
                    if (status && status.start_time) {
                      startTime = moment(status.start_time).format('DD/MM/YYYY HH:mm');
                    }
                    let endTime = '';
                    if (status && status.end_time) {
                      endTime = moment(status.end_time).format('DD/MM/YYYY HH:mm');
                    }
                    let workingTime = null;
                    if (status && status.working_time) {
                      workingTime = moment.utc(status.working_time).format('HH:mm:ss');
                    }
                    let idleTime = null;
                    if (status && status.idle_time) {
                      idleTime = moment.utc(status.idle_time).format('HH:mm:ss');
                      if (status.idle_time < 0) {
                        idleTime = 0;
                      }
                    }

                    // Lybra stuff
                    let goodPieces = 0;
                    if (status && status.lybra && status.lybra.good_pieces) {
                      goodPieces = status.lybra.good_pieces;
                      piecesDone = status.lybra.good_pieces;
                    }

                    let operatorCode = 'N/A';
                    // if (this.state.history.user && this.state.history.user.code) {
                    //   operatorCode = this.state.history.user.code;
                    // }
                    if (status && status.user_code) {
                      const operator = _.find(this.state.history.customer.users, { code: status.user_code });
                      if (operator) {
                        operatorCode = `${operator.firstName} ${operator.lastName}`;
                      } else {
                        operatorCode = status.user_code;
                      }
                    }

                    let timeRemaining = ((piecesToDo - piecesDone) / (p.recipe.profile.yield / 60)) || null;
                    if (timeRemaining && timeRemaining < 0) {
                      timeRemaining = 0;
                    }
                    const isLybra = this.props.history.device.profile.__typename === 'Lybra';

                    return (
                      <div className={`${print ? styles['item-print'] : styles.item} recipe-status-${status ? status.status : 'none'}`} key={p.id} style={{ minWidth: '60rem' }}>
                        <span style={{ flex: '1.5' }} className={styles.multiline}>
                          <span className={styles.main}>{`${p.recipe.description}`}</span>
                          <span className={styles.detail}>{`${lang('recipe', 'weight').s}: ${p.recipe.profile.weight || p.recipe.profile.target}gr`}</span>
                          <span className={styles.detail}>{`${lang('planning', 'tanks').s}: ${p.tanks || 'NA'}, ${lang('planning', 'tankWeight').s}: ${p.tankWeight || 'NA'}`}</span>
                        </span>
                        <span style={{ flex: '1' }} className={styles.multiline}>
                          <span className={styles.detail}>{operatorCode}</span>
                        </span>
                        <span style={{ flex: '1.5' }} className={styles.multiline}>
                          <span className={styles.detail}>{startTime.split(' ')[0]} <b>{startTime.split(' ')[1]}</b></span>
                          <span className={styles.detail}>{endTime.split(' ')[0]} <b>{endTime.split(' ')[1]}</b></span>
                        </span>
                        <span style={{ flex: '1.5' }} className={styles.multiline}>
                          <span className={styles.detail}>{`${lang('planning', 'time-remaining').s}: ${timeRemaining ? `${Math.floor(timeRemaining)} min` : '-'}`}</span>
                          { workingTime ?
                            <span className={styles.detail}>{`${lang('planning', 'time-working').s}: ${workingTime}`}</span>
                          : null }
                          { idleTime ?
                            <span className={styles.detail}>{`${lang('planning', this.state.history.passive ? 'time-working' : 'time-idle').s}: ${idleTime}`}</span>
                          : null }
                        </span>
                        <span style={{ flex: '1' }} className={styles.multiline}>
                          <span className={styles.detail}>{`${lang('planning', 'target').s}: ${piecesToDo}`}</span>
                          { (isLybra || this.props.history.device.profile.lybra) ? null : <span className={styles.detail}>{`${lang('planning', 'done').s}: ${piecesDone}`}</span> }
                          { (isLybra || this.props.history.device.profile.lybra) ? <span className={styles['detail-lybra']}>{`${lang('planning', 'done').s}: ${goodPieces}`}</span> : null }
                          { (isLybra || this.props.history.device.profile.lybra) ? <Link to={`/production/${this.state.history.id}/${p.id}`} className={styles.report}>{ lang('recipe', 'report').s }</Link> : null }
                        </span>
                        <span style={{ flex: '0.5' }}>{status ? status.status : ''}</span>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div className={`small-12 cell ${styles.item}`}>
            <span><b>{lang('planning', 'total-prod-time').s}: </b>{`${totalProductionTime > 0 ? totalProductionTime : 0} min`}</span>
          </div>
          { print || !can(Permissions.production.productionDelete) ?
            null :
            <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
              <Mutation mutation={hisotyrDeleteMutation} onCompleted={this.onDeleteComplete}>
                {(deleteAction, result) => {
                  return result.loading ? <Spinner /> : <div className="outline-button--subtle small-10 medium-4 cell" onClick={this.onHistoryDelete.bind(null, deleteAction)}>{lang('history', 'delete').s}</div>;
                }}
              </Mutation>
            </div> }
        </div>
      </div>
    );
  }
}

export default ProductionHistory;
