import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import uuid from 'uuid';
import Modal from 'react-responsive-modal';

import Spinner from '../common/spinner/Spinner';

const vncRequest = gql`
  mutation startVncRequest($id: ID!, $feedbackKey: String!) {
    startVncRequest(id: $id, feedbackKey: $feedbackKey) { id }
  }
`;

const refreshVnc = gql`
  mutation refreshVncRequest($id: ID!, $feedbackKey: String!) {
    refreshVncRequest(id: $id, feedbackKey: $feedbackKey) { id }
  }
`;

const closeVnc = gql`
  mutation stopVncRequest($id: ID!, $feedbackKey: String!) {
    stopVncRequest(id: $id, feedbackKey: $feedbackKey) { id }
  }
`;

type Props = {
  device: any,
}

type State = {
  streams: Array<any>,
  vncUri: ?string,
  vncLoading: boolean,
  vncStatus: ?string,
  vncUUID: ?string,
  mobileRotation: string,
  mobileScale: number
}

class Device extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func,
    stompClient: PropTypes.object
  }

  static defaultProps = {
    firmwares: [],
    documents: []
  }

  state = { vncUri: null, vncLoading: false, vncStatus: null, streams: [], vncUUID: null, mobileRotation: '0', mobileScale: 1 }

  onVNCRequest = (action: Function) => {
    const callback = (res) => {
      this.setState({ vncStatus: res.content.message || null });
      if (res.content.vncUri && res.content.vncUri.length > 0) {
        this.setState({ vncUri: res.content.vncUri, vncStatus: null, vncLoading: false });
      }
      if (res.content.status === 'stopped') {
        this.setState({ vncStatus: null, vncLoading: false });
        if (this.refreshInterval) {
          clearInterval(this.refreshInterval);
        }
      }
      if (res.content.status === 'alreadyActive') {
        this.state.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
        setTimeout(() => {
          this.setState({ vncStatus: null, vncLoading: false });
        }, 3000);
      }
      if (res.content.status === 'error') {
        setTimeout(() => {
          this.setState({ vncStatus: null, vncLoading: false });
        }, 30000);
      }
    };

    const vncUUID = uuid();
    this.context.stompClient.connect().then(() => {
      this.context.stompClient.streamFrom([{ deviceId: this.props.device.remoteId, channel: 'feedback', topic: vncUUID, callback }]).then((streams) => {
        this.setState({ streams, vncUUID });

        action({ variables: { id: this.props.device.id, feedbackKey: vncUUID } });
      }).catch(() => {
        this.setState({ vncStatus: null, vncLoading: false });
      });
    });
  }

  onCloseVNC = (action: Function) => {
    const { streams } = this.state;
    this.setState({ vncUri: null, vncLoading: false, streams: [] });

    if (this.refreshInterval) {
      clearInterval(this.refreshInterval);
    }
    streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));

    action({ variables: { id: this.props.device.id, feedbackKey: this.state.vncUUID } });
  }

  onVNCRequestComplete = (action: Function) => {
    this.setState({
      vncLoading: true
    });

    this.refreshInterval = setInterval(() => {
      if (this.state.vncUri) {
        action({ variables: { id: this.props.device.id, feedbackKey: this.state.vncUUID } });
      }
    }, 9000);
  }

  refCallback = (element: any) => {
    if (element) {
      const modal = element.parentNode;
      if (modal) {
        const mobileRotation = modal.clientHeight > modal.clientWidth ? '90deg' : '0';
        let mobileScale = 1;
        if (modal.clientWidth < 810 || modal.clientHeight < 520) {
          if (modal.clientHeight > modal.clientWidth) {
            mobileScale = (modal.clientWidth / 520) - 0.05;
          } else {
            mobileScale = (modal.clientHeight / 520) - 0.05;
          }
        }

        this.setState({ mobileRotation, mobileScale });
      }
    }
  }

  refreshInterval = null

  render() {
    const { lang } = this.context;
    const { mobileScale, mobileRotation } = this.state;

    return (
      <Mutation mutation={refreshVnc}>
        {(refreshAction) => {
          return (
            <Mutation mutation={vncRequest} onCompleted={this.onVNCRequestComplete.bind(null, refreshAction)}>
              {(action, { loading }) => {
                return (
                  <div className="small-12 medium-6 cell card-button" onClick={this.state.vncUri ? null : this.onVNCRequest.bind(null, action)}>
                    {
                      (loading || this.state.vncLoading)
                      ? <span className="button-spinner"><Spinner size={20} /></span>
                      : <span className="icon-vnc button-icon" />
                    }
                    <div className="primary-text">{ lang('device', 'vnc').s }</div>
                    { this.state.vncStatus ? <span className="secondary-text">{this.state.vncStatus}</span> : <span className="secondary-text">{lang('device', 'start-vnc').s}</span> }

                    <Mutation mutation={closeVnc}>
                      {(closeAction) => {
                        return (
                          <Modal classNames={{ overlay: 'vnc-overlay', modal: 'vnc-modal' }} closeOnOverlayClick={false} open={this.state.vncUri !== null} onClose={this.onCloseVNC.bind(null, closeAction)} center styles={{ modal: { minHeight: '550px', minWidth: '850px', display: 'flex', justifyContent: 'center', alignItems: 'center' } }}>
                            { this.state.vncUri
                              ? (
                                <div ref={this.refCallback} className="vnc-container" style={{ transform: `rotate(${mobileRotation})` }}>
                                  <iframe frameBorder="0" src={`${this.state.vncUri}`} title="vnc" style={{ minHeight: '520px', minWidth: '810px', transform: `scale(${mobileScale})` }} />
                                </div>
                              ) : null
                            }
                          </Modal>
                        );
                      }}
                    </Mutation>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Mutation>
    );
  }
}

export default Device;
