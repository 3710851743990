import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Permissions } from '../state/authorization';
import initialState from '../state/initial-state';

import styles from './Header.scss';
import Spinner from './common/spinner/Spinner';

const query = gql`
  query customers($search: String) {
    customers(order: name_ASC, search: $search) {
      results {
        id, name, kind
      }
    }
  }
`;

type Props = {
  session: typeof initialState.session
}

type State = {
  toggleMobileMenu: boolean,
  showCustomers: boolean,
  search: string,
  skip: boolean
}

class Header extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func.isRequired,
    lang: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    router: PropTypes.object.isRequired,
    actions: PropTypes.object.isRequired,
    can: PropTypes.func.isRequired,
  }

  state = {
    toggleMobileMenu: true,
    showCustomers: false,
    search: '',
    skip: false
  }

  onChangeCustomer = (selected?: any) => {
    const { dispatch, push, router } = this.context;
    this.setState({ showCustomers: false, toggleMobileMenu: true });
    dispatch(this.context.actions.session.switchCustomer(selected));
    setTimeout(() => {
      if (router.route.location.pathname.indexOf('/devices') >= 0) {
        dispatch(push('/devices'));
      } else if (router.route.location.pathname.indexOf('/users') >= 0 || router.route.location.pathname.indexOf('/customers') >= 0) {
        dispatch(push('/users'));
      } else if (router.route.location.pathname.indexOf('/settings') >= 0) {
        dispatch(push('/settings'));
      } else {
        dispatch(push('/devices'));
      }
    }, 100);
  }

  onSearch = (element: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.searchInterval) {
      clearTimeout(this.searchInterval);
    }

    this.setState({ search: element.target.value, skip: true });

    this.searchInterval = setTimeout(() => {
      this.setState({ skip: false });
    }, 250);
  }

  onSearchClick = (element: SyntheticInputEvent<HTMLInputElement>) => {
    element.stopPropagation();
  }

  searchInterval = null;

  closeMenuAndFireEvent = (menuItem: any) => {
    this.setState({ toggleMobileMenu: true });
    menuItem.onClick();
  }

  render() {
    const { session } = this.props;
    const { dispatch, lang, replace, router, actions, can } = this.context;
    const { logout } = actions.session;

    const menu = [];

    if (can(Permissions.devices.devicesIndex)) {
      menu.push({ onClick: dispatch.bind(null, replace('/devices')), label: lang('page', 'devices').s, selected: router.route.location.pathname.indexOf('/devices') === 0, icon: 'grid' });
    }
    if (can(Permissions.users.usersIndex)) {
      menu.push({ onClick: dispatch.bind(null, replace('/users')), label: lang('page', 'users').s, selected: router.route.location.pathname.indexOf('/users') === 0, icon: 'users' });
    }

    return (
      <div className={`${styles.container} ${this.state.toggleMobileMenu ? styles.closed : ''}`}>
        <div className={styles['container-logo']}>
          <div className={styles.logo} onClick={dispatch.bind(null, replace('/'))}><img src={require('../images/logo-icon.png')} alt="logo" /></div>
          {menu.map((m, i) => (
            <div key={`menu_${i}`} className={`${styles['menu-item']} ${m.selected ? styles.selected : ''}`} onClick={this.closeMenuAndFireEvent.bind(null, m)} >
              <span className="show-for-large">{m.label}</span>
              <span className={`icon-${m.icon} hide-for-large`} style={{ fontSize: '1.4rem' }} />
            </div>
          ))}
          <div className={`hide-for-large ${styles['menu-toggle']}`} onClick={() => { this.setState({ toggleMobileMenu: !this.state.toggleMobileMenu }); }}>
            <span className="icon-menu" style={{ fontSize: '1.4rem', opacity: '0.6' }} />
          </div>
        </div>
        <div className={styles.divider}>&nbsp;</div>
        <div className={styles['user-container']}>
          <div className={styles['user-button']} onClick={() => this.setState({ showCustomers: !this.state.showCustomers })}>
            {this.state.showCustomers && can(Permissions.home.changeCustomer) ?
              <Query query={query} variables={{ search: this.state.search }} fetchPolicy="network-only" skip={this.state.skip}>
                {({ data, loading, error }) => {
                  if (loading) { return (<ul className={styles.dropdown}><li style={{ position: 'relative', height: '3.5rem' }}><Spinner darkBackground absolute size={20} /></li></ul>); }
                  if (error || !data) { return null; }
                  return (
                    <ul className={styles.dropdown}>
                      <li onClick={this.onChangeCustomer.bind(null, null)}>All</li>
                      {data.customers ? data.customers.results.map(c => <li key={c.id} className={c.kind === 'distributor' ? styles['tag-distributor'] : styles.tag} onClick={this.onChangeCustomer.bind(null, c)}>{c.name}</li>) : null}
                    </ul>
                  );
                }}
              </Query>
            : null }
            {this.state.showCustomers ? (
              <div className={styles['search-container']}>
                <input type="text" placeholder="Search..." value={this.state.search} onClick={this.onSearchClick} onChange={this.onSearch} />
                <span className={styles['search-button']} onClick={() => this.setState({ showCustomers: false, search: '' })}><i className="icon-x" /></span>
              </div>
            ) : (
              <div className={styles['user-info']}>
                <span className={styles['user-name']}><span className={styles['user-role']}>{(session.role || '').substring(0, 1)}</span>{session.first_name}</span>
                <span className={styles['user-customer']}>{session.currentCustomer ? session.currentCustomer.name : 'All'}</span>
              </div>
            )}
          </div>
          <div className={styles['user-button--square']} onClick={logout}>
            <span className={`icon-log-out ${styles.icon}`} />
          </div>
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session
  };
}

// flowlint-line-ignore
export default withRouter(connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(Header));
