import React, { Component } from 'react';
import styles from './Cards.scss';

type Props = {
  className?: string,
  title: string,
  subtitle?: string
}

type State = {}

class Card extends Component<Props, State> {
  render() {
    return (
      <div className={`${styles.container} ${this.props.className || ''}`}>
        <div className={styles.card}>
          {this.props.title}
          <span>{this.props.subtitle}</span>
        </div>
      </div>
    );
  }
}

export default Card;
