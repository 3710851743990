import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Spinner from '../common/spinner/Spinner';
import LinkCard from '../common/card/LinkCard';
import ButtonCard from '../common/card/ButtonCard';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  deviceId: string
}

type State = {
  page: number,
  order: string,
  more: boolean
}

class Plannings extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = { page: 1, order: 'createdAt_ASC', more: false }

  render() {
    const { lang } = this.context;
    const { deviceId } = this.props;
    const { page, order, more } = this.state;

    return (
      <div className="page">
        <Query query={queries.plannings.list} variables={{ page, order, deviceId }} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return <Spinner absolute />;
            if (error) return <QueryError lang={lang} error={error.toString()} />;
            if (!data) return null;

            return (
              <div>
                <div className="action-header">
                  <div className="subtitle">{ lang('plannings', 'plannings').s }</div>
                  <Link to={`/devices/${deviceId}/plannings/new`} className="outline-button">{ lang('plannings', 'create').s }</Link>
                </div>
                <div className="grid-x">
                  {
                    data.plannings.slice(0, more ? data.plannings.length : Math.min(data.plannings.length, 6)).map((p) => {
                      const description = p.description || 'No description';
                      return <LinkCard className="small-12 medium-6 large-4 cell" title={`${lang('planning', 'planning').s} #${p.number}`} subtitle={`${description.substring(0, 20)}${description.length > 20 ? '...' : ''}`} extra={`${p.planningItemsCount} ${lang('recipes', 'recipes').s}`} key={p.id} url={`/devices/${deviceId}/plannings/${p.id}`} />;
                    })
                  }
                </div>
                <div className="grid-x">
                  {
                    data.plannings.length > 6
                    ? <ButtonCard className="small-12 medium-6 large-4 cell" subtitle={more ? 'less' : 'more'} onSelect={() => { this.setState({ more: !this.state.more }); }} subtle />
                    : null
                  }
                </div>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Plannings;
