import keyMirror from 'keymirror';
import _ from 'lodash';

export const UserRoles = keyMirror({
  admin: null,
  operator: null,
  superadmin: null,
  distributor: null,
  maintenance_manager: null,
  production_manager: null
});
export type UserRolesType = $Keys<typeof UserRoles>;

export const Permissions = {
  superadmin: keyMirror({
    can_all: null,
  }),
  home: keyMirror({
    homeIndex: null,
    changeCustomer: null
  }),
  customers: keyMirror({
    customersIndex: null,
    customersDetail: null,
    customersCreate: null,
  }),
  users: keyMirror({
    usersIndex: null,
    usersDetail: null,
  }),
  devices: keyMirror({
    devicesIndex: null,
    devicesCreate: null,
    devicesDetail: null,
    devicesDelete: null,
    devicesVNC: null,
    devicesConfig: null,
    devicesRequest: null,
    devicesLiveParams: null,
    devicesAlarmHistory: null,
    deviceAlarmDelete: null,
    deviceSanitationDelete: null
  }),
  recipes: keyMirror({
    recipesIndex: null,
    recipesDetail: null,
  }),
  plannings: keyMirror({
    planningsIndex: null,
    planningsDetail: null
  }),
  production: keyMirror({
    productionIndex: null,
    productionDetail: null,
    productionDelete: null
  }),
  firmwares: keyMirror({
    firmwaresIndex: null,
    firmwaresCreate: null,
    firmwaresDelete: null
  }),
  documents: keyMirror({
    documentsIndex: null,
    documentsCreate: null,
    documentsDelete: null
  }),
  maintenanceOperations: keyMirror({
    maintenanceOperationsUpdate: null,
    maintenanceOperationsDelete: null
  }),
  settings: keyMirror({
    settingsIndex: null
  })
};

const OperatorRole = [
  Permissions.home.homeIndex,
  Permissions.devices.devicesIndex,
  Permissions.production.productionIndex
];

const MaintenanceManagerRole = [
  Permissions.home.homeIndex,
  Permissions.devices.devicesIndex,
  Permissions.devices.devicesDetail,
  Permissions.documents.documentsIndex,
  Permissions.devices.devicesConfig,
  Permissions.devices.devicesRequest,
  Permissions.devices.devicesLiveParams,
  Permissions.devices.devicesVNC,
  Permissions.devices.devicesAlarmHistory
];

const ProductionMangerRole = [
  Permissions.home.homeIndex,
  Permissions.devices.devicesIndex,
  Permissions.devices.devicesDetail,
  Permissions.documents.documentsIndex,
  Permissions.devices.devicesLiveParams,
  Permissions.production.productionIndex,
  Permissions.production.productionDelete
];

const DistributorRole = [
  Permissions.home.homeIndex,
  Permissions.home.changeCustomer,
  Permissions.devices.devicesIndex,
  Permissions.devices.devicesDetail,
  Permissions.documents.documentsIndex,
  Permissions.home.changeCustomer,
  Permissions.devices.devicesConfig,
  Permissions.devices.devicesRequest,
  Permissions.devices.devicesLiveParams,
  Permissions.devices.devicesAlarmHistory,
  Permissions.devices.devicesVNC
];

const AdminRole = [
  ...OperatorRole,
  Permissions.devices.devicesIndex,
  Permissions.devices.devicesDetail,
  Permissions.documents.documentsIndex,
  Permissions.production.productionIndex,
  Permissions.production.productionDelete,
  Permissions.devices.devicesConfig,
  Permissions.devices.devicesRequest,
  Permissions.devices.devicesLiveParams,
  Permissions.devices.devicesAlarmHistory,
  Permissions.devices.devicesVNC,
  Permissions.maintenanceOperations.maintenanceOperationsUpdate,
  ...Object.keys(Permissions.users)
];

const SuperadminRole = [
  ...OperatorRole,
  ...Object.keys(Permissions.superadmin),
];
export const Roles = {
  admin: AdminRole,
  operator: OperatorRole,
  superadmin: SuperadminRole,
  maintenance_manager: MaintenanceManagerRole,
  production_manager: ProductionMangerRole,
  distributor: DistributorRole
};

export const can = (role: UserRolesType, permissions: string | Array<string>) => {
  let res = false;
  if (role && Roles[role]) {
    if (typeof permissions === 'string') {
      res = Roles[role].includes(permissions);
    } else if (_.isArray(permissions)) {
      res = true;
      permissions.forEach((permission) => {
        res = res && Roles[role].includes(permission);
      });
    } else res = false;

    if (Roles[role].includes(Permissions.superadmin.can_all)) res = true;
  }

  return res;
};
