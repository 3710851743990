import keyMirror from 'keymirror';

export default keyMirror({
  LOGIN: null,
  LOGIN_SUCCESS: null,
  LOGIN_FAIL: null,

  LOGOUT: null,
  LOGOUT_SUCCESS: null,

  SWITCH_CUSTOMER: null,

  REFRESH: null,
  REFRESH_SUCCESS: null,
  REFRESH_FAIL: null
});
