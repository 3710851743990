import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import omitDeep from 'omit-deep-lodash';

import initialState from '../../state/initial-state';
import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import queries, { userFields } from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  user?: any,
  session: typeof initialState.session,
  roles: Array<string>,
  onCompleted: Function
}

type State = {
  values: any,
  errors: any,
  search: string
}

const createUser = gql`
  mutation createUser($email: String!, $firstName: String!, $lastName: String!, $password: String!, $role: Role!, $code: Int!, $customer: CustomerRelationAttributes!) {
    createUser(attributes: { email: $email, firstName: $firstName, lastName: $lastName, password: $password, role: $role, code: $code, customer: $customer }) {
      user {
        ${userFields}
      }
    }
  }
`;

const updateUser = gql`
  mutation updateUser($id: ID!, $email: String!, $firstName: String!, $lastName: String!, $password: String, $role: Role!, $code: Int!, $customer: CustomerRelationAttributes!) {
    updateUser(id: $id, attributes: { email: $email, firstName: $firstName, lastName: $lastName, password: $password, role: $role, code: $code, customer: $customer }) {
      user {
        ${userFields}
      }
    }
  }
`;

const userDeleteMutation = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(id: $id) { id }
  }
`;

class DetailForm extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  state = {
    values: { code: 0, customer: {} },
    errors: {},
    search: ''
  }

  componentWillMount() {
    if (this.props.user) {
      this.setState({ values: { ...this.props.user, customer: { id: this.props.user.customer.id, name: this.props.user.customer.name, kind: this.props.user.customer.kind } } });
    } else {
      this.setState({ values: { role: null, customer: { ...this.props.session.customer } } });
    }
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, [field]: e.target.value } });
  }

  onNumericChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, [field]: parseInt(e.target.value, 10) } });
  }

  onSelectChange = (field: string, option: { label: string, value: string }) => {
    this.setState({ values: { ...this.state.values, [field]: option.value } });
  }

  onCustomerSelect = (option: { label: string, value: string }) => {
    // Note: since the select just passes over the label and value, and we need id, name and kind, we embed the kind in the value as id:kind
    const id = option.value.split(':')[0];
    const kind = option.value.split(':')[1];
    const selectedCustomer = { id, name: option.label, kind };
    let roles = selectedCustomer.kind === 'distributor' ? ['distributor'] : this.props.roles;
    if (selectedCustomer && selectedCustomer.name !== 'Artezen') {
      roles = roles.filter(r => r !== 'superadmin');
    }

    this.setState({ ...this.state, values: { ...this.state.values, role: roles[0], customer: { id, name: option.label, kind } } });
    // flowlint-line-ignore
    this.customerSelectField.blur(); // Select is bugged, if you search and select from the results you need a blur event to see the change
  }

  onCustomerSearch = (refetch: Function, search: string) => {
    refetch({ search });
    this.setState({ search });
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.state.values }, '__typename');
    variables.code = variables.code || 0;
    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    action({ variables });
  }

  onUserDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('user', 'delete-confirm-title').toString(),
      message: this.context.lang('user', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: (this.props.user || {}).id } })
        },
        {
          label: this.context.lang('global', 'no').toString()
        }
      ]
    });
  }

  onDeleteComplete = () => {
    const { dispatch, push } = this.context;

    dispatch(push('/users'));
  }

  validate = (values: any) => {
    const errors = {};
    ['email', 'firstName', 'lastName'].forEach((f) => {
      if (!values[f] || values[f].trim().length === 0) {
        errors[f] = 'empty';
      }
    });
    if (!values.id) {
      if (!values.password || values.password.trim().length === 0) {
        errors.password = 'empty';
      }
    }
    return errors;
  }

  customerSelectField = null

  render() {
    const { values, errors, search } = this.state;
    const { lang } = this.context;

    const selectedCustomer = this.state.values.customer ? { id: this.state.values.customer.id, name: this.state.values.customer.name, kind: this.state.values.customer.kind } : undefined;
    let roles = selectedCustomer && selectedCustomer.kind === 'distributor' ? [{ value: 'distributor', label: lang('roles', 'distributor').s }] : this.props.roles.map(r => ({ value: r, label: lang('roles', r).s }));
    if (!selectedCustomer || selectedCustomer.name.toLowerCase().indexOf('artezen') < 0) {
      roles = roles.filter(r => r.value !== 'superadmin');
    }

    return (
      <div className="page full-page">
        <form className="grid-x" autoComplete="off">
          <Mutation mutation={this.props.user ? updateUser : createUser} onCompleted={this.props.onCompleted}>
            {(action, { loading, error }) => {
              return (
                <div className="cell grid-x">
                  <div className="title cell small-12 medium-6">{ lang('user', this.props.user ? 'edit' : 'new').s }</div>

                  <div className="title-action cell small-12 medium-6">
                    {loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onClick.bind(null, action)} value={lang('user', values.id ? 'edit' : 'create').s} />}
                  </div>
                  <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="user" />
                </div>
              );
            }}
          </Mutation>
          <label htmlFor="userEmail" className="cell small-12 medium-6">
            <div>{ lang('user', 'email').s }</div>
            <input className={errors.email ? 'error' : ''} type="email" data-lpignore="true" name={`email_${Math.random().toString(36).substring(7)}`} value={values.email || ''} onChange={this.onTextChange.bind(null, 'email')} autoComplete="edit-user" />
          </label>
          <label htmlFor="userPassword" className="cell small-12 medium-6">
            <div>{ lang('user', 'password').s }</div>
            <input className={errors.password ? 'error' : ''} type="password" data-lpignore="true" name={`password_${Math.random().toString(36).substring(7)}`}value={values.password || ''} onChange={this.onTextChange.bind(null, 'password')} autoComplete="edit-password" />
          </label>
          <label htmlFor="firstName" className="cell small-12 medium-6">
            <div>{ lang('user', 'firstName').s }</div>
            <input className={errors.firstName ? 'error' : ''} type="text" data-lpignore="true" name={`firstName_${Math.random().toString(36).substring(7)}`} value={values.firstName || ''} onChange={this.onTextChange.bind(null, 'firstName')} />
          </label>
          <label htmlFor="lastName" className="cell small-12 medium-6">
            <div>{ lang('user', 'lastName').s }</div>
            <input className={errors.lastName ? 'error' : ''} type="text" data-lpignore="true" name={`lastName_${Math.random().toString(36).substring(7)}`} value={values.lastName || ''} onChange={this.onTextChange.bind(null, 'lastName')} />
          </label>
          <label htmlFor="role" className="cell small-12 medium-6">
            <div>{ lang('user', 'role').s }</div>
            <Select classNamePrefix="Select" options={roles} value={values.role ? roles.filter(e => e.value === values.role) : undefined} onChange={this.onSelectChange.bind(null, 'role')} clearable={false} searchable={false} />
          </label>
          { this.props.session.role === 'superadmin' ?
            <label htmlFor="customer" className="cell small-12 medium-6">
              <div>{ lang('user', 'customer').s }</div>
              <Query query={queries.customers.list} variables={{ search, page: 1, order: 'name_ASC' }} fetchPolicy="network-only">
                {({ loading, error, data, refetch }) => { // eslint-disable-line
                  if (error) return <QueryError lang={lang} error={error.toString()} />;
                  if (!data || !data.customers) return null;

                  const customersOptions = data.customers.results.map((c) => { return { label: c.name, value: `${c.id}:${c.kind}` }; });
                  return <Select classNamePrefix="Select" ref={(e) => { this.customerSelectField = e; }} options={customersOptions} value={values.customer ? { label: values.customer.name, value: values.customer.id } : undefined} onInputChange={this.onCustomerSearch.bind(null, refetch)} inputValue={search} onChange={this.onCustomerSelect} searchable clearable={false} />;
                }}
              </Query>
            </label>
          : null }
          <label htmlFor="code" className="cell small-12 medium-6">
            <div>{ lang('user', 'code').s }</div>
            <input className={errors.code ? 'error' : ''} type="number" name="code" value={values.code || 0} onChange={this.onNumericChange.bind(null, 'code')} />
          </label>
          { this.props.user ?
            <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
              <Mutation mutation={userDeleteMutation} onCompleted={this.onDeleteComplete}>
                {(deleteAction, result) => {
                  return result.loading ? <Spinner /> : <div className="outline-button--subtle small-10 medium-4 cell" onClick={this.onUserDelete.bind(null, deleteAction)}>{lang('user', 'delete').s}</div>;
                }}
              </Mutation>
            </div>
            : null
          }
        </form>
      </div>
    );
  }
}

export default DetailForm;
