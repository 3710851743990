import type { Dispatch } from 'redux';
import { RSAA } from 'redux-api-middleware';

import types from '../action-types';
import { ApiEndpoints } from '../../globals/constants';
import { getApiEndpoint } from '../utils';

export function login({ email, password } : any) {
  return (dispatch: Dispatch<any>) => {
    const body = { email, password };
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.LOGIN),
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(body),
        types: [
          { type: types.LOGIN, payload: () => ({ email }) },
          types.LOGIN_SUCCESS,
          types.LOGIN_FAIL
        ]
      }
    });
  };
}

export function refresh() {
  return (dispatch: Dispatch<any>) => {
    return dispatch({
      [RSAA]: {
        endpoint: getApiEndpoint(ApiEndpoints.ME),
        method: 'GET',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: true
        },
        types: [
          types.REFRESH,
          types.REFRESH_SUCCESS,
          types.REFRESH_FAIL
        ]
      }
    });
  };
}

export function logout() {
  return (dispatch: Dispatch<any>) => {
    return [
      dispatch({ type: types.LOGOUT_SUCCESS })
    ];
  };
}

export function switchCustomer(customer?: any) {
  return {
    type: types.SWITCH_CUSTOMER, customer
  };
}
