import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';

import initialState from '../../state/initial-state';
import Spinner from '../common/spinner/Spinner';
import DetailForm from './DetailForm';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  userId?: string,
  session: typeof initialState.session,
  roles: Array<string>,
  onCompleted: Function
}

type State = {}

class Detail extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  render() {
    const { lang } = this.context;

    return (
      <Query query={queries.users.detail} variables={{ id: this.props.userId }} skip={!this.props.userId} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.userId) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;

          return <DetailForm session={this.props.session} roles={this.props.roles} user={data ? data.user : null} onCompleted={this.props.onCompleted} />;
        }}
      </Query>
    );
  }
}

export default Detail;
