import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';

import Spinner from '../common/spinner/Spinner';
import DetailForm from './DetailForm';
import Device from './Device';

import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  deviceId?: string,
  onCompleted: Function,
  onFirmwareSent: Function
}

type State = {
  editing: boolean
};

class Detail extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = {
    editing: false
  }

  render() {
    const { lang } = this.context;

    return (
      <Query query={queries.devices.fullDetail} variables={{ id: this.props.deviceId }} skip={!this.props.deviceId} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.deviceId) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;

          if (data && data.device && !this.state.editing) {
            return <Device device={data.device} alarms={data.device.alarms} sanitations={data.device.sanitations} maintenanceOperations={data.device.maintenanceOperations} firmwares={data.firmwares} documents={data.documents} refetch={{ query: queries.devices.fullDetail, variables: { id: this.props.deviceId } }} onEdit={() => { this.setState({ editing: true }); }} onDelete={this.props.onCompleted} onFirmwareSent={this.props.onFirmwareSent} />;
          }
          return <DetailForm device={data ? data.device : null} onCompleted={this.props.onCompleted} />;
        }}
      </Query>
    );
  }
}

export default Detail;
