import type { Element } from 'react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { loadLang, getLang } from 'redux-pagan';
import { connect } from 'react-redux';
import { push, replace } from 'react-router-redux';
import cookies from 'cookies-js';
import Notifications from 'react-notification-system-redux';
import moment from 'moment';
import { withRouter } from 'react-router';
import { StompStreamClient } from 'spacebunny/lib/spacebunny.web';
import { setDefaultLocale } from 'react-datepicker';
import types from '../state/action-types';

import { can } from '../state/authorization';
import initialState from '../state/initial-state';
import actionCreators from '../state/actions';
import Header from '../components/Header';

function getLangData(locale) {
  return require(`../i18n/${locale}.i18n.json`);
}

type Props = {
  dispatch: any,
  lang: any,
  classes: any,
  children: Element<any>;
  push: any,
  replace: any,
  routing: any,
  actions: any,
  session: typeof initialState.session,
  can: typeof can,
  notifications: typeof initialState.notifications,
};
type State = {};

class App extends Component<Props, State> {
  static defaultProps = {
    classes: {},
    notifications: []
  }

  static childContextTypes = {
    dispatch: PropTypes.func,
    lang: PropTypes.func,
    push: PropTypes.func,
    routing: PropTypes.object,
    replace: PropTypes.func,
    actions: PropTypes.object,
    stompClient: PropTypes.object,
    can: PropTypes.func,
  }

  constructor(props) {
    super(props);
    const supported = ['en', 'it'];

    // flowlint-line-ignore
    const userLocale = (navigator.language || navigator.browserLanguage);
    let language = userLocale.split('-')[0] || 'en';

    setDefaultLocale(userLocale);

    if (supported.indexOf(language) === -1) {
      language = 'en';
    }

    // This is used by the mobile apps
    window.postLogin = (payload) => {
      this.props.dispatch({ type: types.LOGIN_SUCCESS, payload });
    };

    moment.locale(userLocale);
    this.props.dispatch(loadLang(cookies.get('lang') || language, getLangData));

    const connectionParams = {
      endpoint: {
        url: window.configs.SPACEBUNNY.ENDPOINT
      },
      client: window.configs.SPACEBUNNY.CLIENT,
      secret: window.configs.SPACEBUNNY.SECRET,
      tls: window.configs.SPACEBUNNY.TLS
    };
    this.stompClient = new StompStreamClient(connectionParams);
  }

  getChildContext() {
    return {
      dispatch: this.props.dispatch,
      lang: this.props.lang,
      push: this.props.push,
      routing: this.props.routing,
      replace: this.props.replace,
      actions: this.props.actions,
      stompClient: this.stompClient,
      can: this.props.can.bind(null, this.props.session.role || 'operator')
    };
  }

  componentDidMount() {
    setTimeout(() => {
      // flowlint-line-ignore
      document.getElementById('loading').style.display = 'none'; document.getElementById('app').style.display = '';
    }, 1000);

    if (this.props.session.authenticated) {
      this.props.actions.session.refresh();
    }
  }

  stompClient = null

  render() {
    const { notifications, session, classes, routing } = this.props;
    const printing = routing.location.pathname.indexOf('/print') === 0;
    return (
      <div className={classes.container} style={session.authenticated && !printing ? { height: '100%', paddingTop: '3.5rem' } : { height: '100%' }}>
        {(session.authenticated && routing.location.pathname !== '/' && !printing) ? <Header session={session} /> : null}
        {React.cloneElement(this.props.children, { session })}
        <Notifications notifications={notifications} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: getLang(state.i18n, 'app'),
    locale: state.i18n.locale,
    notifications: state.notifications,
    routing: state.routing,
    app: state.app,
    session: state.session,
  };
}

function mapDispatchToProps(dispatch: any) {
  const actions = {};
  Object.keys(actionCreators).forEach((k) => {
    actions[k] = bindActionCreators((actionCreators[k] : any), dispatch);
  });
  return { dispatch, push, replace, actions, can };
}

// flowlint-line-ignore
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
