import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import Card from '../common/card/Card';
import LinkCard from '../common/card/LinkCard';

type Props = {
  device: any,
  histories: any
}

type State = {
  histories: any
}

class ProductionHistories extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    stompClient: PropTypes.object
  }

  state = {
    histories: []
  }

  componentWillMount() {
    if (this.props.histories) {
      this.setState({ histories: this.props.histories });
    }

    const updateHistory = (res) => {
      if (window.VERBOSE_LIVE_MSG) {
        console.log('history', res.content);  // eslint-disable-line
      }
      if (res.content && res.content.message && res.content.message.productionHistoryId) {
        const { histories } = this.state;
        const newHistories = histories.map((h) => {
          if (res.content.message.productionHistoryId === h.id) {
            let json;
            try {
              json = JSON.parse(h.status);
            } catch (reason) {
              return h;
            }

            if (res.content.message.currentRecipeIndex !== null && res.content.message.currentRecipeIndex !== undefined) {
              json[`${res.content.message.currentRecipeIndex}`] = { status: 'pending' };
            }
            if (res.content.message.prevRecipeIndex !== null && res.content.message.prevRecipeIndex !== undefined) {
              json[`${res.content.message.prevRecipeIndex}`] = { status: 'completed' };
            }
            return { ...h, status: JSON.stringify(json) };
          }
          return h;
        });
        this.setState({ histories: newHistories });
      }
    };
    this.context.stompClient.connect().then(() => {
      this.context.stompClient.streamFrom([
        { deviceId: this.props.device.remoteId, channel: 'feedback', topic: 'live-production-status', callback: updateHistory },
      ]).then((streams) => {
        this.streams = streams;
      }).catch(() => {
        console.error('Unable to subscribe to the history streams'); // eslint-disable-line
      });
    });
  }

  componentWillUnmount() {
    this.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
  }

  streams = []

  render() {
    const { lang } = this.context;
    const { histories } = this.state;

    return (
      <div className="grid-x" style={{ width: '100%' }}>
        {
          histories.map((h, index) => {
            const description = h.planning.description || lang('recipe', 'no-description').toString();
            let json;
            try {
              json = JSON.parse(h.status);
            } catch (reason) {
              return <Card title="Production status cannot be retrieved" subtitle="Error" />;
            }
            let status = 'green';
            let statusName = 'completed';

            for (let i = 0; i < h.planning.planningItemsCount; i += 1) {
              if (!json[i]) {
                status = 'red';
                statusName = 'not completed';
              } else if (json[i].status === 'pending') {
                  status = 'yellow';
                  statusName = 'working';
                  break;
              }
            }

            return <LinkCard className="small-12 cell" status={status} accessory={index === 0 ? 'icon-play-circle' : undefined} title={`${h.planning.number} - ${description.substring(0, 20)}${description.length > 20 ? '...' : ''}`} subtitle={moment(h.createdAt).format('YYYY-MM-DD HH:mm:ss')} extra={statusName} key={h.id} url={`/production/${h.id}`} />;
          })
        }
      </div>
    );
  }
}

export default ProductionHistories;
