export default {
  i18n: undefined,
  app: {},
  notifications: [],
  session: {
    role: null,
    authenticating: false,
    authenticated: false,
    email: null,
    first_name: null,
    last_name: null,
    id: null,
    access_token: null,
    error: null,
    customer: {
      id: null,
      name: null
    },
    currentCustomer: null
  },
};
