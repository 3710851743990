import type { Element } from 'react';
import React, { Component } from 'react';

import Spinner from '../spinner/Spinner';

import styles from './TabPanel.scss';

type Props = {
  children?: Array<Element<any>>,

  selectedTabClassName: string,
  defaultTabClassName: string,
  panelClassName: string,

  loading: boolean,
  tabs: Array<string>,
  tabsPosition: string,
  selectedTab: number,

  onTabChange?: any,
};

type State = {
  selectedTab: number
};

class TabPanel extends Component<Props, State> {
  static defaultProps = {
    selectedTabClassName: 'tab-selected',
    defaultTabClassName: 'tab-default',
    panelClassName: '',

    loading: false,
    tabs: [],
    tabsPosition: 'top',
    selectedTab: 0
  }

  constructor(props: Props) {
    super(props);
    this.state = { selectedTab: props.selectedTab || 0 };
  }

  shouldComponentUpdate(nextProps: Props) {
    if (this.props.selectedTab !== nextProps.selectedTab) this.setState({ selectedTab: nextProps.selectedTab });
    return true;
  }

  selectTab = (index: number) => {
    const { onTabChange } = this.props;
    this.setState({ selectedTab: index });
    if (onTabChange) onTabChange(index);
  }

  render() {
    const { children, loading, tabs, tabsPosition, selectedTabClassName, defaultTabClassName, panelClassName } = this.props;
    const { selectedTab } = this.state;

    const tabsElement = (
      <div className={`hide-for-large ${styles.tabs}`} >
        {tabs ? tabs.map((tab, index) => (
          <div
            key={`tab_${index}`}
            className={`${styles.tab} ${selectedTab === index ? selectedTabClassName : defaultTabClassName}`}
            onClick={this.selectTab.bind(null, index)}
          >
            {tab}
          </div>
        )) : null}
      </div>
    );

    const panels = children ? children.map((child, index) => {
      return (
        <div key={`tab-item-${index}`} className={`${index === selectedTab ? '' : 'show-for-large'} ${styles.panel} ${panelClassName}`} >
          {child}
        </div>
      );
    }) : null;

    return (
      <div className={styles.container}>
        {tabsPosition && tabsPosition === 'top' ? tabsElement : null}
        <div className={styles['tabs-container']}>
          {loading ?
            (
              <div className="flex-1 center" ><Spinner /></div>
            ) :
            panels
          }
        </div>
        <div className={`hide-for-large ${styles['bottom-margin-separator']}`} />
        {tabsPosition && tabsPosition === 'bottom' ? tabsElement : null}
      </div>
    );
  }
}

export default TabPanel;
