import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Detail from '../components/plannings/Detail';

import { notificationSuccess } from '../state/utils';

type Props = {
  match: any,
  dispatch: Function
};
type State = {};

class PlanningsPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func,
    lang: PropTypes.func
  }

  onEditComplete = (result: any, message: string) => {
    const { match } = this.props;
    const { dispatch, push, lang } = this.context;
    const deviceId = match.params.device;

    this.props.dispatch(notificationSuccess(lang('plannings', message || 'save-success').s));

    if (result.createPlanning && result.createPlanning.id) {
      dispatch(push(`/devices/${deviceId}/plannings/${result.createPlanning.id}`));
    } else if (result.updatePlanning && result.updatePlanning.id) {
      dispatch(push(`/devices/${deviceId}/plannings/${result.updatePlanning.id}`));
    } else {
      dispatch(push(`/devices/${deviceId}/production`));
    }
  }

  onSendCompleted = (result: any) => {
    this.onEditComplete(result, 'send-success');
  }

  render() {
    const { match } = this.props;
    const planningId = match.params.id;
    const deviceId = match.params.device;

    return <Detail planningId={planningId} deviceId={deviceId} onCompleted={this.onEditComplete} onSendCompleted={this.onSendCompleted} />;
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(PlanningsPage);
