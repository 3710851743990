import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';

import Spinner from '../components/common/spinner/Spinner';
import ProductionHistory from '../components/production/ProductionHistory';
import LybraReport from '../components/production/LybraReport';
import queries from '../state/queries';
import QueryError from '../components/common/errors/QueryError';

type Props = {
  match: any
};

type State = {};

class RecipesPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func,
    lang: PropTypes.func
  }

  render() {
    const { match } = this.props;
    const productionId = match.params.id;
    const { planningItemId } = match.params;
    const { lang } = this.context;

    return (
      <Query query={queries.production.detail} variables={{ id: productionId }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          if (planningItemId) {
            return <LybraReport history={data.productionHistory} planningItemId={planningItemId} />;
          }

          return <ProductionHistory history={data.productionHistory} />;
        }}
      </Query>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(RecipesPage);
