import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Detail from '../components/customers/Detail';

type Props = {
  match: any
};
type State = {};

class CustomersPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  onEditComplete = () => {
    const { dispatch, push } = this.context;
    dispatch(push('/users'));
  }

  render() {
    const { match } = this.props;
    const customerId = match.params.id;

    return <Detail customerId={customerId} onCompleted={this.onEditComplete} />;
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(CustomersPage);
