import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Queries from '../../state/queries';
import { Permissions } from '../../state/authorization';

import Spinner from '../common/spinner/Spinner';
import LinkCard from '../common/card/LinkCard';
import Pager from '../common/pager/Pager';
import QueryError from '../common/errors/QueryError';

type Props = {
  customerId: ?string,
}

type State = {
  search: string;
  debounce: boolean;
  page: number;
}

class Customers extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    can: PropTypes.func.isRequired
  }

  state = { search: '', debounce: false, page: 1 }

  onSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.interval) {
      clearTimeout(this.interval);
    }

    this.setState({ search: e.target.value, debounce: true, page: 1 });

    this.interval = setTimeout(() => {
      this.setState({ debounce: false });
    }, 200);
  }

  onPage = (page: number) => {
    this.setState({ page });
  }

  interval = null;

  render() {
    const { lang, can } = this.context;
    const { page } = this.state;

    return (
      <div className="page">
        <div className="grid-x">
          <div className="title medium-8 cell">{ lang('customers', 'customers').s }</div>
          <input type="text" placeholder="Search..." className="small-12 medium-4 cell search" value={this.state.search} onChange={this.onSearch} />
          <div className="spacer" />
        </div>
        <Query query={Queries.customers.list} variables={{ customerId: this.props.customerId, page, search: this.state.search, order: 'name_ASC', perPage: 12 }} fetchPolicy="network-only" skip={this.state.debounce}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner absolute />;
            if (error) return <QueryError lang={lang} error={error.toString()} />;
            if (!data) return null;

            return (
              <div>
                <div className="action-header">
                  <div className="subtitle">{ lang('customers', 'customers').s }</div>
                  { can(Permissions.customers.customersCreate) ?
                    <Link to="/customers/new" className="outline-button">{ lang('customers', 'create').s }</Link>
                    : null
                  }
                </div>
                <div className="grid-x">
                  {
                    data.customers.results.map(c => <LinkCard className="small-12 medium-3 cell" title={c.name} tag={lang('customer', `kind-${c.kind}`).s} tagColor={c.kind === 'client' ? 'tag-bg-orange' : 'tag-bg-blue'} key={c.id} url={`/customers/${c.id}`} />)
                  }
                </div>
                <Pager pages={data.customers.totalPages} current={page} onPage={this.onPage} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Customers;
