import React, { Component } from 'react';
import styles from './Cards.scss';

type Props = {
  className?: string,
  title: string,
  subtitle?: string,
  extra?: string,
  isSelected: bool,
  onSelect: Function
}

type State = {}

class SelectCard extends Component<Props, State> {
  render() {
    return (
      <div className={`${styles.container} ${this.props.className || ''}`}>
        <div className={styles.select} onClick={this.props.onSelect}>
          <div className={styles['select-content']}>
            {this.props.title}
            <span>{this.props.subtitle}</span>
            { this.props.extra ? <span>{this.props.extra}</span> : null }
          </div>
          <i className={`${styles.check} ${this.props.isSelected ? styles.active : ''} ${this.props.isSelected ? 'icon-check' : 'icon-circle'}`} />
        </div>
      </div>
    );
  }
}

export default SelectCard;
