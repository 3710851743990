import React, { Component } from 'react';
import styles from './Cards.scss';

type Props = {
  className?: string,
  title?: string,
  subtitle?: string,
  icon?: string,
  onSelect: Function,
  subtle?: boolean
}

type State = {}

class ButtonCard extends Component<Props, State> {
  render() {
    return (
      <div className={`${styles.container} ${styles.button} ${this.props.className || ''}`} onClick={this.props.onSelect}>
        <div className={`${styles.select} ${this.props.subtle ? styles.subtle : ''}`} onClick={this.props.onSelect}>
          <div className={styles['select-content']}>
            {this.props.title}
            <span>{this.props.subtitle}</span>
          </div>
          { this.props.icon ? <i className={`${styles.check} ${this.props.icon}`} /> : null }
        </div>
      </div>
    );
  }
}

export default ButtonCard;
