import React from 'react';
import PropTypes from 'prop-types';
import styles from './Pager.scss';

const Pager = ({ pages, current, onPage, className }: any) => {
  const bb = current > 2;
  const b = current > 1;
  const f = current < pages;
  const ff = current < (pages - 1);
  return (
    <div className={`${className} ${styles.container}`}>
      <span className={`${bb ? styles.enabled : ''}`} onClick={bb ? onPage.bind(null, 1) : null}><i className="icon-chevrons-left" /></span>
      <span className={`${b ? styles.enabled : ''}`} onClick={b ? onPage.bind(null, current - 1) : null}><i className="icon-chevron-left" /></span>
      <span className={styles.current}>{ current }</span>
      <span className={`${f ? styles.enabled : ''}`} onClick={f ? onPage.bind(null, current + 1) : null}><i className="icon-chevron-right" /></span>
      <span className={`${ff ? styles.enabled : ''}`} onClick={ff ? onPage.bind(null, pages) : null}><i className="icon-chevrons-right" /></span>
    </div>
  );
};

Pager.propTypes = {
  className: PropTypes.string,
  pages: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  onPage: PropTypes.func.isRequired
};

Pager.defaultProps = {
  className: ''
};

export default Pager;
