import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import Select from 'react-select';
import Modal from 'react-responsive-modal';
import uuid from 'uuid';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import styles from './Device.scss';

import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import { Permissions } from '../../state/authorization';

const createFirmwareMutation = gql`
  mutation createFirmware($version: String, $notes: String, $target: FirmwareTarget!, $device: DeviceRelationAttributes!, $file: Upload!) {
    createFirmware(attributes: { version: $version, notes: $notes, target: $target, device: $device, file: $file }) {
      firmware {
        id, version, target, notes, file, createdAt
        device {
          id
        }
      }
    }
  }
`;

const deleteFirmwareMutation = gql`
  mutation deleteFirmware($id: ID!) {
    deleteFirmware(id: $id) { id }
  }
`;

const sendAction = gql`
  mutation sendFirmwareToMachine($id: ID!, $topic: String!) {
    sendFirmwareToMachine(id: $id, topic: $topic) {
      id
    }
  }
`;

type Props = {
  device?: ?any,
  firmwares: Array<any>,
  refetch: any,
  fancyboxOnly: boolean,
  onComplete?: ?Function
}

type State = {
  streams: Array<any>,
  variables: any,
  showFirmwareForm: boolean,
  moreFirmware: boolean,
  selectedFirmware: ?string,
  sending: ?any,
  feedback: Array<any>,
  fileError: boolean
}

class Firmwares extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    can: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func,
    stompClient: PropTypes.object
  }

  static defaultProps = {
    firmwares: []
  }

  state = {
    variables: { target: this.props.fancyboxOnly ? 'fancybox' : 'hmi' },
    showFirmwareForm: false,
    moreFirmware: false,
    selectedFirmware: null,
    sending: null,
    feedback: [],
    streams: [],
    fileError: false
  }

  onFileChange = (f: any) => {
    const { name } = f[0];
    if (name.endsWith('.zip')) {
      this.setState({ variables: { ...this.state.variables, file: f[0] }, fileError: false });
    } else {
      this.setState({ fileError: true });
    }
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ variables: { ...this.state.variables, [field]: e.target.value } });
  }

  onSelect = (field: string, option: { label: string, value: string }) => {
    this.setState({ variables: { ...this.state.variables, [field]: option.value } });
  }

  onFirmwareUpload = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();

    if (this.props.device) {
      action({ variables: { ...this.state.variables, device: { id: this.props.device.id } } });
    } else {
      action({ variables: { ...this.state.variables } });
    }
  }

  onFirmwareDelete = (action: Function, firmwareId: string) => {
    confirmAlert({
      title: this.context.lang('firmware', 'delete-confirm-title').toString(),
      message: this.context.lang('firmware', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: firmwareId } })
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  onFirmwareToMachine = (action: Function, firmware: any) => {
    confirmAlert({
      title: this.context.lang('firmware', 'send-confirm-title').toString(),
      message: this.context.lang('firmware', 'send-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => this.sendFirmwareToMachine(action, firmware.id)
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  onFeedbackExit = () => {
    this.state.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
    this.setState({ sending: null, feedback: [] });
  }

  sendFirmwareToMachine = (action: Function, firmwareId: string) => {
    const topic = uuid();
    this.setState({ sending: firmwareId });
    const callback = (res) => {
      this.setState({ feedback: [...this.state.feedback, res.content] });
    };
    if (this.props.device) {
      this.context.stompClient.connect().then(() => {
        this.context.stompClient.streamFrom([{ deviceId: this.props.device.remoteId, channel: 'feedback', topic, callback }]).then((streams) => {
          this.setState({ streams });
        }).catch(() => {
          this.setState({ sending: null, feedback: [] });
        });
      });
    }
    action({ variables: { id: firmwareId, topic } });
  }

  render() {
    const { firmwares, fancyboxOnly } = this.props;
    const { lang, can } = this.context;
    const { variables } = this.state;
    const targets = fancyboxOnly ? [{ label: 'fancybox', value: 'fancybox' }] : [{ label: 'hmi', value: 'hmi' }, { label: 'plc', value: 'plc' }];
    return (
      <div>
        { this.state.sending ?
          <Modal onClose={this.onFeedbackExit} closeOnOverlayClick={false} open center styles={{ modal: { minHeight: '550px', minWidth: '850px' } }}>
            <div className={styles['feedback-container']}>
              {
                this.state.feedback.map((f, i) => <div key={`feedback-${i}`} className={`${styles['feedback-item']} ${styles[`feedback-${f.status}`]}`}>{f.message}</div>)
              }
            </div>
          </Modal>
          : null
        }
        <div className="grid-x">
          <div className="subtitle small-10 cell">{ lang('device', 'firmwares').s }</div>
          { can(Permissions.firmwares.firmwaresCreate) ?
            <div className={`${styles['inline-button']} ${styles['inline-button__header']} small-2 cell`} onClick={() => this.setState({ showFirmwareForm: true })}>{lang('global', 'new').s}</div>
          : null }
        </div>
        {
          firmwares.length > 0 ?
          firmwares.slice(0, this.state.moreFirmware ? firmwares.length : 4).map((f) => {
            return (
              <span className="subtitle grid-x" key={f.id}>
                <div className="small-8 cell">{`${moment(f.createdAt).format('DD/MM/YYYY')} [${f.target}] ${f.version || ''}`}</div>
                <div className={`small-4 cell ${styles['accessory-container']}`}>
                  <span className={styles.info} onClick={() => this.setState({ selectedFirmware: this.state.selectedFirmware === f.id ? null : f.id })}><i className="icon-info" /></span>
                  { can(Permissions.firmwares.firmwaresCreate) ?
                    <Mutation mutation={deleteFirmwareMutation} refetchQueries={[this.props.refetch]}>
                      {(action, { loading }) => {
                        return loading ? <Spinner size={20} /> : <span className={styles.delete}><i className="icon-trash" onClick={this.onFirmwareDelete.bind(null, action, f.id)} /></span>;
                      }}
                    </Mutation>
                  : null }
                  <a href={f.file} target="_blank" className={styles.download}><i className="icon-cloud-download" /></a>
                  <Mutation mutation={sendAction} onCompleted={this.props.onComplete || (() => {})}>
                    {(action, sendResult) => {
                      return sendResult.loading ? <Spinner /> : <span onClick={this.onFirmwareToMachine.bind(null, action, f)} target="_blank" className={styles.send}><i className="icon-vnc" /></span>;
                    }}
                  </Mutation>
                </div>
                <div className={`${styles.notes} small-12 cell`} style={this.state.selectedFirmware === f.id ? {} : { display: 'none' }}>
                  {f.notes || lang('firmware', 'no-notes').s}
                </div>
              </span>
            );
          })
          : <span className={styles['no-firmware']}>{lang('firmware', 'no-firmware').s}</span>
        }
        <div className={styles.more} style={firmwares.length > 4 ? {} : { display: 'none' }} onClick={() => this.setState({ moreFirmware: !this.state.moreFirmware })}>{this.state.moreFirmware ? lang('global', 'less').s : lang('global', 'more').s}</div>
        {
          this.state.showFirmwareForm ?
            <Mutation mutation={createFirmwareMutation} onCompleted={() => this.setState({ showFirmwareForm: false, variables: {} })} refetchQueries={[this.props.refetch]}>
              {(action, { loading, error }) => {
                return (
                  <form className={`grid-x ${styles.firmware}`}>
                    <MutationError graphQLError={error} lang={lang} langKey="firmware" />

                    <div className="title cell small-12 medium-6">{ lang('firmware', 'new').s }</div>
                    <div className="title-action cell small-12 medium-6">
                      {loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onFirmwareUpload.bind(null, action)} value="upload" />}
                    </div>

                    <label htmlFor="file" className="cell small-12">
                      <div>{ lang('firmware', 'file').s }</div>
                      <Dropzone className={styles.dropzone} activeClassName={styles['dropzone--active']} multiple={false} name="file" onDrop={this.onFileChange}>
                        {variables.file ? variables.file.name : lang('firmware', 'no-file').toString()}
                      </Dropzone>
                      { this.state.fileError
                      ? <span className="dropzone-error">{lang('global', 'only-zip').s}</span>
                      : null }
                    </label>
                    <label htmlFor="type" className="cell small-12 medium-6">
                      <div>{ lang('device', 'firmware-type').s }</div>
                      <Select classNamePrefix="Select" options={targets} value={variables.target ? targets.filter(e => e.value === variables.target) : targets[0]} onChange={this.onSelect.bind(null, 'target')} clearable={false} searchable={false} />
                    </label>
                    <label htmlFor="model" className="cell small-12 medium-6">
                      <div>{ lang('firmware', 'version').s }</div>
                      <input type="text" name="version" value={variables.version || ''} onChange={this.onTextChange.bind(null, 'version')} />
                    </label>
                    <label htmlFor="model" className="cell small-12">
                      <div>{ lang('firmware', 'notes').s }</div>
                      <textarea name="notes" value={variables.notes || ''} onChange={this.onTextChange.bind(null, 'notes')} />
                    </label>
                  </form>
                );
              }}
            </Mutation>
            : null
        }
      </div>
    );
  }
}

export default Firmwares;
