import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
// import 'react-select/dist/react-select.css';
import omitDeep from 'omit-deep-lodash';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';

import MutationError from '../common/errors/MutationError';
import Spinner from '../common/spinner/Spinner';
import TabPanel from '../common/tabpanel/TabPanel';
import NumericInput from '../common/form/NumericInput';
import styles from './DetailForm.scss';
import ButtonCard from '../common/card/ButtonCard';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import Estimate from './Estimate';

import queries, { recipeFields } from '../../state/queries';

type Props = {
  device: any,
  planning?: any,
  onCompleted: Function,
  onSendCompleted: Function
}

type State = {
  values: any,
  errors: any,
  showRecipes: boolean,
  search: string,
  skip: boolean
}

const sendAction = gql`
  mutation sendPlanningToMachine($id: ID!) {
    sendPlanningToMachine(id: $id) {
      productionHistory { id }
    }
  }
`;

const createPlanning = gql`
  mutation createPlanning($device: DeviceRelationAttributes!, $description: String, $date: DateTime!, $planningItems: [PlanningItemAttributes!]) {
    createPlanning(attributes: { device: $device, description: $description, date: $date, planningItems: $planningItems }) {
      planning {
        id, number, date, description
        device {
          id, serial, model
        }
        planningItems {
          id
          recipe {
            ${recipeFields}
          }
          tanks, tankWeight, targetPieces
        }
      }
    }
  }
`;

const updatePlanning = gql`
  mutation updatePlanning($id: ID!, $device: DeviceRelationAttributes!, $description: String, $date: DateTime!, $planningItems: [PlanningItemAttributes!]) {
    updatePlanning(id: $id, attributes: { device: $device, description: $description, date: $date, planningItems: $planningItems }) {
      planning {
        id, number, date, description
        device {
          id, serial, model
        }
        planningItems {
          id
          recipe {
            ${recipeFields}
          }
          tanks, tankWeight, targetPieces
        }
      }
    }
  }
`;

const planningDeleteMutation = gql`
  mutation deletePlanning($id: ID!) {
    deletePlanning(id: $id) { id }
  }
`;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

class DetailForm extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  state = {
    values: { planningItems: [], date: moment().format('YYYY-MM-DD'), number: 0 },
    errors: {},
    showRecipes: false,
    search: '',
    skip: false
  }

  componentWillMount() {
    if (this.props.planning) {
      this.setState({ values: { ...this.props.planning } });
    }
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.state.values, device: { id: this.props.device.id } }, '__typename');

    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    variables.planningItems.filter(p => !p._destroy).forEach((p, i) => { p.order = i; }); // eslint-disable-line
    variables.planningItems.filter(p => p._destroy).forEach((p, i) => { p.order = -1; }); // eslint-disable-line

    const items = omitDeep({ ...variables }, 'profile');

    action({ variables: items });
  }

  onPlanningUpdate = (result: any) => {
    if (result.updatePlanning) {
      this.setState({ values: { ...result.updatePlanning } });
    }
    if (result.createPlanning) {
      this.setState({ values: { ...result.createPlanning } });
    }
    this.props.onCompleted(result);
  }

  onPlanningDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('planning', 'delete-confirm-title').toString(),
      message: this.context.lang('planning', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: (this.props.planning || {}).id } })
        },
        {
          label: this.context.lang('global', 'no').toString()
        }
      ]
    });
  }

  onDeleteComplete = () => {
    const { dispatch, push } = this.context;

    dispatch(push(`/devices/${this.props.device.id}/production`));
  }

  onSearch = (action: Function, e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.interval) {
      clearTimeout(this.interval);
    }

    this.setState({ search: e.target.value, skip: true });

    this.interval = setTimeout(() => {
      this.setState({ skip: false });
    }, 500);
  }

  onDragEnd = (result: any) => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = reorder(
        this.state.values.planningItems,
        source.index,
        destination.index
      );

      this.setState({ values: { ...this.state.values, planningItems: items } });
    }
  }

  onRecipeSelection = (recipe: any) => {
    this.setState({ showRecipes: false, values: { ...this.state.values, planningItems: [...this.state.values.planningItems, { recipe, id: this.state.values.planningItems.length.toString() }] } });
  }

  onItemNumberchange = (item: any, field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    let { planningItems } = this.state.values;
    planningItems = planningItems.map((p) => {
      if (p.id === item.id) {
        return { ...p, [field]: (parseInt(e.target.value, 10) || 0) };
      }
      return p;
    });
    this.setState({ values: { ...this.state.values, planningItems } });
  }

  onNumericChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, [field]: parseInt(e.target.value, 10) } });
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, [field]: e.target.value } });
  }

  onDateChange = (date: any) => {
    this.setState({ values: { ...this.state.values, date: date.format('YYYY-MM-DD') } });
  }

  onItemRemove = (id: string) => {
    let { planningItems } = this.state.values;
    planningItems = planningItems.map((p) => {
      if (p.id === id) {
        return { ...p, _destroy: true };
      }
      return p;
    });
    this.setState({ values: { ...this.state.values, planningItems } });
  }

  onSend = (action: Function, id: string) => {
    confirmAlert({
      title: this.context.lang('planning', 'send-confirm-title').toString(),
      message: this.context.lang('planning', 'send-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id } })
        },
        {
          label: this.context.lang('global', 'no').toString()
        }
      ]
    });
  }

  onCalc = (planningId: string, field: string) => {
    let { planningItems } = this.state.values;

    planningItems = planningItems.map((p) => {
      if (p.id === planningId) {
        const weight = p.recipe.profile.weight || p.recipe.profile.target;
        if (field === 'tanks') {
          if (p.tankWeight <= 0 || p.targetPieces <= 0) {
            return p;
          }
          return { ...p, tanks: Math.ceil(((p.targetPieces * weight) / p.tankWeight) / 1000) };
        }
        if (field === 'tankWeight') {
          if (p.tanks <= 0 || p.targetPieces <= 0) {
            return p;
          }
          return { ...p, tankWeight: Math.ceil(((p.targetPieces * weight) / 1000) / p.tanks) };
        }
        if (field === 'targetPieces') {
          if (p.tanks <= 0 || p.tankWeight <= 0) {
            return p;
          }
          return { ...p, targetPieces: Math.floor(((p.tanks * p.tankWeight) / weight) * 1000) };
        }
        return p;
      }
      return p;
    });

    this.setState({ values: { ...this.state.values, planningItems } });
  }

  validate = (values: any) => {
    const errors = {};
    ['date'].forEach((f) => {
      if (!values[f] || `${values[f]}`.trim().length === 0) {
        errors[f] = 'empty';
      }
    });
    return errors;
  }

  interval = null

  render() {
    const { lang } = this.context;

    const { device, planning } = this.props;
    const { values, showRecipes } = this.state;

    return (
      <Mutation mutation={planning ? updatePlanning : createPlanning} onCompleted={this.onPlanningUpdate}>
        {(action, mutationResult) => {
          return (
            <TabPanel tabsPosition="bottom" tabs={[lang('global', 'details-tab-title').s, lang('global', 'info-tab-title').s]}>
              <form className="grid-x page">
                <Breadcrumbs relative steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${device.model} - ${device.serial}`, url: `/devices/${device.id}` }, { title: lang('page', 'production').s, url: `/devices/${device.id}/production` }]} />
                <div className="spacer" />
                <div className="title cell small-12 medium-6">{ planning ? `${lang('planning', 'planning').s} #${values.number}` : lang('planning', 'create').s }</div>
                <div className="title-action cell small-12 medium-6">
                  {mutationResult.loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onClick.bind(null, action)} value={planning ? 'Update' : 'Create'} />}
                </div>
                <MutationError graphQLError={mutationResult.error} errors={this.state.errors} lang={lang} langKey="planning" />
                <Query query={queries.recipes.list} variables={{ search: this.state.search, deviceId: device.id }} fetchPolicy="network-only" skip={this.state.skip}>
                  {({ loading, error, data, refetch }) => {
                    const content = (
                      <div>
                        <DragDropContext onDragEnd={this.onDragEnd}>
                          <Droppable droppableId="source">
                            {outerProvided => (
                              <div ref={outerProvided.innerRef} className={styles.steps}>
                                <div className={styles.hint} style={values.planningItems.filter(p => !p._destroy).length > 1 ? {} : { display: 'none' }}>{lang('planning', 'drag-hint').s}</div>
                                <div className={styles['steps-container']}>
                                  { values.planningItems.filter(p => !p._destroy).map((p, index) => {
                                    const description = p.recipe.description || lang('recipe', 'no-description').toString();
                                    const calcTanks = p.tankWeight > 0 && p.targetPieces > 0;
                                    const calcTankWeight = p.tanks > 0 && p.targetPieces > 0;
                                    const calcTargetPieces = p.tanks > 0 && p.tankWeight > 0;
                                    return (
                                      <Draggable key={p.id} draggableId={p.id} index={index}>
                                        {(provided, snapshot) => (
                                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                                            <div className={styles.card} style={snapshot.isDragging ? { boxShadow: '0px 2px 5px #cecece' } : {}}>
                                              <div className="grid-x">
                                                <div className="small-11 cell">
                                                  {`${p.recipe.number} - ${description.substring(0, 20)}${description.length > 20 ? '...' : ''}`}
                                                </div>
                                                <div className={`small-1 cell ${styles.remove}`}>
                                                  <i className="icon-x" onClick={this.onItemRemove.bind(null, p.id)} />
                                                </div>
                                              </div>
                                              <div className="grid-x">
                                                <span className="small-12 cell">{`Target: ${p.recipe.profile.weight}g`}</span>
                                                <label htmlFor="tanks" className={`${styles['calc-container']} small-12 medium-4 cell`}>
                                                  <div>{lang('planning', 'tanks').s}</div>
                                                  <NumericInput name="tanks" value={p.tanks} onChange={this.onItemNumberchange.bind(null, p, 'tanks')} />
                                                  <i className={`${calcTanks ? styles['calc--active'] : styles.calc} icon-calculator`} onClick={this.onCalc.bind(null, p.id, 'tanks')} />
                                                </label>
                                                <label htmlFor="tankWeight" className={`${styles['calc-container']} small-12 medium-4 cell`}>
                                                  <div>{lang('planning', 'tankWeight').s}</div>
                                                  <NumericInput name="tankWeight" value={p.tankWeight} onChange={this.onItemNumberchange.bind(null, p, 'tankWeight')} />
                                                  <i className={`${calcTankWeight ? styles['calc--active'] : styles.calc} icon-calculator`} onClick={this.onCalc.bind(null, p.id, 'tankWeight')} />
                                                </label>
                                                <label htmlFor="targetPieces" className={`${styles['calc-container']} small-12 medium-4 cell`}>
                                                  <div>{lang('planning', 'pieces').s}</div>
                                                  <NumericInput name="targetPieces" value={p.targetPieces} onChange={this.onItemNumberchange.bind(null, p, 'targetPieces')} />
                                                  <i className={`${calcTargetPieces ? styles['calc--active'] : styles.calc} icon-calculator`} onClick={this.onCalc.bind(null, p.id, 'targetPieces')} />
                                                </label>
                                              </div>
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    );
                                  })}
                                </div>
                              </div>
                            )}
                          </Droppable>
                        </DragDropContext>
                        <div className={`grid-x ${styles['recipe-search']}`} style={showRecipes ? {} : { display: 'none' }}>
                          <i className={`icon-x ${styles['recipe-search__close']}`} onClick={() => { this.setState({ showRecipes: false }); }} />
                          <label htmlFor="search-recipe" className="cell small-12">
                            <div>{lang('planning', 'search').s}</div>
                            <input type="text" className="small-12 cell" name="search-recipe" value={this.state.search} onChange={this.onSearch.bind(null, refetch)} />
                            { loading ? <Spinner /> : null }
                            { error ? <div>{error.toString()}</div> : null }
                          </label>
                          <div className="grid-x small-12 cell">
                            { (data && data.recipes) ? data.recipes.map((r) => {
                              const description = r.description || lang('recipe', 'no-description').toString();
                              return <ButtonCard className="small-12 medium-6 cell" title={`${r.number} - ${description.substring(0, 20)}${description.length > 20 ? '...' : ''}`} subtitle={r.weight} key={r.id} icon="icon-plus-circle" onSelect={this.onRecipeSelection.bind(null, r)} />;
                            }) : null }
                          </div>
                        </div>
                      </div>
                    );

                    // DatePicker is buggy while formatting dates, this gets the localized format and switches DD for dd
                    const localeFormat = moment.localeData().longDateFormat('L').replace('DD', 'dd');
                    return (
                      <div className="grid-x small-12">
                        <label htmlFor="description" className="cell small-12 medium-8">
                          <div>{lang('planning', 'description').s}</div>
                          <input type="text" name="description" value={values.description || ''} onChange={this.onTextChange.bind(null, 'description')} />
                        </label>
                        <label htmlFor="date" className="cell small-12 medium-4">
                          <div>{lang('planning', 'date').s}</div>
                          <DatePicker dateFormat={localeFormat} selected={new Date(values.date)} onChange={this.onDateChange} className="cell small-12 medium-6" />
                        </label>
                        <div className="small-12 cell">
                          { content }
                          {
                            showRecipes
                            ? null
                            : <div className={styles.add} onClick={() => { this.setState({ showRecipes: true }); }}><i className="icon-plus-circle" />{lang('planning', 'add-recipe').toString()}</div>
                          }
                        </div>
                      </div>
                    );
                  }}
                </Query>
                { planning ?
                  <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
                    <Mutation mutation={planningDeleteMutation} onCompleted={this.onDeleteComplete}>
                      {(deleteAction, result) => {
                        return result.loading ? <Spinner /> : <div className="outline-button--subtle small-10 medium-4 cell" onClick={this.onPlanningDelete.bind(null, deleteAction)}>{lang('planning', 'delete').s}</div>;
                      }}
                    </Mutation>
                  </div>
                  : null
                }
              </form>
              <aside className="grid-x">
                { (planning && planning.id)
                  ?
                    <Mutation mutation={sendAction} onCompleted={this.props.onSendCompleted}>
                      {(planningAction, sendResult) => {
                        return sendResult.loading ? <Spinner /> : <div className="outline-button cell small-12" onClick={this.onSend.bind(null, planningAction, planning.id)}>Send to device</div>;
                      }}
                    </Mutation>
                : null }
                { (planning && planning.id) ? <Estimate values={values} id={planning.id} lang={lang} /> : null }
              </aside>
            </TabPanel>
          );
        }}
      </Mutation>
    );
  }
}

export default DetailForm;
