import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import Spinner from '../components/common/spinner/Spinner';
import Firmwares from '../components/devices/Firmwares';
import TabPanel from '../components/common/tabpanel/TabPanel';
import styles from './SettingsPage.scss';
import initialState from '../state/initial-state';
import Pager from '../components/common/pager/Pager';
import QueryError from '../components/common/errors/QueryError';

type Props = {
  session: typeof initialState.session,
};
type State = {
  page: number
};

const query = gql`
  query {
    firmwares(target: "fancybox", order: createdAt_DESC) {
      id,
      version, notes, target, createdAt, file
    }
  }
`;

const devices = gql`
  query devices($page: Int!, $customerId: ID) {
    devices(customerId: $customerId, page: $page) {
      results {
        id, remoteId, serial, model, profileType
        customer { id, name }
      }
      totalPages
      count
    }
  }
`;

const deviceConnectionStates = gql`
  query($ids: [String!]!) {
    deviceConnectionStates(ids: $ids) {
      id, connected
    }
  }
`;

class HomePage extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = {
    page: 1
  }

  onPage = (page: number) => {
    this.setState({ page });
  }

  render() {
    const { page } = this.state;
    const { lang } = this.context;

    return (
      <TabPanel tabsPosition="bottom" tabs={['Firmwares', 'Devices']}>
        <div className="page grid-x">
          <div className={`cell small-12 ${styles.info}`}>
            <Query query={query} fetchPolicy="network-only">
              {({ loading, error, data }) => {
                if (loading) return <Spinner absolute />;
                if (error) return <QueryError lang={lang} error={error.toString()} />;
                if (!data) return null;

                return <Firmwares firmwares={data.firmwares} fancyboxOnly refetch={{ query }} />;
              }}
            </Query>
          </div>
        </div>
        <aside className="grid-x">
          <Query query={devices} variables={{ page, customerId: this.props.session.currentCustomer ? this.props.session.currentCustomer.id : null }} fetchPolicy="network-only">
            {({ loading, error, data }) => {
              if (loading) return <Spinner absolute />;
              if (error) return <QueryError lang={lang} error={error.toString()} />;
              if (!data) return null;

              return (
                <Query query={deviceConnectionStates} pollInterval={5000} skip={!data.devices} variables={{ ids: data.devices.results.map(d => d.id) }} fetchPolicy="network-only">
                  {(status) => {
                    return (
                      <div style={{ width: '100%' }}>
                        <div className={styles.container} style={{ marginBottom: '1rem' }}>
                          Total devices: {data.devices.count}
                        </div>
                        <div className={styles.container}>
                          {
                            data.devices.results.map((d) => {
                              let connected = false;
                              if (status.data && status.data.deviceConnectionStates) {
                                const states = status.data.deviceConnectionStates.filter(dev => dev.id === d.id);
                                const meta = states.length > 0 ? states[0] : { connect: false };
                                connected = meta.connected; // eslint-disable-line
                              }

                              return (
                                <div className={styles.device} key={d.id}>
                                  <span>
                                    <span className={`${connected ? styles.connected : styles.disconnected}`} />
                                    <Link to={`/devices/${d.id}`}>{d.model} - {d.serial}</Link>
                                  </span>
                                  <span className={styles.code}>{d.remoteId}</span>
                                </div>
                              );
                            })
                          }
                          <Pager pages={data.devices.totalPages} current={page} onPage={this.onPage} />
                        </div>
                      </div>
                    );
                  }}
                </Query>
              );
            }}
          </Query>
        </aside>
      </TabPanel>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(HomePage);
