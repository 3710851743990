import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import Recipes from '../recipes/Recipes';
import Plannings from '../plannings/Plannings';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import Pager from '../common/pager/Pager';
import Spinner from '../common/spinner/Spinner';
import TabPanel from '../common/tabpanel/TabPanel';
import ProductionHistories from './ProductionHistories';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  deviceId: string,
  customerId: string,
  onSendCompleted: Function
}

type State = {
  page: number
};

class Production extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = {
    page: 1
  }

  onPage = (page: number) => {
    this.setState({ page });
  }

  render() {
    const { lang } = this.context;
    const { deviceId, customerId } = this.props;

    return (
      <Query query={queries.devices.basic} variables={{ id: deviceId }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          return (
            <TabPanel tabsPosition="bottom" tabs={['Main', 'History']}>
              <div style={{ width: '100%' }}>
                <Breadcrumbs steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${data.device.model} - ${data.device.serial}`, url: `/devices/${deviceId}` }]} />
                <Recipes customerId={customerId} deviceId={deviceId} onSendCompleted={this.props.onSendCompleted} />
                <Plannings customerId={customerId} deviceId={deviceId} />
              </div>
              <aside className="grid-x">
                <div className="small-12 cell">
                  <div className="subtitle">{lang('history', 'title').s}</div>
                  <Query query={queries.devices.productionHistories} variables={{ id: deviceId, page: this.state.page }} fetchPolicy="network-only">
                    {(result) => {
                      if (result.loading) return <Spinner absolute />;
                      if (result.error) return `Error!: ${result.error.toString()}`;
                      if (!result.data) return null;

                      return (
                        <div>
                          <ProductionHistories histories={result.data.productionHistories.results} device={data.device} />
                          <Pager current={parseInt(this.state.page, 10)} pages={result.data.productionHistories.totalPages} onPage={this.onPage} />
                        </div>
                      );
                    }}
                  </Query>
                </div>
              </aside>
            </TabPanel>
          );
        }}
      </Query>
    );
  }
}

export default Production;
