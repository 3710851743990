import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styles from './Home.scss';
import { Permissions } from '../../state/authorization';

type Props = {
}

type State = {}

class Home extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    replace: PropTypes.func.isRequired,
    actions: PropTypes.object.isRequired,
    can: PropTypes.func.isRequired
  }

  render() {
    const { lang, actions, can } = this.context;
    const { logout } = actions.session;

    return (
      <div className={styles.main}>
        <div className={styles['logo-container']}>
          <img src={require('../../images/logo.png')} alt="logo" />
        </div>
        <div className={styles.selector}>
          { can(Permissions.devices.devicesIndex) ? (
            <Link to="/devices" className={styles.card}>
              <span className={`icon-grid ${styles.icon}`} />
              <div className={styles['menu-info']}>
                <div className={styles['menu-title']}>
                  {lang('page', 'devices').toString()}
                </div>
                <div className={styles['menu-subtitle']}>
                  {lang('home', 'devices-line-1').toString()}
                  <br />
                  {lang('home', 'devices-line-2').toString()}
                </div>
              </div>
            </Link>
          ) : null }
          { can(Permissions.users.usersIndex) ? (
            <Link to="/users" className={styles.card}>
              <span className={`icon-users ${styles.icon}`} />
              <div className={styles['menu-info']}>
                <div className={styles['menu-title']}>
                  {lang('page', 'users').toString()}
                </div>
                <div className={styles['menu-subtitle']}>
                  {lang('home', 'users-line-1').toString()}
                  <br />
                  {lang('home', 'users-line-2').toString()}
                </div>
              </div>
            </Link>
          ) : null }
          <div onClick={logout} className={styles.card}>
            <span className={`icon-log-out ${styles.icon}`} />
            <div className={styles['menu-info']}>
              <div className={styles['menu-title']}>
                {lang('page', 'logout').toString()}
              </div>
              <div className={styles['menu-subtitle']}>
                {lang('home', 'logout-line-1').toString()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Home;
