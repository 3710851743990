import { parseErrorMessage } from '../utils';
import initialState from '../initial-state';
import types from '../action-types';

export default function sessionReducer(state: typeof initialState.session = initialState.session, action: any) {
  switch (action.type) {
    case types.LOGOUT:
      return state;
    case types.LOGOUT_SUCCESS:
      if (localStorage) localStorage.clear();
      if (sessionStorage) sessionStorage.clear();
      return Object.assign({}, state, initialState.session);
    case types.LOGIN:
      return Object.assign({}, state, {
        authenticating: !action.error,
        authenticated: false,
        error: action.error ? parseErrorMessage(action.payload) : false
      });
    case types.LOGIN_SUCCESS:
      return Object.assign({}, state, {
        ...action.payload,
        authenticated: true,
        authenticating: false,
        error: false,
        currentCustomer: action.payload.customer
      });
    case types.REFRESH_SUCCESS:
      return Object.assign({}, state, {
        ...action.payload,
        authenticated: true,
        authenticating: false,
        error: false
      });
    case types.LOGIN_FAIL:
      return Object.assign({}, state, {
        authenticating: false,
        authenticated: false,
        error: parseErrorMessage(action.payload)
      });
    case types.SWITCH_CUSTOMER:
      return {
        ...state,
        currentCustomer: action.customer
      };
    default:
      return state;
  }
}
