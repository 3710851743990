import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import styles from './Cards.scss';

type Props = {
  className?: string,
  title: string,
  subtitle?: string,
  extra?: string,
  tag?: string,
  tagColor?: string,
  url: string,
  status?: string,
  accessory?: string
}

type State = {}

class LinkCard extends Component<Props, State> {
  render() {
    return (
      <div className={`${styles.container} ${this.props.className || ''}`}>
        <Link to={this.props.url} className={styles.card}>
          {this.props.title}
          <span>{this.props.subtitle}</span>
          { this.props.extra ? <span>{this.props.extra}</span> : null }
          { this.props.status ? <i className={`${styles.status} icon-circle2 status-color-${this.props.status}`} /> : null }
          { this.props.accessory ? <i className={`${styles.accessory} ${this.props.accessory}`} /> : null }
          { this.props.tag ? <span className={`${this.props.tagColor || ''} ${styles.tag}`}>{this.props.tag}</span> : null }
        </Link>
      </div>
    );
  }
}

export default LinkCard;
