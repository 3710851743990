import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import moment from 'moment';

import { Permissions } from '../../state/authorization';

import styles from './Aside.scss';

type Props = {
  device: any
}

type State = {
  data: any,
  updatedAt: any
};

class Status extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    stompClient: PropTypes.object,
    can: PropTypes.func.isRequired
  }

  state = {
    data: {},
    updatedAt: null
  }

  componentDidMount() {
    const { can, lang } = this.context;

    if (can(Permissions.devices.devicesLiveParams)) {
      sessionStorage.setItem(`${this.props.device.remoteId}:data`, '{}');
      const setData = (res) => {
        if (window.VERBOSE_LIVE_MSG) {
          console.log('status', res.content);  // eslint-disable-line
        }
        if (res.content && res.content.message && (res.content.message instanceof Object) && !(res.content.message instanceof Array)) {
          const data = _.pickBy(res.content.message, (v, k) => { return !_.endsWith(k, 'alarm'); });
          this.setState({ data, updatedAt: moment() });
          const translated = Object.keys(data).map((k) => {
            const { value } = this.valueFor(data[k]);
            return `${lang('device', 'status', k).s}: ${value}`;
          });
          sessionStorage.setItem(`${this.props.device.remoteId}:data`, JSON.stringify(translated));
        }
      };
      this.context.stompClient.connect().then(() => {
        this.context.stompClient.streamFrom([
          { deviceId: this.props.device.remoteId, channel: 'feedback', topic: 'live-data-snapshot', callback: setData },
        ]).then((streams) => {
          this.streams = streams;
        }).catch(() => {
          console.error('Unable to subscribe to the data stream'); // eslint-disable-line
        });
      });

      this.timerRefresh = setInterval(() => {
        this.setState({ ...this.state });
      }, 30000);
    }
  }

  componentWillUnmount() {
    const { can } = this.context;

    if (can(Permissions.devices.devicesLiveParams)) {
      if (this.timerRefresh) {
        clearInterval(this.timerRefresh);
      }
      this.streams.forEach(s => this.context.stompClient.removeStompStreamListener(s));
    }
  }

  valueFor = (data: any) => {
    const { lang } = this.context;
    let value = data;
    let mu = null;

    if (value && typeof value === 'object') {
      mu = value.mu || ''; // eslint-disable-line
      value = value.value; // eslint-disable-line
    }
    let option = '';
    if (typeof value === 'boolean') {
      option = value ? 'status-color-green' : 'status-color-red';
      value = value ? '✓' : '×';
    } else {
      value = mu ? `${value} ${lang('mu', mu).s}` : value;
    }

    return { value, option };
  }

  streams = []
  timerRefresh = null

  render() {
    const { lang, can } = this.context;
    const { updatedAt } = this.state;
    const data = { ...this.state.data };
    let online = false;
    if (updatedAt) {
      online = moment().diff(updatedAt, 'minutes') < 1;
    }
    let color = 'grey';

    let status = data.machine_status || 0;
    if (data.machine_status && data.machine_status.value) {
      status = data.machine_status.value;
    }

    if (status === 1) {
      color = 'red';
    }
    if (status === 2) {
      color = 'blue';
    }
    if (status === 4) {
      color = 'yellow';
    }
    if (status === 8) {
      color = 'green';
    }
    // flowlint-line-ignore
    delete data.machine_status;
    return (
      <div style={{ width: '100%' }}>
        <div className={styles.container}>
          <div className={styles.item}>
            <span>{lang('device', 'online-status').s}</span>
            <span>{online ? 'ONLINE' : 'OFFLINE'}</span>
          </div>
          <div className={styles.item}>
            <span>{lang('device', 'machine-status').s}</span>
            <span className={`${styles.status} status-color-${color}`}><i className="icon-circle2" /></span>
          </div>
          {
            can(Permissions.devices.devicesLiveParams) ? Object.keys(data).map((d) => {
              const { value, option } = this.valueFor(data[d]);
              return (
                <div className={styles.item} key={d}>
                  <span>{lang('device', 'status', d).s}</span>
                  <span className={`${styles.detail} ${option}`}>{value}</span>
                </div>
              );
            }) : null
          }
        </div>
        <span className={styles.footer}>{`${lang('global', 'last-update').s}: ${updatedAt ? updatedAt.format('HH:mm:ss') : lang('global', 'NA').s}`}</span>
      </div>
    );
  }
}

export default Status;
