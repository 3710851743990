import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import ReactFlagsSelect from 'react-flags-select';
import omitDeep from 'omit-deep-lodash';
import Select from 'react-select';
import 'react-flags-select/css/react-flags-select.css';
import CreatableSelect from 'react-select/lib/Creatable';

import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import { customerFields } from '../../state/queries';

type Props = {
  customer?: any,
  onCompleted: Function
}

type State = {
  recipientsInput: string,
  values: any,
  errors: any
}

const createCustomer = gql`
  mutation createCustomer($kind: CustomerKind!, $name: String!, $address: String, $postalCode: String, $city: String, $town: String, $country: String, $zone: String, $language: String, $contactName: String, $phone: String, $email: String, $web: String, $recipients: [String!]) {
    createCustomer(attributes: { kind: $kind, name: $name, address: $address, postalCode: $postalCode, city: $city, town: $town, country: $country, zone: $zone, language: $language, contactName: $contactName, phone: $phone, email: $email, web: $web, recipients: $recipients }) {
      customer {
        ${customerFields}
      }
    }
  }
`;

const updateCustomer = gql`
  mutation updateCustomer($id: ID!, $kind: CustomerKind!, $name: String!, $address: String, $postalCode: String, $city: String, $town: String, $country: String, $zone: String, $language: String, $contactName: String, $phone: String, $email: String, $web: String, $recipients: [String!]) {
    updateCustomer(id: $id, attributes: { kind: $kind, name: $name, address: $address, postalCode: $postalCode, city: $city, town: $town, country: $country, zone: $zone, language: $language, contactName: $contactName, phone: $phone, email: $email, web: $web, recipients: $recipients }) {
      customer {
        ${customerFields}
      }
    }
  }
`;

const customerDeleteMutation = gql`
  mutation deleteCustomer($id: ID!) {
    deleteCustomer(id: $id) { id }
  }
`;

class DetailForm extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func,
    actions: PropTypes.object.isRequired
  }

  state = {
    recipientsInput: '',
    values: { recipients: ['service@artezen.eu'], kind: 'client', name: '' },
    errors: {}
  }

  componentWillMount() {
    if (this.props.customer) {
      this.setState({ values: { ...this.props.customer } });
    }
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, [field]: e.target.value } });
  }

  onNumberChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, [field]: parseInt(e.target.value, 10) } });
  }

  onCountryChange = (field: string, country: string) => {
    this.setState({ values: { ...this.state.values, [field]: country } });
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.state.values }, '__typename');

    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    action({ variables });
  }

  onCustomerDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('customer', 'delete-confirm-title').s,
      message: this.context.lang('customer', 'delete-confirm-lite').s,
      buttons: [
        {
          label: this.context.lang('global', 'yes').s,
          onClick: () => {
            confirmAlert({
              title: this.context.lang('customer', 'delete-confirm-title').s,
              message: this.context.lang('customer', 'delete-confirm').s,
              buttons: [
                {
                  label: this.context.lang('global', 'yes').s,
                  onClick: () => action({ variables: { id: (this.props.customer || {}).id } })
                },
                { label: this.context.lang('global', 'no').s }
              ]
            });
          }
        },
        { label: this.context.lang('global', 'no').s }
      ]
    });
  }

  onRecipientsChange = (value: any) => {
    this.setState({ values: { ...this.state.values, recipients: value.map(item => item.value) } });
  }

  onRecipientsKeyDown = (event: SyntheticKeyboardEvent<HTMLElement>) => {
    const { recipientsInput } = this.state;
    if (!recipientsInput || !this.isEmail(recipientsInput) || this.state.values.recipients.indexOf(recipientsInput) >= 0) return;
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        this.setState({
          recipientsInput: '',
          values: { ...this.state.values, recipients: [...this.state.values.recipients, recipientsInput] }
        });
        event.preventDefault();
        break;
      default: break;
    }
  };

  onDeleteComplete = () => {
    const { dispatch, push } = this.context;

    dispatch(this.context.actions.session.switchCustomer(null));
    setTimeout(() => {
      dispatch(push('/users'));
    }, 10);
  }

  onSelect = (field: string, option: { label: string, value: string }) => {
    this.setState({ values: { ...this.state.values, [field]: option.value } });
  }

  isEmail = (email: string) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  validate = (values: any) => {
    const errors = {};
    ['name'].forEach((f) => {
      if (!values[f] || values[f].trim().length === 0) {
        errors[f] = 'empty';
      }
    });
    return errors;
  }

  render() {
    const { values, errors } = this.state;
    const { lang } = this.context;
    const kinds = [{ value: 'client', label: lang('customer', 'kind-client').s }, { value: 'distributor', label: lang('customer', 'kind-distributor').s }];

    return (
      <div className="page full-page">
        <form className="grid-x">
          <Mutation mutation={this.props.customer ? updateCustomer : createCustomer} onCompleted={this.props.onCompleted}>
            {(action, { loading, error }) => {
              return (
                <div className="cell grid-x">
                  <div className="title cell small-12 medium-6">{ lang('customer', this.props.customer ? 'edit' : 'new').s }</div>

                  <div className="title-action cell small-12 medium-6">
                    {loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onClick.bind(null, action)} value={lang('customer', values.id ? 'edit' : 'create').s} />}
                  </div>
                  <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="customer" />
                </div>
              );
            }}
          </Mutation>
          <label htmlFor="name" className="cell small-12 medium-6">
            <div>{ lang('customer', 'name').s }</div>
            <input className={errors.name ? 'error' : ''} type="text" name="name" value={values.name || ''} onChange={this.onTextChange.bind(null, 'name')} />
          </label>
          <label htmlFor="type" className="cell small-12 medium-6">
            <div>{ lang('customer', 'type').s }</div>
            <Select classNamePrefix="Select" options={kinds} value={values.kind ? kinds.filter(e => e.value === values.kind) : kinds[0]} onChange={this.onSelect.bind(null, 'kind')} clearable={false} searchable={false} />
          </label>
          <label htmlFor="address" className="cell small-12 medium-6">
            <div>{ lang('customer', 'address').s }</div>
            <input className={errors.address ? 'error' : ''} type="text" name="address" value={values.address || ''} onChange={this.onTextChange.bind(null, 'address')} />
          </label>
          <label htmlFor="postalCode" className="cell small-12 medium-6">
            <div>{ lang('customer', 'postalCode').s }</div>
            <input className={errors.postalCode ? 'error' : ''} type="text" name="postalCode" value={values.postalCode || ''} onChange={this.onTextChange.bind(null, 'postalCode')} />
          </label>
          <label htmlFor="city" className="cell small-12 medium-6">
            <div>{ lang('customer', 'city').s }</div>
            <input className={errors.city ? 'error' : ''} type="text" name="city" value={values.city || ''} onChange={this.onTextChange.bind(null, 'city')} />
          </label>
          <label htmlFor="town" className="cell small-12 medium-6">
            <div>{ lang('customer', 'town').s }</div>
            <input className={errors.town ? 'error' : ''} type="text" name="town" value={values.town || ''} onChange={this.onTextChange.bind(null, 'town')} />
          </label>
          <label htmlFor="country" className="cell small-12 medium-6">
            <div>{ lang('customer', 'country').s }</div>
            <ReactFlagsSelect searchable placeholder="Select Country" defaultCountry={values.country || 'IT'} onSelect={this.onCountryChange.bind(null, 'country')} />
          </label>
          <label htmlFor="zone" className="cell small-12 medium-6">
            <div>{ lang('customer', 'zone').s }</div>
            <input className={errors.zone ? 'error' : ''} type="text" name="zone" value={values.zone || ''} onChange={this.onTextChange.bind(null, 'zone')} />
          </label>
          <label htmlFor="language" className="cell small-12 medium-6">
            <div>{ lang('customer', 'language').s }</div>
            <ReactFlagsSelect searchable placeholder="Select Language" defaultCountry={values.language || 'IT'} onSelect={this.onCountryChange.bind(null, 'language')} countries={['US', 'GB', 'FR', 'DE', 'IT']} customLabels={{ US: 'EN-US', GB: 'EN-GB', FR: 'FR', DE: 'DE', IT: 'IT' }} />
          </label>
          <label htmlFor="contactName" className="cell small-12 medium-6">
            <div>{ lang('customer', 'contactName').s }</div>
            <input className={errors.contactName ? 'error' : ''} type="text" name="contactName" value={values.contactName || ''} onChange={this.onTextChange.bind(null, 'contactName')} />
          </label>
          <label htmlFor="phone" className="cell small-12 medium-6">
            <div>{ lang('customer', 'phone').s }</div>
            <input className={errors.phone ? 'error' : ''} type="text" name="phone" value={values.phone || ''} onChange={this.onTextChange.bind(null, 'phone')} />
          </label>
          <label htmlFor="email" className="cell small-12 medium-6">
            <div>{ lang('customer', 'email').s }</div>
            <input className={errors.email ? 'error' : ''} type="email" name="email" value={values.email || ''} onChange={this.onTextChange.bind(null, 'email')} />
          </label>
          <label htmlFor="web" className="cell small-12 medium-6">
            <div>{ lang('customer', 'web').s }</div>
            <input className={errors.web ? 'error' : ''} type="text" name="web" value={values.web || ''} onChange={this.onTextChange.bind(null, 'web')} />
          </label>
          <label htmlFor="recipients" className="cell small-12">
            <div>{ lang('customer', 'recipients').s }</div>
            <CreatableSelect
              className="overflow-auto"
              classNamePrefix="Select"
              components={{ DropdownIndicator: null }}
              inputValue={this.state.recipientsInput}
              isClearable
              isMulti
              menuIsOpen={false}
              onChange={this.onRecipientsChange}
              onInputChange={(value) => { this.setState({ recipientsInput: value }); }}
              onKeyDown={this.onRecipientsKeyDown}
              placeholder={lang('customer', 'recipients-placeholder').s}
              value={this.state.values.recipients.map((i) => { return { label: i, value: i }; })}
            />
          </label>
          { this.props.customer ?
            <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
              <Mutation mutation={customerDeleteMutation} onCompleted={this.onDeleteComplete}>
                {(deleteAction, result) => {
                  return result.loading ? <Spinner /> : <div className="outline-button--subtle small-10 medium-4 cell" onClick={this.onCustomerDelete.bind(null, deleteAction)}>{lang('customer', 'delete').s}</div>;
                }}
              </Mutation>
            </div>
            : null
          }
        </form>
      </div>
    );
  }
}

export default DetailForm;
