import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import querystring from 'query-string';
import { confirmAlert } from 'react-confirm-alert';

import Spinner from '../common/spinner/Spinner';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import Pager from '../common/pager/Pager';

import queries from '../../state/queries';
import { Permissions } from '../../state/authorization';

import styles from './Aside.scss';
import QueryError from '../common/errors/QueryError';

const deleteMutation = gql`
  mutation deleteAlarm($id: ID!) {
    deleteAlarm(id: $id) { id }
  }
`;

const deleteAllMutation = gql`
  mutation clearAllAlarms($deviceId: ID!) {
    clearAllAlarms(deviceId: $deviceId) { deleteCount }
  }
`;

type Props = {
  print?: boolean,
  deviceId: string
}

type State = {
};

class Alarms extends Component<Props, State> {
  static contextTypes = {
    push: PropTypes.func,
    dispatch: PropTypes.func,
    lang: PropTypes.func,
    can: PropTypes.func,
    routing: PropTypes.object
  }

  onPage = (page: number) => {
    const { dispatch, push, routing } = this.context;

    dispatch(push({ pathname: routing.location.pathname, search: `?page=${page}` }));
  }

  onAlarmDelete = (action: Function, alarmId: string) => {
    confirmAlert({
      title: this.context.lang('alarms', 'delete-confirm-title').s,
      message: this.context.lang('alarms', 'delete-confirm').s,
      buttons: [
        {
          label: this.context.lang('global', 'yes').s,
          onClick: () => action({ variables: { id: alarmId } })
        },
        { label: this.context.lang('global', 'no').s }
      ]
    });
  }

  onClearAll = (action: Function) => {
    confirmAlert({
      title: this.context.lang('alarms', 'delete-all-confirm-title').s,
      message: this.context.lang('alarms', 'delete-all-confirm').s,
      buttons: [
        {
          label: this.context.lang('global', 'yes').s,
          onClick: () => action({ variables: { deviceId: this.props.deviceId } })
        },
        { label: this.context.lang('global', 'no').s }
      ]
    });
  }

  render() {
    const { lang, routing, can } = this.context;
    const { print, deviceId } = this.props;

    const page = querystring.parse(routing.location.search).page || 1;

    return (
      <Query query={queries.devices.alarms} variables={{ id: this.props.deviceId, page: parseInt(page, 10) }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.deviceId) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          return (
            <div className={`${print ? 'page-print' : 'page'} full-page`}>
              <div className="grid-x" style={{ width: '100%' }}>
                { print ? null : <Breadcrumbs relative steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${data.device.model} - ${data.device.serial}`, url: `/devices/${this.props.deviceId || ''}` }]} /> }
                { print ? null : <div className="spacer" /> }
                <div className="title cell small-12 medium-6"><span>{lang('device', 'alarm-history').s}</span></div>
                <div className="cell small-12 medium-6 show-for-large">{ print ? null : <Link target="_blank" to={`/print/alarms/${deviceId}`} className="outline-button float-right">{lang('global', 'print').s}</Link> }</div>
                <div className="spacer" />

                <div className="title cell small-12 medium-6">
                  <div className="subtitle">{ lang('device', 'device').s }</div>
                  <span className="subtitle" style={{ color: 'black' }}>{ `${data.device.model} - ${data.device.serial}` }</span>
                </div>

                <div className={`${print ? styles['container--print'] : styles['container--full-page']} ${styles['table-container']} small-12 cell`}>
                  <div style={{ minWidth: '30rem' }} className={`${styles.header} ${styles['table-row-small']}`}>
                    <span className={styles['table-column--xsmall']}>{lang('global', 'date').s}</span>
                    <span className={styles['table-column--xsmall']}>{lang('global', 'time').s}</span>
                    <span className={styles['table-column--xsmall']}>{lang('global', 'alarm').s}</span>
                    { can(Permissions.devices.deviceAlarmDelete) ? <span style={{ flex: 0.2 }} className={styles['table-column--xsmall']} /> : null }
                  </div>
                  { data.alarms.results.map((a) => {
                    const raised = moment(a.raisedAt);
                    return (
                      <div style={{ minWidth: '30rem' }} className={`${styles.item} ${styles['table-row-small']}`} key={a.id}>
                        <span className={styles['table-column--xsmall']}>{raised.format('DD/MM/YYYY')}</span>
                        <span className={styles['table-column--xsmall']}>{raised.format('HH:mm:ss')}</span>
                        <span className={styles['table-column--xsmall']}>{lang('alarms', a.description).s}</span>
                        {
                          (can(Permissions.devices.deviceAlarmDelete) && !print) ?
                            <Mutation mutation={deleteMutation} refetchQueries={[{ query: queries.devices.alarms, variables: { id: this.props.deviceId, page: parseInt(page, 10) } }]}>
                              {(action, mutationResult) => {
                                return mutationResult.loading ? <Spinner size={10} /> : <span style={{ flex: 0.2 }} className={`${styles['table-column--xsmall']} ${styles['table-column-delete']}`} onClick={this.onAlarmDelete.bind(null, action, a.id)}><i className="icon-trash" /></span>;
                              }}
                            </Mutation>
                          :
                          null
                        }
                      </div>
                    );
                    })
                  }
                </div>
                { print ? null : <Pager current={parseInt(page, 10)} pages={data.alarms.totalPages} onPage={this.onPage} /> }
                {(can(Permissions.devices.deviceAlarmDelete) && !print) ?
                  <Mutation mutation={deleteAllMutation} refetchQueries={[{ query: queries.devices.alarms, variables: { id: this.props.deviceId, page: parseInt(page, 10) } }]}>
                    {(action, mutationResult) => {
                      return mutationResult.loading ? <Spinner size={10} /> : <div className="cell small-12">{ print ? null : <div onClick={this.onClearAll.bind(null, action)} className="outline-button float-right">{lang('global', 'delete-all').s}</div> }</div>;
                    }}
                  </Mutation>
                : null }
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Alarms;
