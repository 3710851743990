import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { Permissions } from './state/authorization';

import requireAuth from './components/RequireAuth';
import HomePage from './containers/HomePage';
import CustomersPage from './containers/CustomersPage';
import DevicesPage from './containers/DevicesPage';
import RecipesPage from './containers/RecipesPage';
import SettingsPage from './containers/SettingsPage';
import UsersPage from './containers/UsersPage';
import PlanningsPage from './containers/PlanningsPage';
import PrintPage from './containers/PrintPage';
import ProductionPage from './containers/ProductionPage';
import LoginPage from './containers/LoginPage';
import NotFoundPage from './components/NotFoundPage';

export default (
  <Switch>
    <Route exact path="/" component={requireAuth(HomePage, { permissions: [Permissions.home.homeIndex] })} />

    <Route exact path="/settings" component={requireAuth(SettingsPage, { permissions: [Permissions.settings.settingsIndex] })} />

    <Route exact path="/customers" component={requireAuth(CustomersPage, { permissions: [Permissions.customers.customersIndex] })} />
    <Route exact path="/customers/new" component={requireAuth(CustomersPage, { permissions: [Permissions.customers.customersIndex] })} />
    <Route exact path="/customers/:id" component={requireAuth(CustomersPage, { permissions: [Permissions.customers.customersIndex] })} />

    <Route exact path="/users" component={requireAuth(UsersPage, { permissions: [Permissions.users.usersIndex] })} />
    <Route exact path="/users/new" component={requireAuth(UsersPage, { permissions: [Permissions.users.usersIndex] })} />
    <Route exact path="/users/:id" component={requireAuth(UsersPage, { permissions: [Permissions.users.usersIndex] })} />

    <Route exact path="/devices" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesIndex] })} />
    <Route exact path="/devices/new" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesCreate] })} />
    <Route exact path="/devices/:id" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesDetail] })} />
    <Route exact path="/devices/:id/configs" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesConfig] })} />
    <Route exact path="/devices/:id/maintenance-requests/new" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesDetail] })} />
    <Route exact path="/devices/:id/production" component={requireAuth(DevicesPage, { permissions: [Permissions.production.productionIndex] })} />
    <Route exact path="/devices/:id/alarms" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesAlarmHistory] })} />
    <Route exact path="/devices/:id/sanitations" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesIndex] })} />
    <Route exact path="/devices/:id/maintenance" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesIndex] })} />
    <Route exact path="/devices/:id/weight-reports" component={requireAuth(DevicesPage, { permissions: [Permissions.devices.devicesIndex] })} />

    <Route exact path="/devices/:device/recipes/new" component={requireAuth(RecipesPage, { permissions: [Permissions.production.productionIndex] })} />
    <Route exact path="/devices/:device/recipes/:id" component={requireAuth(RecipesPage, { permissions: [Permissions.production.productionIndex] })} />

    <Route exact path="/plannings" component={requireAuth(PlanningsPage, { permissions: [Permissions.production.productionIndex] })} />
    <Route exact path="/devices/:device/plannings/new" component={requireAuth(PlanningsPage, { permissions: [Permissions.production.productionIndex] })} />
    <Route exact path="/devices/:device/plannings/:id" component={requireAuth(PlanningsPage, { permissions: [Permissions.production.productionIndex] })} />

    <Route exact path="/production/:id" component={requireAuth(ProductionPage, { permissions: [Permissions.production.productionIndex] })} />
    <Route exact path="/production/:id/:planningItemId" component={requireAuth(ProductionPage, { permissions: [Permissions.production.productionIndex] })} />

    <Route exact path="/print/:type/:id/" component={requireAuth(PrintPage, { permissions: [Permissions.home.homeIndex] })} />
    <Route exact path="/print/:type/:id/:optional" component={requireAuth(PrintPage, { permissions: [Permissions.home.homeIndex] })} />

    <Route path="/login" component={LoginPage} />
    <Route component={NotFoundPage} />
  </Switch>
);
