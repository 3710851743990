import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import moment from 'moment';
import Dropzone from 'react-dropzone';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import styles from './Device.scss';

import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import { Permissions } from '../../state/authorization';

const createDocumentMutation = gql`
  mutation createDocument($description: String, $device: DeviceRelationAttributes!, $file: Upload!) {
    createDocument(attributes: { description: $description, device: $device, file: $file }) {
      document {
        id, description, file, createdAt
        device {
          id
        }
      }
    }
  }
`;

const deleteDocumentMutation = gql`
  mutation deleteDocument($id: ID!) {
    deleteDocument(id: $id) { id }
  }
`;

type Props = {
  device: any,
  documents: Array<any>,
  refetch: any
}

type State = {
  variables: any,
  showDocumentForm: boolean,
  moreDocument: boolean,
}

class Documents extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    can: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func,
  }

  static defaultProps = {
    documents: []
  }

  state = { variables: {}, showDocumentForm: false, moreDocument: false }

  onFileChange = (f: any) => {
    this.setState({ variables: { ...this.state.variables, file: f[0] } });
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ variables: { ...this.state.variables, [field]: e.target.value } });
  }

  onDocumentUpload = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();

    action({ variables: { ...this.state.variables, device: { id: this.props.device.id } } });
  }

  onDocumentDelete = (action: Function, firmareId: string) => {
    confirmAlert({
      title: this.context.lang('document', 'delete-confirm-title').toString(),
      message: this.context.lang('document', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: firmareId } })
        },
        {
          label: this.context.lang('global', 'no').toString()
        }
      ]
    });
  }

  render() {
    const { documents } = this.props;
    const { lang, can } = this.context;
    const { variables } = this.state;

    return (
      <div>
        <div className="grid-x">
          <div className="subtitle small-10 cell">{ lang('device', 'documents').s }</div>
          { can(Permissions.documents.documentsCreate) ?
            <div className={`${styles['inline-button']} ${styles['inline-button__header']} small-2 cell`} onClick={() => this.setState({ showDocumentForm: true })}>{lang('global', 'new').s}</div>
          : null }
          <div className="small-12 cell">
            {
              this.state.showDocumentForm ?
                <Mutation mutation={createDocumentMutation} onCompleted={() => this.setState({ showDocumentForm: false, variables: {} })} refetchQueries={[this.props.refetch]}>
                  {(action, { loading, error }) => {
                    return (
                      <form className={`grid-x ${styles.firmware}`}>
                        <MutationError graphQLError={error} lang={lang} langKey="document" />

                        <div className="title cell small-12 medium-6">{ lang('document', 'new').s }</div>
                        <div className="title-action cell small-12 medium-6">
                          {loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onDocumentUpload.bind(null, action)} value={lang('global', 'upload').s} />}
                        </div>

                        <label htmlFor="file" className="cell small-12">
                          <div>{ lang('document', 'file').s }</div>
                          <Dropzone className={styles.dropzone} activeClassName={styles['dropzone--active']} multiple={false} name="file" onDrop={this.onFileChange}>
                            {variables.file ? variables.file.name : lang('document', 'no-file').toString()}
                          </Dropzone>
                          {variables.file ? <span onClick={() => { this.setState({ variables: { ...this.state.variables, file: null } }); }} className={styles['file-remove']}>{lang('document', 'remove').s}</span> : null}
                        </label>
                        <label htmlFor="model" className="cell small-12">
                          <div>{ lang('document', 'description').s }</div>
                          <textarea name="notes" value={variables.description || ''} onChange={this.onTextChange.bind(null, 'description')} />
                        </label>
                      </form>
                    );
                  }}
                </Mutation>
                : null
            }
          </div>
        </div>
        {
          documents.length > 0 ?
          documents.slice(0, this.state.moreDocument ? documents.length : 4).map((f) => {
            const { fileName } = f;
            const description = f.description || 'No description';
            const dots = fileName.split('.');
            const ext = dots[dots.length - 1];
            return (
              <span className="subtitle grid-x" key={f.id}>
                <div className={`small-1 cell ${styles.icon} show-for-large`}>
                  <i className={`icon-file-${ext.toLocaleLowerCase()}`} />
                </div>
                <div className="small-9 cell">
                  <div className="small-12 cell">{unescape(fileName)}</div>
                  <div className={`${styles.subtitle} small-12 cell`}>
                    {description}
                  </div>
                  <div className={`small-12 cell ${styles.subtitle}`}>{moment(f.createdAt).format('DD/MM/YYYY')}</div>
                </div>
                <div className={`small-2 cell ${styles['accessory-container']}`}>
                  { can(Permissions.documents.documentsCreate) ?
                    /* flowlint-line-ignore */
                    <Mutation mutation={deleteDocumentMutation} refetchQueries={[this.props.refetch]}>
                      {(action, { loading }) => {
                        return loading ? <Spinner size={20} /> : <span className={styles.delete}><i className="icon-trash" onClick={this.onDocumentDelete.bind(null, action, f.id)} /></span>;
                      }}
                    </Mutation>
                  : null }
                  <a href={f.file} className={styles.download}><i className="icon-cloud-download" /></a>
                </div>
              </span>
            );
          })
          : <span className={styles['no-firmware']}>{lang('document', 'no-document').s}</span>
        }
        <div className={styles.more} style={documents.length > 4 ? {} : { display: 'none' }} onClick={() => this.setState({ moreDocument: !this.state.moreDocument })}>{this.state.moreDocument ? lang('global', 'less').s : lang('global', 'more').s}</div>
      </div>
    );
  }
}

export default Documents;
