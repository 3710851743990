import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import styles from './Device.scss';

import Spinner from '../common/spinner/Spinner';
import TabPanel from '../common/tabpanel/TabPanel';
import Firmwares from './Firmwares';
import Documents from './Documents';
import Vnc from './Vnc';
import Status from './Status';
import LiveAlarms from './LiveAlarms';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import { Permissions } from '../../state/authorization';

const deviceDeleteMutation = gql`
  mutation deleteDevice($id: ID!) {
    deleteDevice(id: $id) { id }
  }
`;

type Props = {
  device: any,
  firmwares: Array<any>,
  documents: Array<any>,
  onEdit: Function,
  onDelete: Function,
  onFirmwareSent: Function,
  refetch: any
}

type State = {
  loadingRequestPage: boolean
}

class Device extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func,
    can: PropTypes.func.isRequired
  }

  static defaultProps = {
    firmwares: [],
    documents: []
  }

  state = {
    loadingRequestPage: false
  }

  componentWillUnmount() {
    if (this.requestDelay) {
      clearTimeout(this.requestDelay);
    }
  }

  onDeviceDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('device', 'delete-confirm-title').toString(),
      message: this.context.lang('device', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: this.props.device.id } })
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  onRequest = () => {
    const { dispatch, push } = this.context;

    if (sessionStorage.getItem(`${this.props.device.remoteId}:data`) !== '{}') {
      dispatch(push(`/devices/${this.props.device.id}/maintenance-requests/new`));
      return;
    }

    this.setState({ loadingRequestPage: true });

    clearTimeout(this.requestDelay);
    this.requestDelay = setTimeout(() => {
      dispatch(push(`/devices/${this.props.device.id}/maintenance-requests/new`));
    }, 5000);
  }

  requestDelay = null

  render() {
    const { device, onEdit } = this.props;
    const { lang, dispatch, push, can } = this.context;

    const isLybra = device.profileType === 'LybraProfile';
    let rightItemsCount = 1;
    if (can(Permissions.devices.devicesAlarmHistory) && isLybra) {
      rightItemsCount = 4;
    } else if (can(Permissions.devices.devicesAlarmHistory)) {
      rightItemsCount = 3;
    } else if (isLybra) {
      rightItemsCount = 2;
    }

    const showProduction = can(Permissions.production.productionIndex) && (device.profileType === 'CalybraProfile' || (device.profileType === 'LybraProfile' && device.profile.mode === 'standalone'));
    return (
      <TabPanel tabsPosition="bottom" tabs={[lang('global', 'details-tab-title').s, lang('global', 'data-tab-title').s]}>
        <div className="page grid-x">
          <Breadcrumbs relative steps={[{ title: lang('devices', 'devices').s, url: '/devices' }]} />
          <div className="spacer" />
          <div className="title cell small-12 medium-6">
            <span>{`${lang('devices', device.profileType).s} ${device.model}`}</span><br />
            <span className={styles['info-light']}>{device.customer.name}</span>
          </div>
          <div className="title-action cell small-12 medium-6">
            { can(Permissions.devices.devicesCreate) ? <input className="outline-button float-right" type="submit" onClick={onEdit} value={lang('device', 'edit').s} /> : null }
          </div>
          <div className="spacer" />
          <div className={`cell small-12 ${styles.info}`}>
            <div className="subtitle">{ lang('device', 'device').s }</div>
            <span className="subtitle">{ `${device.model} - ${device.serial}` }</span>
            { device.profileType === 'CalybraProfile' ? (
              <div>
                <div className="subtitle">{ lang('device', 'weightIncrement').s }</div>
                <span className="subtitle">{ lang('device', device.profile.weightIncrement).s }</span>
                <div className="subtitle">{ lang('device', 'exit').s }</div>
                <span className="subtitle">{ lang('device', device.profile.exit).s }</span>
                <div className="subtitle">{ lang('device', 'dusters').s }</div>
                <span className="subtitle">{ device.profile.dusters }</span>
              </div>
            ) : (
              <div>
                <div className="subtitle">{ lang('device', 'mode').s }</div>
                <span className="subtitle">{ lang('device', device.profile.mode).s }</span>
              </div>
            )}
            <div className="grid-x grid-margin-x">
              { showProduction ? (
                <div className={`small-12 ${can(Permissions.devices.devicesConfig) ? 'medium-6' : ''} cell card-button`} onClick={() => dispatch(push(`/devices/${this.props.device.id}/production`))}>
                  <span className="icon-production button-icon" />
                  <div className="primary-text">{ lang('device', 'production').s }</div>
                  <span className="secondary-text">{lang('global', 'open').s}</span>
                </div>
              ) : null }
              { can(Permissions.devices.devicesConfig) ? (
                <div className={`small-12 ${showProduction ? 'medium-6' : ''} cell card-button`} onClick={() => dispatch(push(`/devices/${this.props.device.id}/configs`))}>
                  <span className="icon-configuration button-icon" />
                  <div className="primary-text">{ lang('device', 'configs').s }</div>
                  <span className="secondary-text">{lang('device', 'show').s}</span>
                </div>
              ) : null }
              { can(Permissions.devices.devicesRequest) ? (
                <div className={`small-12 ${can(Permissions.devices.devicesVNC) ? 'medium-6' : ''} cell card-button`} onClick={this.onRequest}>
                  { this.state.loadingRequestPage ? <Spinner /> : <span className="icon-maintenance button-icon" /> }
                  <div className="primary-text">{ lang('device', 'maintenance-request').s }</div>
                  <span className="secondary-text">{ this.state.loadingRequestPage ? lang('global', 'loading').s : lang('global', 'open').s}</span>
                </div>
              ) : null }
              { can(Permissions.devices.devicesVNC) ?
                <Vnc device={this.props.device} />
              : null }
            </div>
            { this.props.device.notes ? (
              <div>
                <div className="line-divider" style={{ marginBottom: '1rem' }} />
                <div className="subtitle small-10 cell">{ lang('device', 'notes').s }</div>
                <textarea readOnly className={styles['notes-text']} value={this.props.device.notes} />
              </div>
            ) : null }
            <div className="line-divider" />
            <Documents documents={this.props.documents} refetch={this.props.refetch} device={this.props.device} />
            <div className="line-divider" />
            { can(Permissions.firmwares.firmwaresIndex) ? <Firmwares fancyboxOnly={false} firmwares={this.props.firmwares} refetch={this.props.refetch} device={this.props.device} onComplete={this.props.onFirmwareSent} /> : null }
            {
              can(Permissions.devices.devicesDelete) ? (
                <div className={`grid-x ${styles['space-above']} centered`}>
                  <Mutation mutation={deviceDeleteMutation} onCompleted={this.props.onDelete}>
                    {(action, { loading }) => {
                      return loading ? <Spinner /> : <div className="outline-button--subtle small-10 medium-4 cell" onClick={this.onDeviceDelete.bind(null, action)}>{lang('device', 'delete').s}</div>;
                    }}
                  </Mutation>
                </div>
              ) : null
            }
            <div className="spacer" />
          </div>
        </div>
        <aside className="grid-x">
          <Status device={device} />
          <div className="spacer" />
          <LiveAlarms device={device} />
          <div className="spacer" />
          <div className="grid-x small-12" style={{ display: 'flex', flexDirection: 'row' }}>
            { can(Permissions.devices.devicesAlarmHistory) ? (
              <div className={`small-12 medium-${12 / rightItemsCount} cell card-button`} onClick={() => dispatch(push(`/devices/${this.props.device.id}/alarms`))}>
                <span className="icon-production button-icon" />
                <div className="primary-text">{ lang('device', 'alarm-history').s }</div>
                <span className="secondary-text">{lang('global', 'open').s}</span>
              </div>
            ) : null }
            <div className={`small-12 medium-${12 / rightItemsCount} cell card-button`} onClick={() => dispatch(push(`/devices/${this.props.device.id}/sanitations`))}>
              <span className="icon-configuration button-icon" />
              <div className="primary-text">{ lang('device', 'sanification-report').s }</div>
              <span className="secondary-text">{lang('device', 'show').s}</span>
            </div>
            {
              isLybra
                ? (
                  <div className={`small-12 medium-${12 / rightItemsCount} cell card-button`} onClick={() => dispatch(push(`/devices/${this.props.device.id}/weight-reports`))}>
                    <span className="icon-scale button-icon" />
                    <div className="primary-text">{ lang('device', 'weight-report').s }</div>
                    <span className="secondary-text">{lang('device', 'show').s}</span>
                  </div>
                ) : null
            }
            { can(Permissions.devices.devicesAlarmHistory) ? (
              <div className={`small-12 medium-${12 / rightItemsCount} cell card-button`} onClick={() => dispatch(push(`/devices/${this.props.device.id}/maintenance`))}>
                <span className="icon-tools1 button-icon" />
                <div className="primary-text">{ lang('device', 'maintenance-operations').s }</div>
                <span className="secondary-text">{lang('global', 'open').s}</span>
              </div>
              ) : null }
          </div>
        </aside>
      </TabPanel>
    );
  }
}

export default Device;
