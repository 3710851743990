import React from 'react';
import PropTypes from 'prop-types';
import styles from './Spinner.scss';

const Spinner = ({ darkBackground, size, absolute }: any) => {
  let view = (
    <div className={`${styles.spinner} ${darkBackground ? styles.white : ''} ${size <= 24 ? styles.small : ''}`} style={{ width: `${size}px`, height: `${size}px` }} />
  );

  if (absolute) {
    view = (
      <div
        className={`${styles.spinner} ${darkBackground ? styles.white : ''} ${size <= 24 ? styles.small : ''} ${styles['spinner--absolute']}`}
        style={{
          width: `${size}px`, height: `${size}px`, marginLeft: `-${size / 2}px`, marginTop: `-${size / 2}px`
        }}
      />
    );
  }

  return view;
};

Spinner.propTypes = {
  darkBackground: PropTypes.bool,
  size: PropTypes.number,
  absolute: PropTypes.bool
};

Spinner.defaultProps = {
  darkBackground: false,
  size: 32,
  absolute: false
};

export default Spinner;
