import React, { Component } from 'react';
import PropTypes from 'prop-types';

import styles from './Login.scss';

type Props = {
  onLogin: ({ email?: string, password?: string }) => {},
  authenticating: boolean,
  error: ?string
}

type State = {
  email: string,
  password: string,
}

class Login extends Component<Props, State> {
  static defaultProps = {
    authenticating: false
  }

  static contextTypes = {
    lang: PropTypes.func
  }

  state = { email: '', password: '' }

  onChange = (e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ [e.target.name]: e.target.value });
  }

  onLogin = (event: SyntheticEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (this.props.onLogin && this.state.email.length >= 3 && this.state.password.length >= 3) {
      this.props.onLogin({ email: this.state.email, password: this.state.password });
    }
    return false;
  }

  render() {
    const { lang } = this.context;
    const { authenticating, error } = this.props;
    const { email, password } = this.state;

    const form = (
      <div>
        <img className={`hide-for-large ${styles.logo}`} src={require('../../images/logo.png')} alt="logo" />
        <div className={styles.error}>{error ? error : ''}</div>
        <form className={`${styles.form} grid-x`} onSubmit={this.onLogin} >
          <input type="text" name="email" autoCapitalize="off" className="small-12 cell" onChange={this.onChange} value={email} placeholder={`${lang('session', 'email').s}`} />
          <input type="password" name="password" className="small-12 cell" onChange={this.onChange} value={password} placeholder={`${lang('session', 'password').s}`} />
          <input type="submit" className={`secondary text-upcase small-12 cell ${styles['button-border']}`} value={lang('session', 'login').toString()} disabled={authenticating} />
        </form>
      </div>
    );

    return (
      <div className={styles.container}>
        <div className={`show-for-large ${styles.panel}`}>
          <img className={styles.logo} src={require('../../images/logo.png')} alt="logo" />
        </div>
        <div className={styles['right-panel']}>
          {form}
        </div>
      </div>
    );
  }
}

export default Login;
