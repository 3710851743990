import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import Select from 'react-select';
import omitDeep from 'omit-deep-lodash';
import _ from 'lodash';

import styles from './DetailForm.scss';
import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import NumericInput from '../common/form/NumericInput';
import SelectCard from '../common/card/SelectCard';
import TabPanel from '../common/tabpanel/TabPanel';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import queries, { commonDeviceFields, calybraFields, lybraFields } from '../../state/queries';
import Pager from '../common/pager/Pager';
import QueryError from '../common/errors/QueryError';

const createLybra = gql`
  mutation createLybra($model: String!, $serial: String!, $notes: String, $customer: CustomerRelationAttributes!, $profile: LybraProfileAttributes!, $userIds: [String!]) {
    createLybra(attributes: { customer: $customer, model: $model, serial: $serial, notes: $notes, userIds: $userIds }, profile: $profile) {
      device {
        ${commonDeviceFields}
        profile {
          ${lybraFields}
        }
      }
    }
  }
`;

const updateLybra = gql`
  mutation updateLybra($id: ID!, $model: String!, $serial: String!, $notes: String, $customer: CustomerRelationAttributes!, $profile: LybraProfileAttributes!, $userIds: [String!]) {
    updateLybra(id: $id, attributes: { customer: $customer, model: $model, serial: $serial, notes: $notes, userIds: $userIds }, profile: $profile) {
      device {
        ${commonDeviceFields}
        profile {
          ${lybraFields}
        }
      }
    }
  }
`;

const createCalybra = gql`
  mutation createCalybra($model: String!, $serial: String!, $notes: String, $customer: CustomerRelationAttributes!, $profile: CalybraProfileAttributes!, $userIds: [String!]) {
    createCalybra(attributes: { customer: $customer, model: $model, serial: $serial, notes: $notes, userIds: $userIds }, profile: $profile) {
      device {
        ${commonDeviceFields}
        profile {
          ${calybraFields}
        }
      }
    }
  }
`;

const updateCalybra = gql`
  mutation updateCalybra($id: ID!, $model: String!, $serial: String!, $notes: String, $customer: CustomerRelationAttributes!, $profile: CalybraProfileAttributes!, $userIds: [String!]) {
    updateCalybra(id: $id, attributes: { customer: $customer, model: $model, serial: $serial, notes: $notes, userIds: $userIds }, profile: $profile) {
      device {
        ${commonDeviceFields}
        profile {
          ${calybraFields}
        }
      }
    }
  }
`;

type Props = {
  device?: any,
  onCompleted: Function
}

type State = {
  values: any,
  type: ?string,
  selectedUsers: Array<any>,
  division: string,
  errors: any,
  search: string,
  page: number
}

class DetailForm extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = {
    values: { profile: { version: { id: null }, lybra: { id: null }, dusters: 0 }, customer: {} },
    division: 'single',
    selectedUsers: [],
    type: null,
    errors: {},
    search: '',
    page: 1
  }

  componentWillMount() {
    if (this.props.device) {
      if (this.props.device.profileType === 'CalybraProfile') {
        const lybra = this.props.device.profile.lybra ? { id: this.props.device.profile.lybra.id, model: this.props.device.profile.lybra.model, serial: this.props.device.profile.lybra.serial } : {};

        this.setState({
          division: this.props.device.profile.version.division,
          values: { ...this.props.device,
            customer: { id: this.props.device.customer.id, name: this.props.device.customer.name },
            profile: { ...this.props.device.profile,
              version: { id: this.props.device.profile.version.id },
              lybra
            }
          },
          type: this.props.device.profileType,
          selectedUsers: this.props.device.users
        });
      } else {
        this.setState({
          values: { ...this.props.device,
            customer: { id: this.props.device.customer.id, name: this.props.device.customer.name }
          },
          type: this.props.device.profileType,
          selectedUsers: this.props.device.users
        });
      }
    }
  }

  onUserSelect = (user: any) => {
    const { selectedUsers } = this.state;
    this.setState({ selectedUsers: [...selectedUsers, user] });
  }

  onUserDeselect = (id: string) => {
    const { selectedUsers } = this.state;
    this.setState({ selectedUsers: _.filter(selectedUsers, u => u.id !== id) });
  }

  onPage = (page: number) => {
    this.setState({ page });
  }

  onClick = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.state.values }, '__typename');

    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }

    if (this.state.type === 'LybraProfile') {
      delete variables.profile.version;
      delete variables.profile.lybra;
      delete variables.profile.dusters;
      delete variables.profile.weightIncrement;
      delete variables.profile.exit;
      delete variables.profile.rounder;
    }
    if (this.state.type === 'CalybraProfile') {
      if (!variables.profile.lybra || !variables.profile.lybra.id) {
        delete variables.profile.lybra;
        delete variables.profile.mode;
      }
    }

    action({ variables: { ...variables, userIds: this.state.selectedUsers.map(u => u.id) } });
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, [field]: e.target.value } });
  }

  onNumberChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: parseInt(e.target.value, 10) } } });
  }

  onTypeChange = (option: { label: string, value: string }) => {
    this.setState({ type: option.value });
  }

  onCustomerSelect = (option: { label: string, value: string }) => {
    this.setState({ ...this.state, values: { ...this.state.values, customer: { id: option.value, name: option.label } } });
    // flowlint-line-ignore
    this.customerSelectField.blur(); // Select is bugged, if you search and select from the results you need a blur event to see the change
  }

  onCustomerSearch = (refetch: Function, search: string) => {
    refetch({ search });
    this.setState({ search });
  }

  onCheckboxChange = (field: string, checked: boolean) => {
    this.setState({ ...this.state, values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: checked } } });
  }

  onSelect = (field: string, option: { label: string, value: string }) => {
    this.setState({ ...this.state, values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: option.value } } });
  }

  onSelectId = (field: string, option: { label: string, value: string }) => {
    if (!option || !option.value) {
      this.setState({ ...this.state, values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: null } } });
      return;
    }

    if (this.state.values.profile[field] && this.state.values.profile[field].id === option.value) {
      return;
    }

    this.setState({ ...this.state, values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: { id: option ? option.value : null } } } });
  }

  onWeightIncrementChange = (yields: any, field: string, option: { label: string, value: string }) => {
    this.onSelect(field, option);
    setTimeout(() => {
      this.onDefaultChange(yields, true);
    }, 200);
  }

  onVersionChange = (yields: any, field: string, option: { label: string, value: string }) => {
    this.onSelectId(field, option);
    setTimeout(() => {
      this.onDefaultChange(yields, true);
    }, 200);
  }

  onDefaultChange = (yields: any, force: boolean) => {
    const { values } = this.state;
    let defaults = {};

    // If both version and weight increment are avaialble, I can assess the defaults, only if none was already specified (hence the check against yield min)
    if (values.profile.weightIncrement && values.profile.version && (!values.profile.singleYieldMin || force)) {
      let v = _.find(yields, y => y.version.id === values.profile.version.id && y.division === 'single');

      if (v) {
        if (values.profile.weightIncrement === 'large') {
          defaults = { ...defaults, singleWeightMin: v.weightLargeMin, singleWeightMax: v.weightLargeMax };
        } else {
          defaults = { ...defaults, singleWeightMin: v.weightStandardMin, singleWeightMax: v.weightStandardMax };
        }
        defaults = { ...defaults, singleYieldMin: v.yieldMin, singleYieldMax: v.yieldMax };
      }

      v = _.find(yields, y => y.version.id === values.profile.version.id && y.division === 'double');

      if (v) {
        if (values.profile.weightIncrement === 'large') {
          defaults = { ...defaults, doubleWeightMin: v.weightLargeMin, doubleWeightMax: v.weightLargeMax };
        } else {
          defaults = { ...defaults, doubleWeightMin: v.weightStandardMin, doubleWeightMax: v.weightStandardMax };
        }
        defaults = { ...defaults, doubleYieldMin: v.yieldMin, doubleYieldMax: v.yieldMax };
      }
    }

    // flowlint-line-ignore
    const { division } = _.find(yields, y => y.version.id === values.profile.version.id).version;

    this.setState({ division, values: { ...values, profile: { ...values.profile, ...defaults } } });
  }

  getLybraFields = (values: any) => {
    const { lang } = this.context;
    const modes = [{ label: lang('device', 'standalone').s, value: 'standalone' }, { label: lang('device', 'combined').s, value: 'combined' }];
    return (
      <div className="cell grid-x">
        <label htmlFor="mode" className="cell small-12">
          <div>{ lang('device', 'mode').s }</div>
          <Select classNamePrefix="Select" options={modes} value={modes.filter(e => e.value === values.profile.mode)} onChange={this.onSelect.bind(null, 'mode')} clearable={false} searchable={false} />
        </label>
      </div>
    );
  }

  getCalybraFields = (values: any) => {
    const { lang } = this.context;

    return (
      <Query query={queries.devices.calybraMetadata} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          const lybras = data.devices.results.filter(d => d.profile.mode === 'combined').map((d) => { return { label: `${d.model} - ${d.serial}`, value: d.id }; });
          if (values.profile.lybra && values.profile.lybra.id) {
            lybras.push({ label: `${values.profile.lybra.model} - ${values.profile.lybra.serial}`, value: values.profile.lybra.id });
          }

          let availableDivisions;
          let weightIncrements = [];
          if (values.profile.version.id) {
            availableDivisions = _.find(data.yields, y => y.version.id === values.profile.version.id).version.division;
            weightIncrements = _.find(data.yields, y => y.version.id === values.profile.version.id).version.weightIncrements.map((w) => { return { label: lang('device', w).s, value: w }; });
          }
          const calybraVersions = data.calybraVersions.map((v) => { return { label: v.version, value: v.id }; });
          const exits = data.calybraExits.map((e) => { return { label: lang('device', e).s, value: e }; });
          const dusters = [{ label: '1', value: 1 }, { label: '2', value: 2 }, { label: lang('global', 'none').s, value: 0 }];
          const rounders = [{ label: lang('device', 'rounder-off').s, value: false }, { label: lang('device', 'rounder-on').s, value: true }];
          return (
            <div className="cell grid-x">
              <div className="cell grid-x">
                <label htmlFor="version" className="cell small-12 medium-6">
                  <div>{ lang('device', 'version').s }</div>
                  <Select classNamePrefix="Select" options={calybraVersions} value={values.profile.version ? calybraVersions.filter(e => e.value === values.profile.version.id) : null} onChange={this.onVersionChange.bind(null, data.yields, 'version')} clearable={false} searchable={false} />
                </label>
                <label htmlFor="weightIncrement" className="cell small-12 medium-6">
                  <div>{ lang('device', 'weightIncrement').s }</div>
                  <Select classNamePrefix="Select" options={weightIncrements} value={weightIncrements.filter(e => e.value === values.profile.weightIncrement)} onChange={this.onWeightIncrementChange.bind(null, data.yields, 'weightIncrement')} clearable={false} searchable={false} />
                </label>
                <label htmlFor="exit" className="cell small-12 medium-6">
                  <div>{ lang('device', 'calybraExit').s }</div>
                  <Select classNamePrefix="Select" options={exits} value={exits.filter(e => e.value === values.profile.exit)} onChange={this.onSelect.bind(null, 'exit')} clearable={false} searchable={false} />
                </label>
                { values.profile.exit === 'side' ? (
                  <label htmlFor="rounder" className="cell small-12 medium-6">
                    <div>{ lang('device', 'rounder').s }</div>
                    <Select classNamePrefix="Select" options={rounders} value={rounders.filter(e => e.value === values.profile.rounder)} onChange={this.onSelect.bind(null, 'rounder')} clearable={false} searchable={false} />
                  </label>
                ) : <div className="cell small-12 medium-6" /> }
                <label htmlFor="duster" className="cell small-12 medium-6">
                  <div>{ lang('device', 'duster').s }</div>
                  <Select classNamePrefix="Select" options={dusters} value={values.profile.dusters ? dusters.filter(e => e.value === values.profile.dusters) : dusters[0]} onChange={this.onSelect.bind(null, 'dusters')} clearable={false} searchable={false} />
                </label>
                <label htmlFor="lybra" className="cell small-12 medium-6">
                  <div>{ lang('device', 'lybra').s }</div>
                  <Select classNamePrefix="Select" options={lybras} value={values.profile.lybra ? lybras.filter(e => e.value === values.profile.lybra.id) : null} onChange={this.onSelectId.bind(null, 'lybra')} searchable={false} />
                </label>
              </div>
              <div className="line-divider" />
              <div className="cell grid-x" style={values.profile.weightIncrement && values.profile.version.id ? {} : { display: 'none' }}>
                <label htmlFor="singleWeightMin" className="cell small-12 medium-6" style={availableDivisions === 'single' || availableDivisions === 'both' ? {} : { display: 'none' }}>
                  <div>{ lang('device', 'singleWeightMin').s }</div>
                  <NumericInput name="singleWeightMin" value={values.profile.singleWeightMin} onChange={this.onNumberChange.bind(null, 'singleWeightMin')} />
                </label>
                <label htmlFor="doubleWeightMin" className="cell small-12 medium-6" style={availableDivisions === 'double' || availableDivisions === 'both' ? {} : { display: 'none' }}>
                  <div>{ lang('device', 'doubleWeightMin').s }</div>
                  <NumericInput name="doubleWeightMin" value={values.profile.doubleWeightMin} onChange={this.onNumberChange.bind(null, 'doubleWeightMin')} />
                </label>
                <label htmlFor="singleWeightMax" className="cell small-12 medium-6" style={availableDivisions === 'single' || availableDivisions === 'both' ? {} : { display: 'none' }}>
                  <div>{ lang('device', 'singleWeightMax').s }</div>
                  <NumericInput name="singleWeightMax" value={values.profile.singleWeightMax} onChange={this.onNumberChange.bind(null, 'singleWeightMax')} />
                </label>
                <label htmlFor="doubleWeightMax" className="cell small-12 medium-6" style={availableDivisions === 'double' || availableDivisions === 'both' ? {} : { display: 'none' }}>
                  <div>{ lang('device', 'doubleWeightMax').s }</div>
                  <NumericInput name="doubleWeightMax" value={values.profile.doubleWeightMax} onChange={this.onNumberChange.bind(null, 'doubleWeightMax')} />
                </label>
                <label htmlFor="singleYieldMin" className="cell small-12 medium-6" style={availableDivisions === 'single' || availableDivisions === 'both' ? {} : { display: 'none' }}>
                  <div>{ lang('device', 'singleYieldMin').s }</div>
                  <NumericInput name="singleYieldMin" value={values.profile.singleYieldMin} onChange={this.onNumberChange.bind(null, 'singleYieldMin')} />
                </label>
                <label htmlFor="doubleYieldMin" className="cell small-12 medium-6" style={availableDivisions === 'double' || availableDivisions === 'both' ? {} : { display: 'none' }}>
                  <div>{ lang('device', 'doubleYieldMin').s }</div>
                  <NumericInput name="doubleYieldMin" value={values.profile.doubleYieldMin} onChange={this.onNumberChange.bind(null, 'doubleYieldMin')} />
                </label>
                <label htmlFor="singleYieldMax" className="cell small-12 medium-6" style={availableDivisions === 'single' || availableDivisions === 'both' ? {} : { display: 'none' }}>
                  <div>{ lang('device', 'singleYieldMax').s }</div>
                  <NumericInput name="singleYieldMax" value={values.profile.singleYieldMax} onChange={this.onNumberChange.bind(null, 'singleYieldMax')} />
                </label>
                <label htmlFor="doubleYieldMax" className="cell small-12 medium-6" style={availableDivisions === 'double' || availableDivisions === 'both' ? {} : { display: 'none' }}>
                  <div>{ lang('device', 'doubleYieldMax').s }</div>
                  <NumericInput name="doubleYieldMax" value={values.profile.doubleYieldMax} onChange={this.onNumberChange.bind(null, 'doubleYieldMax')} />
                </label>
                <div className={`cell small-12 ${styles.reload}`} onClick={this.onDefaultChange.bind(null, data.yields, true)}>
                  {lang('device', 'load-defaults').s}
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }

  validate = (values: any) => {
    const errors = {};
    if (!values.customer || !values.customer.id) {
      errors.customer = 'empty';
    }
    ['model', 'serial'].forEach((f) => {
      if (!values[f] || values[f].trim().length === 0) {
        errors[f] = 'empty';
      }
    });

    const { division, type } = this.state;

    if (type === 'CalybraProfile') {
      let fields = ['exit'];
      if (division === 'single' || division === 'both') {
        fields = [...fields, 'singleWeightMin', 'singleWeightMax', 'singleYieldMin', 'singleYieldMax'];
      }
      if (division === 'double' || division === 'both') {
        fields = [...fields, 'doubleWeightMin', 'doubleWeightMax', 'doubleYieldMin', 'doubleYieldMax'];
      }

      fields.forEach((f) => {
        if (!values.profile[f] || `${values.profile[f]}`.trim().length === 0) {
          errors[f] = 'empty';
        }
      });
    }

    return errors;
  }

  customerSelectField = null

  render() {
    const { device, onCompleted } = this.props;
    const { lang } = this.context;
    const { values, type, errors, search, page } = this.state;

    let mutation;
    if (type === 'LybraProfile') {
      mutation = device ? updateLybra : createLybra;
    } else {
      mutation = device ? updateCalybra : createCalybra;
    }

    const types = [{ label: 'Lybra', value: 'LybraProfile' }, { label: 'Calybra', value: 'CalybraProfile' }];

    return (
      <Mutation mutation={mutation} onCompleted={onCompleted}>
        {(action, { loading, error }) => {
          return (
            <TabPanel tabsPosition="bottom" tabs={[lang('global', 'details-tab-title').s, lang('global', 'users-tab-title').s]}>
              <form className="grid-x page">
                <Breadcrumbs className="small-12 cell" steps={[{ title: lang('devices', 'devices').s, url: '/devices' }]} relative />
                <div className="spacer" />
                <div className="title cell small-12 medium-6">{ device ? `${device.model} - ${device.serial}` : lang('device', 'new').s }</div>
                <div className="title-action cell small-12 medium-6">
                  {loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onClick.bind(null, action)} value={device ? lang('global', 'update').s : lang('device', 'create').s} />}
                </div>
                <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="device" />
                <label htmlFor="type" className="cell small-12 medium-6">
                  <div>{ lang('device', 'type').s }</div>
                  {
                    device
                    ? <input type="text" name="type" value={type === 'LybraProfile' ? 'Lybra' : 'Calybra'} readOnly />
                    : <Select classNamePrefix="Select" options={types} value={types.filter(e => e.value === type)} onChange={this.onTypeChange} clearable={false} searchable={false} />
                  }
                </label>
                <label htmlFor="customer" className="cell small-12 medium-6">
                  <div>{ lang('device', 'customer').s }</div>
                  <Query query={queries.customers.list} variables={{ search, page: 1, order: 'name_ASC' }} fetchPolicy="network-only">
                    {({ loading, error, data, refetch }) => { // eslint-disable-line
                      if (error) return <QueryError lang={lang} error={error.toString()} />;
                      if (!data || !data.customers) return null;

                      const customersOptions = data.customers.results.map((c) => { return { label: c.name, value: c.id }; });
                      return <Select classNamePrefix="Select" ref={(e) => { this.customerSelectField = e; }} options={customersOptions} value={{ label: values.customer.name, value: values.customer.id }} onInputChange={this.onCustomerSearch.bind(null, refetch)} inputValue={search} onChange={this.onCustomerSelect} searchable clearable={false} />;
                    }}
                  </Query>
                </label>
                <label htmlFor="model" className="cell small-12 medium-6">
                  <div>{ lang('device', 'model').s }</div>
                  <input className={errors.model ? 'error' : ''} type="text" name="model" value={values.model || ''} onChange={this.onTextChange.bind(null, 'model')} />
                </label>
                <label htmlFor="serial" className="cell small-12 medium-6">
                  <div>{ lang('device', 'serial').s }</div>
                  <input className={errors.serial ? 'error' : ''} type="text" name="serial" value={values.serial || ''} onChange={this.onTextChange.bind(null, 'serial')} />
                </label>
                <label htmlFor="notes" className="cell small-12 medium-12">
                  <div>{ lang('device', 'notes').s }</div>
                  <textarea name="notes" value={values.notes || ''} onChange={this.onTextChange.bind(null, 'notes')} />
                </label>
                { type === 'LybraProfile' ? this.getLybraFields(values) : null }
                { type === 'CalybraProfile' ? this.getCalybraFields(values) : null }
              </form>
              { values.customer.id ?
                <aside className="grid-x">
                  { this.state.selectedUsers.length > 0 ? <div className="subtitle">{ lang('device', 'associated-users').s }</div> : null }
                  <div className="grid-x cell">
                    {
                      this.state.selectedUsers.map(u => <SelectCard className="cell small-12 medium-6" key={u.id} title={`${u.firstName} ${u.lastName}`} subtitle={u.customer.name} extra={u.role} isSelected onSelect={this.onUserDeselect.bind(null, u.id)} />)
                    }
                  </div>
                  <div className="subtitle">{ lang('device', 'available-users').s }</div>
                  <Query query={queries.users.listWithDistributors} variables={{ customerId: values.customer.id, page, perPage: 20 }} fetchPolicy="network-only">
                    {(userFetch) => {
                      if (userFetch.loading) return <Spinner absolute />;
                      if (userFetch.error) return `Error!: ${userFetch.error.toString()}`;
                      return userFetch.data ? (
                        <div className="grid-x cell">
                          {
                            userFetch.data.users.results.filter(u => this.state.selectedUsers.map(user => user.id).indexOf(u.id) < 0).map(u => <SelectCard className="cell small-12 medium-6" key={u.id} title={`${u.firstName} ${u.lastName}`} subtitle={u.customer.name} extra={u.role} isSelected={false} onSelect={this.onUserSelect.bind(null, u)} />)
                          }
                          <Pager pages={userFetch.data.users.totalPages} current={page} onPage={this.onPage} />
                        </div>
                      ) : null;
                    }}
                  </Query>
                </aside>
                : <aside className="grid-x" /> }
            </TabPanel>
          );
        }}
      </Mutation>
    );
  }
}

export default DetailForm;
