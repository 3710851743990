import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';

import { Permissions } from '../state/authorization';

import Spinner from '../components/common/spinner/Spinner';
import Users from '../components/users/Users';
import Customers from '../components/customers/Customers';
import Detail from '../components/users/Detail';

import initialState from '../state/initial-state';
import queries from '../state/queries';
import QueryError from '../components/common/errors/QueryError';

type Props = {
  session: typeof initialState.session,
  match: any
};
type State = {
};

class UsersPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func,
    can: PropTypes.func.isRequired,
    lang: PropTypes.func
  }

  state = {
  }

  onEditComplete = () => {
    const { dispatch, push } = this.context;
    dispatch(push('/users'));
  }

  render() {
    const { can, lang } = this.context;
    const { session, match } = this.props;
    const userId = match.params.id;
    const showList = match.path === '/users';

    return (
      <Query query={queries.users.metadata} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          return showList
            ? (
              <div>
                { can(Permissions.customers.customersIndex) ? <Customers customerId={session.currentCustomer ? session.currentCustomer.id : null} /> : null }
                <Users customerId={session.currentCustomer ? session.currentCustomer.id : null} />
              </div>
            )
            : <Detail userId={userId} roles={data.roles} onCompleted={this.onEditComplete} session={session} customerId={session.currentCustomer ? session.currentCustomer.id : null} />;
        }}
      </Query>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(UsersPage);
