import { RSAA } from 'redux-api-middleware';

const authMiddleware = (store: any) => (next: any) => (action: any) => {
  if (action[RSAA] && action[RSAA].headers.Authorization !== undefined) {
    action[RSAA].headers.Authorization = `Bearer ${store.getState().session.access_token}`; // eslint-disable-line no-param-reassign
  }
  return next(action);
};

export default authMiddleware;
