import React from 'react';
import PropTypes from 'prop-types';

const QueryError = ({ error, lang }: any) => {
  return (
    <ul className="cell small-12 query-error">
      <h1>{lang('global', 'something-wrong').s}</h1>
      <div>{lang('global', 'something-wrong-detail').s}</div>
      <button className="action-button" type="button" onClick={() => window.location.reload()}>{lang('global', 'retry').s}</button>
      <figcaption>{lang('global', 'detail').s}</figcaption>
      <textarea readOnly value={error} />
    </ul>
  );
};

QueryError.propTypes = {
  error: PropTypes.string,
  lang: PropTypes.func.isRequired
};

QueryError.defaultProps = {
  error: ''
};

export default QueryError;
