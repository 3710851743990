import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import styles from './Estimate.scss';
import mobileTableStyle from '../devices/Aside.scss';

const Estimate = ({ values, lang, id, print }: any) => {
  let view = null;

  const piecesForItem = (p: any) => {
    return p.targetPieces || (parseInt((p.tanks * p.tankWeight) / ((p.recipe.profile.weight || p.recipe.profile.target) / 1000), 10) || 0);
  };

  const timeForitem = (p: any) => {
    return parseInt(((piecesForItem(p) / p.recipe.profile.yield) * 60).toFixed(0), 10);
  };

  const totalPieces = values.planningItems.reduce((total, p) => parseInt(total, 10) + piecesForItem(p), 0);
  const totalTime = values.planningItems.reduce((total, p) => parseInt(total, 10) + timeForitem(p), 0);

  if (values.planningItems.length > 0) {
    view = (
      <div className="cell small-12">
        <div className="spacer" />
        { (print || !id) ? null : <Link target="_blank" to={`/print/estimate/${id}`} className="print-button show-for-large">{lang('global', 'print').s}</Link> }
        <div className="small-12 cell">
          <div className={`subtitle ${print ? 'subtitle--big' : ''}`}>{`${lang('planning', 'planning-ref').s}: #${values.number}`}</div>
        </div>

        <div className={styles.info}>
          <span className="subtitle">{values.description} - {moment(values.date).format('DD/MM/YYYY')}</span>
        </div>

        <div className={`small-12 cell ${mobileTableStyle['table-container']}`}>
          <div style={{ minWidth: '40rem' }} className={`small-12 cell subtitle ${styles.header} ${mobileTableStyle['table-row-small']}`}>
            <span style={{ flex: '2' }} className={mobileTableStyle['table-column--xsmall']}>{ lang('recipe', 'recipe').s }</span>
            <span style={{ flex: '1' }} className={mobileTableStyle['table-column--xsmall']}>{ lang('recipe', 'weight').s }</span>
            <span style={{ flex: '1' }} className={mobileTableStyle['table-column--xsmall']}>{ lang('planning', 'tanks').s }</span>
            <span style={{ flex: '1' }} className={mobileTableStyle['table-column--xsmall']}>{ lang('planning', 'tankWeight').s }</span>
            <span style={{ flex: '1' }} className={mobileTableStyle['table-column--xsmall']}>{ lang('planning', 'pieces').s }</span>
          </div>

          <div className={styles.info}>
            {
              values.planningItems.filter(p => !p._destroy).map((p, i) => {
                return (
                  <span style={{ minWidth: '40rem' }} className={`subtitle ${styles.item} ${mobileTableStyle['table-row-small']}`} key={`recipe-${i}`}>
                    <span style={{ flex: '2' }} className={mobileTableStyle['table-column--xsmall']}>{`${p.recipe.number} - ${p.recipe.description}`}</span>
                    <span style={{ flex: '1', textTransform: 'none' }} className={mobileTableStyle['table-column--xsmall']}>{`${p.recipe.profile.weight || p.recipe.profile.target}gr`}</span>
                    <span style={{ flex: '1' }} className={mobileTableStyle['table-column--xsmall']}>{p.tanks}</span>
                    <span style={{ flex: '1' }} className={mobileTableStyle['table-column--xsmall']}>{p.tankWeight}</span>
                    <span style={{ flex: '1' }} className={mobileTableStyle['table-column--xsmall']}>{piecesForItem(p)}</span>
                  </span>
                );
              })
            }
          </div>
        </div>

        <div className="spacer" />

        <div className="small-12 cell">
          <div className="subtitle">{ lang('planning', 'estimate').s }</div>
        </div>

        <div className={styles.info}>
          {
            values.planningItems.filter(p => !p._destroy).map((p, i) => {
              return p.recipe.profile.yield
                ? <span className="subtitle" key={i}>{ `${p.recipe.number} - ${p.recipe.description}: ${piecesForItem(p)} pieces in ${timeForitem(p)} min` }</span>
                : <span className="subtitle" key={i}>{ `${p.recipe.number} - ${p.recipe.description}: ${piecesForItem(p)} pieces` }</span>;
            })
          }
        </div>
        <div className="spacer" />
        <span className="subtitle">
          {
            totalTime
              ? `${lang('planning', 'total').s}: ${totalPieces.toLocaleString()} pieces in ${totalTime.toLocaleString()} min`
              : `${lang('planning', 'total').s}: ${totalPieces.toLocaleString()} pieces`
          }
        </span>
      </div>
    );
  }

  return view;
};

Estimate.propTypes = {
  values: PropTypes.object.isRequired,
  print: PropTypes.bool,
  lang: PropTypes.func.isRequired,
  id: PropTypes.string
};

Estimate.defaultProps = {
  print: false,
  id: null
};

export default Estimate;
