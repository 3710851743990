import gql from 'graphql-tag';

export const customerFields = `
  id
  kind
  name
  address
  postalCode
  city
  town
  country
  zone
  language
  contactName
  phone
  email
  web
  recipients
`;

export const userFields = `
  id
  email
  firstName
  lastName
  role
  code
  customer {
    id
    name
    kind
  }
`;

export const lybraFields = `
  ... on Lybra {
    id
    mode
  }
`;

export const calybraFields = `
  ... on Calybra {
    id
    weightIncrement
    exit
    rounder
    dusters
    singleWeightMin
    singleWeightMax
    singleYieldMin
    singleYieldMax
    doubleWeightMin
    doubleWeightMax
    doubleYieldMin
    doubleYieldMax
    lybra {
      id
      serial
      model
    }
    version {
      id
      version
      division
    }
  }
`;

export const commonDeviceFields = `
  id
  profileType
  model
  serial
  notes
  users {
    id
    firstName
    lastName
    role
  }
  customer {
    id
    name
    kind
  }
`;

export const calybraRecipeFields = `
  ... on CalybraRecipe {
    id
    correction
    division
    duster1
    duster1Speed
    duster2
    duster2Speed
    exitSpeed
    lybraBackStep1
    lybraForwardStep1
    lybraForwardStep2
    pressingTime
    mass
    rounderSpeed
    separatorSpeed
    weight
    yield
  }
`;

export const lybraRecipeFields = `
  ... on LybraRecipe {
    id
    target
    yield
    backStep
    forwardStep
  }
`;

export const recipeFields = `
  id
  number
  description
  profile {
    ${calybraRecipeFields}
    ${lybraRecipeFields}
  }
`;

export default {

  // Customers

  customers: {
    list: gql`
      query customers($page: Int!, $search: String, $order: CustomersOrder!, $customerId: ID, $perPage: Int) {
        customers(page: $page, search: $search, order: $order, customerId: $customerId, perPage: $perPage) {
          results {
            id
            kind
            name
          }
          totalPages
        }
      }
    `,
    detail: gql`
      query customer($id: ID!) {
        customer(id: $id) {
          ${customerFields}
          users {
            id
            firstName
            lastName
            role
          }
        }
      }
    `
  },

  // Devices

  devices: {
    basic: gql`
      query device($id: ID!) {
        device(id: $id) {
          id
          serial
          model
          notes
          remoteId
          customer {
            id
            name
            kind
          }
        }
      }
    `,
    detail: gql`
      query device($id: ID!) {
        calybraPressingData {
          key
          value
          yieldSingle
          yieldDouble
          default
        }
        device(id: $id) {
          id
          remoteId
          serial
          model
          notes
          profileType
          customer {
            id
            name
            kind
          }
          profile {
            ${lybraFields}
            ${calybraFields}
          }
        }
      }
    `,
    fullDetail: gql`
      query device($id: ID!) {
        device(id: $id) {
          id
          remoteId
          serial
          model
          notes
          profileType
          customer {
            id
            name
            kind
          }
          profile {
            ${lybraFields}
            ${calybraFields}
          }
          users {
            id
            firstName
            lastName
            role
            customer {
              id
              name
              kind
            }
          }
        }
        firmwares: firmwares(deviceId: $id, order: createdAt_DESC) {
          id,
          version
          notes
          target
          createdAt
          file
        }
        documents: documents(deviceId: $id, order: createdAt_DESC) {
          id,
          description
          createdAt
          file
          fileName
        }
      }
    `,
    listSearch: gql`
      query devices($page: Int!, $order: DevicesOrder!, $search: String, $customerId: ID) {
        devices(page: $page, order: $order, search: $search, customerId: $customerId) {
          results {
            id
            remoteId
            serial
            model
            profileType
            customer {
              id
              name
              kind
            }
          }
          totalPages
        }
      }
    `,
    maintnanceRequestMetadata: gql`
      query device($id: ID!) {
        device(id: $id) {
          id
          serial
          model
          remoteId
        }
        user: me {
          id
          email
          firstName
          lastName
          customer {
            id
            contactName
            phone
          }
        }
      }
    `,
    maintenanceOperations: gql`
      query maintenanceOperations($id: ID!) {
        device(id: $id) {
          id
          serial
          model
        }
        maintenanceOperations(deviceId: $id) {
          id
          performedAt
          user {
            id
            firstName
            lastName
          }
          description
          partCost
          partNumber
          hours
          hourlyCost
        }
      }
    `,
    productionHistories: gql`
      query productionHistories($id: ID!, $page: Int!) {
        productionHistories(deviceId: $id, page: $page) {
          results {
            id
            status
            createdAt
            planning {
              id
              number
              description
              planningItemsCount
            }
          }
          totalPages
        }
      }
    `,
    sanitations: gql`
      query sanitations($id: ID!) {
        device(id: $id) {
          id
          serial
          model
        }
        sanitations(deviceId: $id) {
          id
          userCode
          startTime
          endTime
          user {
            id
            firstName
            lastName
          }
        }
      }
    `,
    alarms: gql`
      query alarms($page: Int, $id: ID!) {
        device(id: $id) {
          id
          serial
          model
        }
        alarms(page: $page, deviceId: $id) {
          totalPages
          results {
            id
            description
            raisedAt
          }
        }
      }
    `,
    lybraWeightReports: gql`
      query lybraWeightReports($id: ID!, $page: Int) {
        device(id: $id) {
          id
          serial
          model
        }
        lybraWeightReports(deviceId: $id, page: $page) {
          totalPages
          results {
            id
            date
            weighsCount
            lybraKind
          }
        }
      }
    `,
    lybraWeightReport: gql`
    query lybraWeightReport($id: ID!) {
      lybraWeightReport(id: $id) {
        id
        date
        payload
        weighsCount
        lybraKind
      }
    }
  `,
    configs: gql`
      query deviceConfigs($id: ID!) {
        deviceConfigs(id: $id)
        device(id: $id) {
          id
          model
          serial
          profile {
            ... on Lybra { id }
            ... on Calybra { id }
          }
        }
      }
    `,
    calybraMetadata: gql`
      {
        calybraExits
        calybraVersions {
          id
          version
          division
        }
        yields: calybraYields {
          version {
            id
            version
            division
            weightIncrements
          }
          division
          weightStandardMin
          weightStandardMax
          weightLargeMin
          weightLargeMax
          yieldMin
          yieldMax
        }
        devices(type: LybraProfile, availableOnly: true, page: 1) {
          results {
            id
            serial
            model
            profile {
              ... on Lybra {
                id
                mode
              }
            }
          }
        }
      }
    `
  },

  // Users

  users: {
    list: gql`
      query users($page: Int!, $search: String, $order: UsersOrder!, $customerId: ID, $perPage: Int) {
        users(page: $page, search: $search, order: $order, customerId: $customerId, perPage: $perPage, includeDistributors: false) {
          results {
            ${userFields}
          }
          totalPages
        }
      }
    `,
    listWithDistributors: gql`
      query($customerId: ID!, $page: Int!, $perPage: Int) {
        users(customerId: $customerId, page: $page, perPage: $perPage, includeDistributors: true) {
          results {
            ${userFields}
          }
          totalPages
        }
      }
    `,
    detail: gql`
      query user($id: ID!) {
        user(id: $id) {
          ${userFields}
        }
      }
    `,
    metadata: gql`
      {
        roles
      }
    `
  },

  // Planning

  plannings: {
    list: gql`
      query plannings($deviceId: ID!, $page: Int!, $order: PlanningsOrder!) {
        plannings(deviceId: $deviceId, page: $page, order: $order) {
          id
          number
          date
          description
          planningItemsCount
        }
      }
    `,
    detail: gql`
      query planning($id: ID!) {
        planning(id: $id) {
          id
          number
          date
          description
          device {
            id
            serial
            model
            customer {
              id
              name
              kind
            }
          }
          planningItems {
            id
            recipe {
              ${recipeFields}
            }
            tanks
            tankWeight
            targetPieces
          }
        }
      }
    `
  },

  // Recipes

  recipes: {
    list: gql`
      query recipes($search: String, $deviceId: ID) {
        recipes(search: $search, deviceId: $deviceId) {
          ${recipeFields}
        }
      }
    `,
    listSearch: gql`
      query recipes($deviceId: ID!, $page: Int!, $order: RecipesOrder!) {
        recipes(deviceId: $deviceId, page: $page, order: $order) {
          ${recipeFields}
          device {
            id, model, serial
          }
        }
      }
    `,
    detail: gql`
      query recipe($id: ID!) {
        recipe(id: $id) {
          ${recipeFields}
          device {
            ${commonDeviceFields}
            profile {
              ${calybraFields}
              ${lybraFields}
            }
          }
        }
      }
    `
  },

  // Production

  production: {
    detail: gql`
      query productionHistory($id: ID!) {
        productionHistory(id: $id) {
          id
          status
          createdAt
          user {
            id
            code
            firstName
            lastName
          }
          customer {
            id
            name
            users {
              id
              code
              firstName
              lastName
            }
          }
          userCode
          device {
            id
            model
            serial
            remoteId
            profile {
              ... on Calybra {
                id
                lybra {
                  id
                  serial
                  model
                }
              }
              ... on Lybra {
                id
                mode
              }
            }
          }
          passive
          planning {
            id
            number
            description
            planningItems {
              id
              order
              tanks
              tankWeight
              targetPieces
              recipe {
                ${recipeFields}
              }
            }
          }
        }
      }
    `
  }
};
