import React from 'react';
import PropTypes from 'prop-types';
import styles from './Checkbox.scss';

const Checkbox = ({ checked, title, className, onChange }: any) => {
  return (
    <div className={`${className} ${styles.container}`} onClick={onChange.bind(null, !checked)}>
      <span>{title}</span>
      <i className={`${checked ? 'icon-check-square' : 'icon-square'} ${styles.check}`} />
    </div>
  );
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

Checkbox.defaultProps = {
  checked: false,
  title: '',
  className: ''
};

export default Checkbox;
