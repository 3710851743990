import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Devices from '../components/devices/Devices';
import Detail from '../components/devices/Detail';
import Config from '../components/devices/Config';
import Maintenance from '../components/devices/Maintenance';
import Production from '../components/devices/Production';
import Alarms from '../components/devices/Alarms';
import Sanitations from '../components/devices/Sanitations';
import MaintenanceOperations from '../components/devices/MaintenanceOperations';
import LybraWeights from '../components/devices/LybraWeights';

import initialState from '../state/initial-state';
import { notificationSuccess } from '../state/utils';

type Props = {
  session: typeof initialState.session,
  match: any
};
type State = {};

class DevicesPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func,
    lang: PropTypes.func
  }

  onEditComplete = () => {
    const { dispatch, push } = this.context;
    dispatch(push('/devices'));
  }

  onMaintenanceComplete = () => {
    const { match } = this.props;
    const { dispatch, push, lang } = this.context;
    const deviceId = match.params.id;

    dispatch(notificationSuccess(lang('device', 'maintenance-requst-success').s));

    dispatch(push(`/devices/${deviceId}`));
  }

  onFirmwareToMachineComplete = () => {
    const { dispatch, lang } = this.context;

    dispatch(notificationSuccess(lang('firmware', 'send-request-success').s));
  }

  onRecipesSendCompleted = () => {
    const { dispatch, lang } = this.context;

    dispatch(notificationSuccess(lang('recipe', 'send-request-success').s));
  }

  render() {
    const { session, match } = this.props;

    const deviceId = match.params.id;
    const showList = match.path === '/devices';
    const showConfigs = match.path === '/devices/:id/configs';
    const showMaintenanceRequest = match.path === '/devices/:id/maintenance-requests/new';
    const showProduction = match.path === '/devices/:id/production';
    const showAlarms = match.path === '/devices/:id/alarms';
    const showSanitations = match.path === '/devices/:id/sanitations';
    const showMaintenance = match.path === '/devices/:id/maintenance';
    const showLybraWeights = match.path === '/devices/:id/weight-reports';

    if (showList) {
      return <Devices customerId={session.currentCustomer ? session.currentCustomer.id : null} />;
    }
    if (showConfigs) {
      return <Config deviceId={deviceId} />;
    }
    if (showMaintenanceRequest) {
      return <Maintenance deviceId={deviceId} onCompleted={this.onMaintenanceComplete} />;
    }
    if (showProduction) {
      return <Production deviceId={deviceId} customerId={session.customer.id || ''} onSendCompleted={this.onRecipesSendCompleted} />;
    }
    if (showAlarms) {
      return <Alarms deviceId={deviceId} />;
    }
    if (showSanitations) {
      return <Sanitations deviceId={deviceId} />;
    }
    if (showMaintenance) {
      return <MaintenanceOperations deviceId={deviceId} />;
    }
    if (showLybraWeights) {
      return <LybraWeights deviceId={deviceId} />;
    }
    return <Detail deviceId={deviceId} onCompleted={this.onEditComplete} onFirmwareSent={this.onFirmwareToMachineComplete} />;
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(DevicesPage);
