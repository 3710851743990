import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { connect } from 'react-redux';
import querystring from 'query-string';

import Alarms from '../components/devices/Alarms';
import MaintenanceOperations from '../components/devices/MaintenanceOperations';
import Sanitations from '../components/devices/Sanitations';
import DevicePrintHeader from '../components/devices/PrintHeader';
import Spinner from '../components/common/spinner/Spinner';
import Estimate from '../components/plannings/Estimate';
import ProductionHistory from '../components/production/ProductionHistory';
import LybraReport from '../components/production/LybraReport';

import recipeStyles from '../components/recipes/DetailForm.scss';
import styles from './PrintPage.scss';
import queries from '../state/queries';

type Props = {
  match: any,
  session: any
};

type State = {};

class PrintPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func,
    lang: PropTypes.func,
    routing: PropTypes.object
  }

  getHeader = (title: string) => {
    return (
      <div className={styles.header}>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.logo}>
          <img src={require('../images/logo-print.png')} alt="" />
        </div>
      </div>
    );
  }

  render() {
    const { lang, routing } = this.context;
    const { match } = this.props;
    const resourceType = match.params.type;
    const resourceId = match.params.id;
    const option = match.params.optional;

    const page = parseInt(querystring.parse(routing.location.search).page, 10) || 1;

    if (resourceType === 'alarms' || resourceType === 'sanitation' || resourceType === 'maintenance') {
      return (
        <div className="page full-page">
          <div className="no-print outline-button" onClick={window.print}>{lang('global', 'print').s}</div>
          {this.getHeader(lang('print', `header-${resourceType}`).s)}
          <div className="spacer" />
          <DevicePrintHeader deviceId={resourceId} />
          <div className="line-divider" />
          <div className="spacer" />
          { resourceType === 'alarms'
            ? (
              <Query query={queries.devices.alarms} variables={{ id: resourceId, page }} fetchPolicy="network-only">
                {({ data, loading }) => {
                  if (loading) return <Spinner absolute />;
                  if (!data) return null;
                  return <Alarms deviceId={resourceId} alarms={data.alarms.results} print />;
                }}
              </Query>
            ) : null }
          { resourceType === 'sanitation'
            ? (
              <Query query={queries.devices.sanitations} variables={{ id: resourceId }} fetchPolicy="network-only">
                {({ loading, data }) => {
                  if (loading) return <Spinner absolute />;
                  if (!data) return null;
                  return <Sanitations deviceId={resourceId} sanitations={data.sanitations} print />;
                }}
              </Query>
            ) : null }
          { resourceType === 'maintenance'
            ? (
              <Query query={queries.devices.maintenanceOperations} variables={{ id: resourceId }} fetchPolicy="network-only">
                {({ loading, data }) => {
                  if (loading) return <Spinner absolute />;
                  if (!data) return null;
                  return <MaintenanceOperations deviceId={resourceId} maintenanceOperations={data.maintenanceOperations} print />;
                }}
              </Query>
            ) : null }
        </div>
      );
    } else if (resourceType === 'production-history') {
      return (
        <Query query={queries.production.detail} variables={{ id: resourceId }} fetchPolicy="network-only">
          {({ loading, data }) => {
            if (loading) return <Spinner absolute />;
            if (!data) return null;

            return (
              <div className="page full-page">
                <div className="no-print outline-button" onClick={window.print}>{lang('global', 'print').s}</div>
                {this.getHeader(lang('print', `header-${resourceType}`).s)}
                <div className="spacer" />
                <div><b>{lang('global', 'company').toString()}: </b>{data.productionHistory.customer.name}</div>
                <div><b>{lang('roles', 'production_manager').toString()}: </b>{`${this.props.session.first_name} ${this.props.session.last_name}`}</div>
                <DevicePrintHeader deviceId={data.productionHistory.device.id} />
                <div className="line-divider" />
                <div className="spacer" />
                <ProductionHistory history={data.productionHistory} print />
              </div>
            );
          }}
        </Query>
      );
    } else if (resourceType === 'recipes') {
      return (
        <Query query={queries.recipes.detail} variables={{ id: resourceId }} fetchPolicy="network-only">
          {({ loading, data }) => {
            if (loading) return <Spinner absolute />;
            if (!data) return null;

            const weight = (data.recipe.profile.weight || data.recipe.profile.target);
            const b1 = (data.recipe.profile.weight + data.recipe.profile.lybraBackStep1) - 1;
            const b2 = data.recipe.profile.weight + data.recipe.profile.lybraBackStep1;
            const f1 = data.recipe.profile.weight + data.recipe.profile.lybraForwardStep1;
            const f2 = data.recipe.profile.weight + data.recipe.profile.lybraForwardStep1 + data.recipe.profile.lybraForwardStep2;
            const f3 = data.recipe.profile.weight + data.recipe.profile.lybraForwardStep1 + data.recipe.profile.lybraForwardStep2 + 1;

            return (
              <div className="page full-page">
                <div className="no-print outline-button" onClick={window.print}>{lang('global', 'print').s}</div>
                {this.getHeader(lang('print', `header-${resourceType}`).s)}
                <div className="spacer" />
                <DevicePrintHeader deviceId={data.recipe.device.id} />
                <div className="line-divider" />
                <div className="spacer" />
                <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'number').s}: `}<span>{`#${data.recipe.number}`}</span></div>
                <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'description').s}: `}<span>{`${data.recipe.description}`}</span></div>
                <div className="line-divider" />
                {
                  data.recipe.device.profileType === 'CalybraProfile'
                  ? (
                    <div>
                      <div className="spacer" />
                      <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'division').s}: `}<span>{`${data.recipe.profile.division}`}</span></div>
                      <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'target').s}: `}<span>{`${data.recipe.profile.weight} gr`}</span></div>
                      <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'mass').s}: `}<span>{`${data.recipe.profile.mass} (K)`}</span></div>
                      <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'correction').s}: `}<span>{`${data.recipe.profile.correction}`}</span></div>
                      <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'yield').s}: `}<span>{`${data.recipe.profile.yield} pz/H`}</span></div>
                      <div className="line-divider" />
                      <div className="spacer" />
                      { data.recipe.device.profile.exit === 'frontal_single' ? null : <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'separatorSpeed').s}: `}<span>{`${data.recipe.profile.separatorSpeed}%`}</span></div> }
                      { (data.recipe.device.profile.exit !== 'side' || data.recipe.device.profile.rounder === false) ? null : <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'rounderSpeed').s}: `}<span>{`${data.recipe.profile.rounderSpeed}%`}</span></div> }
                      { data.recipe.device.profile.exit !== 'side' ? null : <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'exitSpeed').s}: `}<span>{`${data.recipe.profile.exitSpeed}%`}</span></div>}
                      <div className="line-divider" />
                      <div className="spacer" />
                      <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'duster1').s}: `}<span>{`${data.recipe.profile.duster1 ? '✓' : '×'}`}</span></div>
                      <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'duster2').s}: `}<span>{`${data.recipe.profile.duster2 ? '✓' : '×'}`}</span></div>
                      { data.recipe.profile.duster1 ? <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'duster1Speed').s}: `}<span>{`${data.recipe.profile.duster1Speed}`}</span></div> : null }
                      { data.recipe.profile.duster2 ? <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'duster2Speed').s}: `}<span>{`${data.recipe.profile.duster2Speed}`}</span></div> : null }
                      <div className="line-divider" />
                      <div className="spacer" />
                      <div className={`cell grid-x ${recipeStyles.lybra}`}>
                        <div className={recipeStyles['lybra-header']}><span className={recipeStyles.blue} /><span className={recipeStyles.blue} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.blue} /><span className={recipeStyles.blue} /></div>
                        <div className={recipeStyles['lybra-header']}><span className={recipeStyles.red} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.red} /></div>
                        <div className={recipeStyles['lybra-inputs']}>
                          <div className={recipeStyles['lybra-cell']}>
                            <span className={recipeStyles['lybra-empty']} />
                            <span>{b1 ? `<${b1}gr` : ''}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']}>
                            <span className={recipeStyles['lybra-empty']}>{data.recipe.profile.lybraBackStep1}</span>
                            <span>{b2 ? `${b2}gr` : ''}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']}>
                            <span className={recipeStyles['lybra-center']}>{data.recipe.profile.weight}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']}>
                            <span className={recipeStyles['lybra-empty']}>{data.recipe.profile.lybraForwardStep1}</span>
                            <span>{f1 ? `${f1}gr` : ''}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']}>
                            <span className={recipeStyles['lybra-empty']}>{data.recipe.profile.lybraForwardStep2}</span>
                            <span>{f2 ? `${f2}gr` : ''}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']}>
                            <span className={recipeStyles['lybra-empty']} />
                            <span>{f3 ? `>${f3}gr` : ''}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div>
                      <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'target').s}: `}<span>{`${weight}gr`}</span></div>
                      { data.recipe.profile.yield ? <div className="small-12 cell subtitle--dark">{`${lang('recipe', 'yield').s}: `}<span>{`${data.recipe.profile.yield}pz/h`}</span></div> : null }
                      <div className={`cell grid-x ${recipeStyles.lybra}`}>
                        <div className={recipeStyles['lybra-header']}><span className={recipeStyles.red} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.red} /></div>
                        <div className={recipeStyles['lybra-header']}><span className={recipeStyles.red} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.red} /></div>
                        <div className={recipeStyles['lybra-inputs']}>
                          <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                            <span>&le;{weight ? `${(weight + (data.recipe.profile.backStep || 0)) - 1}gr` : ''}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                            <span>{weight ? `${weight + (data.recipe.profile.backStep || 0)}gr` : ''}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                            <span>{weight ? `${weight}gr` : ''}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                            <span>{weight ? `${weight + (data.recipe.profile.forwardStep || 0)}gr` : ''}</span>
                          </div>
                          <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                            <span>&ge;{weight ? `${(weight + (data.recipe.profile.forwardStep || 0)) + 1}gr` : ''}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                }
              </div>
            );
          }}
        </Query>
      );
    } else if (resourceType === 'estimate') {
      return (
        <div className="page full-page">
          <div className="no-print outline-button" onClick={window.print}>{lang('global', 'print').s}</div>
          {this.getHeader(lang('print', `header-${resourceType}`).s)}
          <div className="spacer" />
          { resourceType === 'estimate'
            ? (
              <Query query={queries.plannings.detail} variables={{ id: resourceId }} fetchPolicy="network-only">
                {({ loading, data }) => {
                  if (loading) return <Spinner absolute />;
                  if (!data) return null;

                  return (
                    <div>
                      <div><b>{lang('global', 'company').toString()}: </b>{data.planning.device.customer.name}</div>
                      <div><b>{lang('roles', 'production_manager').toString()}: </b>{`${this.props.session.first_name} ${this.props.session.last_name}`}</div>
                      <DevicePrintHeader deviceId={data.planning.device.id} />
                      <div className="line-divider" />
                      <div className="spacer" />
                      <Estimate values={data.planning} lang={lang} print />
                    </div>
                  );
                }}
              </Query>
            ) : null }
        </div>
      );
    } else if (resourceType === 'planning-item') {
      return (
        <Query query={queries.production.detail} variables={{ id: resourceId }} fetchPolicy="network-only">
          {({ loading, data }) => {
            if (loading) return <Spinner absolute />;
            if (!data) return null;

            return (
              <div className="page full-page">
                <div className="no-print outline-button" onClick={window.print}>{lang('global', 'print').s}</div>
                {this.getHeader(lang('print', `header-${resourceType}`).s)}
                <div className="spacer" />
                <div><b>{lang('global', 'company').toString()}: </b>{data.productionHistory.customer.name}</div>
                <div><b>{lang('roles', 'production_manager').toString()}: </b>{`${this.props.session.first_name} ${this.props.session.last_name}`}</div>
                <DevicePrintHeader deviceId={data.productionHistory.device.id} />
                <LybraReport history={data.productionHistory} planningItemId={option} print />
              </div>
            );
          }}
        </Query>
      );
    }

    return <div />;
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(PrintPage);
