import React from 'react';
import PropTypes from 'prop-types';

const MutationError = ({ graphQLError, errors, langKey, lang }: any) => {
  if (Object.keys(errors).filter(x => errors[x]).length === 0 && !graphQLError) {
    return null;
  }

  const graphQLErrors = graphQLError ? graphQLError.graphQLErrors : [];
  const validations = Object.keys(errors).filter(x => errors[x]).map((v) => {
    return `${lang('global', 'field').s} ${lang(langKey, v).s} ${lang('errors', errors[v]).s}`;
  });

  const strings = [...validations, ...graphQLErrors.map(e => e.message)];

  return (
    <ul className="cell small-12 error-message">
      {
        strings.map((e, i) => <li key={`error-${i}`}>{e}</li>)
      }
    </ul>
  );
};

MutationError.propTypes = {
  graphQLError: PropTypes.object,
  errors: PropTypes.object,
  langKey: PropTypes.string,
  lang: PropTypes.func.isRequired
};

MutationError.defaultProps = {
  graphQLError: null,
  errors: {},
  langKey: 'global'
};

export default MutationError;
