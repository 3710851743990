import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import initialState from '../state/initial-state';
import { getSession } from '../state/session/selectors';
import Login from '../components/login/Login';

type Props = {
  session: typeof initialState.session
};
type State = {};

class LoginPage extends Component<Props, State> {
  static contextTypes = {
    dispatch: PropTypes.func,
    push: PropTypes.func,
    actions: PropTypes.object
  }

  componentWillUpdate(nextProps: any) {
    const { dispatch, push } = this.context;
    if (nextProps.session.authenticated) {
      dispatch(push('/'));
    }
  }

  render() {
    const { session } = this.props;
    const { authenticating, error } = session;
    const { login } = this.context.actions.session;
    return <Login onLogin={login} authenticating={authenticating || false} error={error} />;
  }
}

function mapStateToProps(state) {
  return {
    session: getSession(state)
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(LoginPage);
