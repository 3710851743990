import React from 'react';
import PropTypes from 'prop-types';

const NumericInput = ({ name, value, min, max, negativeOnly, style, className, onChange }: any) => {
  const changeOverride = (element: any) => {
    const _value = (negativeOnly && element.target.value > 0) ? element.target.value * -1 : element.target.value;
    onChange({ ...element, target: { ...element.target, value: _value } });
  };

  return (
    <input name={name} type="number" style={style} className={className} onChange={changeOverride} value={(value !== null && value !== undefined && !isNaN(value)) ? value : ''} max={max} min={min} />
  );
};

NumericInput.propTypes = {
  name: PropTypes.string,
  value: PropTypes.number,
  min: PropTypes.number,
  max: PropTypes.number,
  negativeOnly: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object,
  onChange: PropTypes.func.isRequired
};

NumericInput.defaultProps = {
  name: '',
  value: null,
  min: null,
  max: null,
  negativeOnly: false,
  className: '',
  style: {}
};

export default NumericInput;
