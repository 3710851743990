import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import moment from 'moment';
import { confirmAlert } from 'react-confirm-alert';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import Spinner from '../common/spinner/Spinner';
import MutationError from '../common/errors/MutationError';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';

import styles from './Aside.scss';
import queries from '../../state/queries';
import { Permissions } from '../../state/authorization';
import QueryError from '../common/errors/QueryError';

type Props = {
  print?: boolean,
  deviceId: string,
  session: any
}

type State = {
  variables: any,
  showMaintenanceOperationsForm: boolean
};

const createMaintenanceOperationMutation = gql`
  mutation createMaintenanceOperation($performedAt: DateTime!, $description: String, $partCost: Float, $partNumber: String, $hours: Float, $hourlyCost: Float, $device: DeviceRelationAttributes!) {
    createMaintenanceOperation(attributes: { performedAt: $performedAt, description: $description, partCost: $partCost, partNumber: $partNumber, hours: $hours, hourlyCost: $hourlyCost, device: $device }) {
      maintenanceOperation {
        id, performedAt, user { id, firstName, lastName }, description, partCost, partNumber, hours, hourlyCost
      }
    }
  }
`;

const updateMaintenanceOperationMutation = gql`
  mutation updateMaintenanceOperation($id: ID!, $performedAt: DateTime!, $description: String, $partCost: Float, $partNumber: String, $hours: Float, $hourlyCost: Float, $device: DeviceRelationAttributes!) {
    updateMaintenanceOperation(id: $id, attributes: { performedAt: $performedAt, description: $description, partCost: $partCost, partNumber: $partNumber, hours: $hours, hourlyCost: $hourlyCost, device: $device }) {
      maintenanceOperation {
        id, performedAt, user { id, firstName, lastName }, description, partCost, partNumber, hours, hourlyCost
      }
    }
  }
`;

const deleteMaintenanceOperationMutation = gql`
  mutation deleteMaintenanceOperation($id: ID!) {
    deleteMaintenanceOperation(id: $id) { id }
  }
`;

class MaintenanceOperations extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    can: PropTypes.func
  }

  state = {
    variables: {},
    showMaintenanceOperationsForm: false
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ variables: { ...this.state.variables, [field]: e.target.value } });
  }

  onNumberChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ variables: { ...this.state.variables, [field]: parseFloat(e.target.value) } });
  }

  onEdit = (item: any) => {
    this.setState({ variables: { ...item }, showMaintenanceOperationsForm: true });
  }

  onDateChange = (date: any) => {
    this.setState({ variables: { ...this.state.variables, performedAt: moment(date).format('YYYY-MM-DD HH:mmZ') } });
  }

  onMaintenanceOperationsCreate = (action: Function, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    action({ variables: { ...this.state.variables, device: { id: this.props.deviceId } } });
  }

  onMaintenanceOperationsDelete = (action: Function, operationId: string) => {
    confirmAlert({
      title: this.context.lang('maintenance-operations', 'delete-confirm-title').toString(),
      message: this.context.lang('maintenance-operations', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: operationId } })
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  render() {
    const { lang, can } = this.context;
    const { print, deviceId, session } = this.props;
    const { variables } = this.state;

    return (
      <Query query={queries.devices.maintenanceOperations} variables={{ id: this.props.deviceId }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.deviceId) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          return (
            <div className={`${print ? 'page-print' : 'page'} full-page`}>
              <div className="grid-x" style={{ width: '100%' }}>
                { print ? null : <Breadcrumbs relative steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${data.device.model} - ${data.device.serial}`, url: `/devices/${this.props.deviceId || ''}` }]} /> }
                { print ? null : <div className="spacer" /> }
                <div className="title cell small-12 medium-6"><span>{lang('device', 'maintenance-operations').s}</span></div>
                <div className="title-action cell small-12 medium-6">
                  { print ? null : <Link target="_blank" to={`/print/maintenance/${deviceId}`} style={{ marginLeft: '1rem' }} className="outline-button float-right show-for-large">{lang('global', 'print').s}</Link> }
                  { !print ? <div className="outline-button float-right" onClick={() => this.setState({ showMaintenanceOperationsForm: true, variables: { performedAt: moment().format('YYYY-MM-DD HH:mm:ss') } })}>{lang('maintenance-operations', 'new').s}</div>
                     : null }
                </div>
                <div className="spacer" />

                <div className="title cell small-12 medium-6">
                  <div className="subtitle">{ lang('device', 'device').s }</div>
                  <span className="subtitle" style={{ color: 'black' }}>{ `${data.device.model} - ${data.device.serial}` }</span>
                </div>

                {
                  this.state.showMaintenanceOperationsForm ?
                    <Mutation mutation={variables.id ? updateMaintenanceOperationMutation : createMaintenanceOperationMutation} onCompleted={() => this.setState({ showMaintenanceOperationsForm: false })} refetchQueries={[{ query: queries.devices.maintenanceOperations, variables: { id: this.props.deviceId } }]}>
                      {(action, mutationResult) => {
                        const localeFormat = moment.localeData().longDateFormat('L').replace('DD', 'dd');
                        return (
                          <form className={`grid-x ${styles['form-container']}`}>
                            <MutationError graphQLError={mutationResult.error} lang={lang} langKey="maintenance-operations" />

                            <div className="title cell small-12 medium-6">{ variables.id ? lang('maintenance-operations', 'edit').s : lang('maintenance-operations', 'new').s }</div>
                            <div className="title-action cell small-12 medium-6">
                              {mutationResult.loading ? <Spinner /> : <input className="outline-button float-right margin-left-small" type="submit" onClick={this.onMaintenanceOperationsCreate.bind(null, action)} value={lang('maintenance-operations', 'create').toString()} />}
                              {mutationResult.loading ? null : <input className="outline-button float-right" type="submit" onClick={(e) => { e.preventDefault(); this.setState({ variables: {}, showMaintenanceOperationsForm: false }); }} value={lang('global', 'cancel').toString()} />}
                            </div>
                            <label htmlFor="operator" className="cell small-12 medium-6">
                              <div>{ lang('global', 'operator').s }</div>
                              <input type="text" name="operator" value={`${session.first_name} ${session.last_name}`} readOnly />
                            </label>
                            <label htmlFor="date" className="cell small-12 medium-6">
                              <div>{ lang('global', 'date').s }</div>
                              <DatePicker dateFormat={`${localeFormat} HH:mm`} showTimeSelect timeFormat="HH:mm" selected={new Date(variables.performedAt)} onChange={this.onDateChange} />
                            </label>
                            <label htmlFor="description" className="cell small-12">
                              <div>{ lang('global', 'description').s }</div>
                              <textarea name="description" value={variables.description || ''} onChange={this.onTextChange.bind(null, 'description')} />
                            </label>
                            <label htmlFor="partNumber" className="cell small-12 medium-6">
                              <div>{ lang('device', 'part-number').s }</div>
                              <input type="text" name="partNumber" value={variables.partNumber || ''} onChange={this.onTextChange.bind(null, 'partNumber')} />
                            </label>
                            <label htmlFor="partCost" className="cell small-12 medium-6">
                              <div>{ lang('device', 'part-cost').s }</div>
                              <input type="number" name="partCost" value={variables.partCost || ''} step="any" onChange={this.onNumberChange.bind(null, 'partCost')} />
                            </label>
                            <label htmlFor="hours" className="cell small-12 medium-6">
                              <div>{ lang('device', 'duration').s }</div>
                              <input type="number" name="hours" value={variables.hours || ''} step="any" onChange={this.onNumberChange.bind(null, 'hours')} />
                            </label>
                            <label htmlFor="hourlyCost" className="cell small-12 medium-6">
                              <div>{ lang('device', 'hourly-cost').s }</div>
                              <input type="number" name="hourlyCost" value={variables.hourlyCost || ''} step="any" onChange={this.onNumberChange.bind(null, 'hourlyCost')} />
                            </label>
                          </form>
                        );
                      }}
                    </Mutation>
                    : null
                }

                { this.state.showMaintenanceOperationsForm ? <div className="spacer" /> : null }

                <div className={`${styles['table-container']} ${print ? styles['container--print'] : styles['container--full-page']} small-12 cell`}>
                  <div className={`${styles.header} ${styles['table-row-large']}`}>
                    <span className={styles['table-column']}>{lang('global', 'date').s}</span>
                    <span className={styles['table-column']}>{lang('global', 'time').s}</span>
                    <span className={styles['table-column']}>{lang('global', 'operator').s}</span>
                    { print ? null : <span className={`${styles['table-column']} ${styles['table-column-large']}`}>{lang('global', 'description').s}</span> }
                    { print ? null : <span className={styles['table-column']}>{lang('device', 'part-number').s}</span> }
                    { print ? null : <span className={styles['table-column']}>{lang('device', 'part-cost').s}</span> }
                    { print ? null : <span className={styles['table-column']}>{lang('device', 'duration').s}</span> }
                    { print ? null : <span className={styles['table-column']}>{lang('device', 'hourly-cost').s}</span> }
                    { print ? null : <span className={styles['table-column']}>{lang('device', 'partial').s}</span> }
                    { (can(Permissions.maintenanceOperations.maintenanceOperationsUpdate) && !print) ? <span style={{ flex: 0.2 }} className={styles['table-column']} /> : null }
                    { (can(Permissions.maintenanceOperations.maintenanceOperationsDelete) && !print) ? <span style={{ flex: 0.2 }} className={styles['table-column']} /> : null }
                  </div>
                  { data.maintenanceOperations.map((m) => {
                    const performed = moment(m.performedAt);
                    return (
                      <div>
                        { print ? <hr /> : null }
                        <div className={`${styles.item} ${styles['table-row-large']}`} key={m.id}>
                          <span className={styles['table-column']}>{performed.format('DD/MM/YYYY')}</span>
                          <span className={styles['table-column']}>{performed.format('HH:mm')}</span>
                          <span className={styles['table-column']}>{`${m.user.firstName} ${m.user.lastName}`}</span>

                          { print ? null : <span className={`${styles['table-column']} ${styles['table-column-large']}`}>{m.description}</span> }
                          { print ? null : <span className={styles['table-column']}>{m.partNumber}</span> }
                          { print ? null : <span className={styles['table-column']}>{m.partCost}</span> }
                          { print ? null : <span className={styles['table-column']}>{m.hours}</span> }
                          { print ? null : <span className={styles['table-column']}>{m.hourlyCost}</span> }
                          { print ? null : <span className={styles['table-column']}>{((m.hourlyCost * m.hours) || 0) + m.partCost}</span> }

                          { (can(Permissions.maintenanceOperations.maintenanceOperationsUpdate) && !print) ? <span style={{ flex: 0.2 }} className={`${styles['table-column']} ${styles['table-column-edit']}`} onClick={this.onEdit.bind(null, m)}><i className="icon-edit-2" /></span> : null }
                          {
                            (can(Permissions.maintenanceOperations.maintenanceOperationsDelete) && !print) ?
                              <Mutation mutation={deleteMaintenanceOperationMutation} refetchQueries={[{ query: queries.devices.maintenanceOperations, variables: { id: this.props.deviceId } }]}>
                                {(action, mutationResult) => {
                                  return mutationResult.loading ? <Spinner size={10} /> : <span style={{ flex: 0.2 }} className={`${styles['table-column']} ${styles['table-column-delete']}`} onClick={this.onMaintenanceOperationsDelete.bind(null, action, m.id)}><i className="icon-trash" /></span>;
                                }}
                              </Mutation>
                            :
                            null
                          }
                        </div>
                        { print ?
                          <div className={styles['table-inner-detail']}>
                            <div className="grid-x">
                              <span className={`small-12 cell ${styles['table-column']}`}><b>{m.description}</b></span>
                            </div>
                            <div className="grid-x">
                              <span className={`small-2 cell ${styles['table-column']}`}>{lang('device', 'part-number').s}</span>
                              <span className={`small-2 cell ${styles['table-column']}`}>{m.partNumber}</span>
                            </div>
                            <div className="grid-x">
                              <span className={`small-2 cell ${styles['table-column']}`}>{lang('device', 'part-cost').s}</span>
                              <span className={`small-2 cell ${styles['table-column']}`}>{m.partCost}</span>
                            </div>
                            <div className="grid-x">
                              <span className={`small-2 cell ${styles['table-column']}`}>{lang('device', 'duration').s}</span>
                              <span className={`small-2 cell ${styles['table-column']}`}>{m.hours}</span>
                            </div>
                            <div className="grid-x">
                              <span className={`small-2 cell ${styles['table-column']}`}>{lang('device', 'hourly-cost').s}</span>
                              <span className={`small-2 cell ${styles['table-column']}`}>{m.hourlyCost}</span>
                            </div>
                            <div className="grid-x">
                              <span className={`small-2 cell ${styles['table-column']}`}>{lang('device', 'partial').s}</span>
                              <span className={`small-2 cell ${styles['table-column']}`}>{((m.hourlyCost * m.hours) || 0) + m.partCost}</span>
                            </div>
                          </div>
                        : null}
                      </div>
                    );
                    })
                  }
                </div>
                <div className={`${styles['table-container']} ${print ? styles['container--print'] : styles['container--full-page']} small-12 cell`}>
                  <div className={styles.item}>
                    <span className={styles['table-column']}>{lang('device', 'total-cost').s}</span>
                    <span>{data.maintenanceOperations.map(m => ((m.hourlyCost * m.hours) || 0) + m.partCost).reduce((t, i) => t + i, 0)}</span>
                  </div>
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default withRouter(connect(mapStateToProps)(MaintenanceOperations));
