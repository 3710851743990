import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';
import gql from 'graphql-tag';
import { confirmAlert } from 'react-confirm-alert';

import Spinner from '../common/spinner/Spinner';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';

import styles from './Aside.scss';
import queries from '../../state/queries';
import { Permissions } from '../../state/authorization';
import QueryError from '../common/errors/QueryError';

const deleteMutation = gql`
  mutation deleteSanitation($id: ID!) {
    deleteSanitation(id: $id) { id }
  }
`;

type Props = {
  print?: boolean,
  deviceId: string
}

type State = {
};

class Sanitations extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    can: PropTypes.func
  }

  onSanitationDelete = (action: Function, alarmId: string) => {
    confirmAlert({
      title: this.context.lang('sanitation', 'delete-confirm-title').toString(),
      message: this.context.lang('sanitation', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: alarmId } })
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  render() {
    const { lang, can } = this.context;
    const { print, deviceId } = this.props;

    return (
      <Query query={queries.devices.sanitations} variables={{ id: this.props.deviceId }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.deviceId) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          return (
            <div className="page full-page">
              <div className="grid-x" style={{ width: '100%' }}>
                { print ? null : <Breadcrumbs relative steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${data.device.model} - ${data.device.serial}`, url: `/devices/${this.props.deviceId || ''}` }]} /> }
                { print ? null : <div className="spacer" /> }
                <div className="title cell small-12 medium-6"><span>{lang('device', 'sanification-report').s}</span></div>
                <div className="title-action cell small-12 medium-6">{ print ? null : <Link target="_blank" to={`/print/sanitation/${deviceId}`} className="outline-button float-right show-for-large">{lang('global', 'print').s}</Link> }</div>
                <div className="spacer" />
                <div className="title cell small-12 medium-6">
                  <div className="subtitle">{ lang('device', 'device').s }</div>
                  <span className="subtitle" style={{ color: 'black' }}>{ `${data.device.model} - ${data.device.serial}` }</span>
                </div>
                <div className={`${print ? styles['container--print'] : styles['container--full-page']} ${styles['table-container']} small-12 cell`}>
                  <div className={`${styles.header} ${styles['table-row-small']}`}>
                    <span className={styles['table-column--xsmall']}>{lang('global', 'date').s}</span>
                    <span className={styles['table-column--xsmall']}>{lang('sanitation', 'start-time').s}</span>
                    <span className={styles['table-column--xsmall']}>{lang('sanitation', 'end-time').s}</span>
                    <span className={styles['table-column--xsmall']}>{lang('sanitation', 'total-time').s}</span>
                    <span className={styles['table-column--xsmall']}>{lang('global', 'operator').s}</span>
                    { can(Permissions.devices.deviceSanitationDelete) ? <span style={{ flex: 0.2 }} className={styles['table-column--xsmall']} /> : null }
                  </div>
                  { data.sanitations.map((a) => {
                    const start = moment(a.startTime);
                    const end = moment(a.endTime);
                    return (
                      <div className={`${styles.item} ${styles['table-row-small']}`} key={a.id}>
                        <span className={styles['table-column--xsmall']}>{start.format('DD/MM/YYYY')}</span>
                        <span className={styles['table-column--xsmall']}>{start.format('HH:mm')}</span>
                        <span className={styles['table-column--xsmall']}>{end.format('HH:mm')}</span>
                        <span className={styles['table-column--xsmall']}>{`${end.diff(start, 'minutes')}' ${end.diff(start, 'seconds') % 60}"`}</span>
                        { a.user
                          ? <span className={styles['table-column--xsmall']}>{`${a.user.firstName} ${a.user.lastName}`}</span>
                          : <span className={styles['table-column--xsmall']}>{a.userCode || 'N.A.'}</span>
                        }
                        {
                          (can(Permissions.devices.deviceSanitationDelete) && !print) ?
                            <Mutation mutation={deleteMutation} refetchQueries={[{ query: queries.devices.sanitations, variables: { id: this.props.deviceId } }]}>
                              {(action, mutationResult) => {
                                return mutationResult.loading ? <Spinner size={10} /> : <span style={{ flex: 0.2 }} className={`${styles['table-column--xsmall']} ${styles['table-column-delete']}`} onClick={this.onSanitationDelete.bind(null, action, a.id)}><i className="icon-trash" /></span>;
                              }}
                            </Mutation>
                          :
                          null
                        }
                      </div>
                    );
                    })
                  }
                </div>
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default Sanitations;
