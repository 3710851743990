import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import Spinner from '../common/spinner/Spinner';
import LinkCard from '../common/card/LinkCard';
import Pager from '../common/pager/Pager';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  customerId: ?string,
}

type State = {
  search: string;
  debounce: boolean;
  page: number;
}

class Users extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = { search: '', debounce: false, page: 1 }

  onSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.interval) {
      clearTimeout(this.interval);
    }

    this.setState({ search: e.target.value, debounce: true, page: 1 });

    this.interval = setTimeout(() => {
      this.setState({ debounce: false });
    }, 200);
  }

  onPage = (page: number) => {
    this.setState({ page });
  }

  interval = null;

  render() {
    const { lang } = this.context;
    const { page } = this.state;

    return (
      <div className="page full-page" style={{ paddingTop: '0' }}>
        <div className="grid-x">
          <div className="title medium-8 cell">{ lang('users', 'users').s }</div>
          <input type="text" placeholder="Search..." className="small-12 medium-4 cell search" value={this.state.search} onChange={this.onSearch} />
          <div className="spacer" />
        </div>
        <Query query={queries.users.list} variables={{ customerId: this.props.customerId, page, search: this.state.search, perPage: 12, order: 'firstName_ASC' }} fetchPolicy="network-only" skip={this.state.debounce}>
          {({ loading, error, data }) => {
            if (loading) return <div className="page"><Spinner /></div>;
            if (error) return <QueryError lang={lang} error={error.toString()} />;
            if (!data) return null;

            return (
              <div>
                <div className="action-header">
                  <div className="subtitle">{ lang('users', 'users').s }</div>
                  <Link to="/users/new" className="outline-button">{ lang('users', 'create').s }</Link>
                </div>
                <div className="grid-x">
                  {
                    data.users.results.map(u => <LinkCard className="small-12 medium-3 cell" title={`${u.firstName} ${u.lastName}`} subtitle={`#${u.code} - ${u.role}`} key={u.id} url={`/users/${u.id}`} />)
                  }
                </div>
                <Pager pages={data.users.totalPages} current={page} onPage={this.onPage} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Users;
