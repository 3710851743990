import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import { Link } from 'react-router-dom';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { confirmAlert } from 'react-confirm-alert';

import Spinner from '../common/spinner/Spinner';
import LinkCard from '../common/card/LinkCard';
import ButtonCard from '../common/card/ButtonCard';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  deviceId: string,
  onSendCompleted: Function
}

type State = {
  page: number,
  order: string,
  more: boolean
}

const sendRecipes = gql`
  mutation sendRecipes($id: ID!) {
    sendRecipes(id: $id) { id }
  }
`;

class Recipes extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = { page: 1, order: 'number_ASC', more: false }

  onSync = (action: Function) => {
    confirmAlert({
      title: this.context.lang('device', 'sync-confirm-title').toString(),
      message: this.context.lang('device', 'sync-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: this.props.deviceId } })
        },
        { label: this.context.lang('global', 'no').toString() }
      ]
    });
  }

  render() {
    const { lang } = this.context;
    const { deviceId } = this.props;
    const { page, order, more } = this.state;

    return (
      <div className="page">
        <Query query={queries.recipes.listSearch} variables={{ page, order, deviceId }} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading) return <Spinner absolute />;
            if (error) return <QueryError lang={lang} error={error.toString()} />;
            if (!data) return null;

            return (
              <div>
                <div className="action-header">
                  <div className="subtitle">{ lang('recipes', 'recipes').s }</div>
                  <Link to={`/devices/${deviceId}/recipes/new`} className="outline-button">{ lang('recipes', 'create').s }</Link>
                </div>
                <div className="grid-x">
                  {
                    data.recipes.slice(0, more ? data.recipes.length : Math.min(data.recipes.length, 6)).map((r) => {
                      const description = r.description || lang('recipe', 'no-description').toString();
                      const subtitle = r.profile.weight
                      ? `${r.profile.weight}gr - ${r.profile.yield}pz/h`
                      : `${r.profile.target}gr`;

                      return <LinkCard className="small-12 medium-6 large-4 cell" title={`${r.number} - ${description.substring(0, 20)}${description.length > 20 ? '...' : ''}`} subtitle={subtitle} key={r.id} url={`/devices/${deviceId}/recipes/${r.id}`} />;
                    })
                  }
                </div>
                <div className="grid-x">
                  {
                    data.recipes.length > 6
                    ? <ButtonCard className="small-12 medium-6 large-4 cell" subtitle={more ? 'less' : 'more'} onSelect={() => { this.setState({ more: !this.state.more }); }} subtle />
                    : null
                  }
                </div>
                { data.recipes.length > 0 ?
                  <Mutation mutation={sendRecipes} onCompleted={this.props.onSendCompleted}>
                    {(action) => {
                      return (
                        <div className="action-button" onClick={this.onSync.bind(null, action)}>
                          {lang('device', 'sync-recipes').toString()}
                          <div className="icon-cloud-download" />
                        </div>
                      );
                    }}
                  </Mutation>
                : null }
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Recipes;
