import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import { i18n } from 'redux-pagan';
import { reducer as notificationsReducer } from 'react-notification-system-redux';

import appReducer from './app/reducer';
import sessionReducer from './session/reducer';

// flowlint-line-ignore
const rootReducer = combineReducers({
  routing: routerReducer,
  notifications: notificationsReducer,
  i18n,
  app: appReducer,
  session: sessionReducer,
});

export default rootReducer;
