import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';

import styles from './Production.scss';
import recipeStyles from '../recipes/DetailForm.scss';

type Props = {
  history: any,
  print?: ?boolean,
  planningItemId: string
}

type State = {
};

class LybraReport extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  render() {
    const piecesForItem = (p: any) => {
      return p.targetPieces || (parseInt((p.tanks * p.tankWeight) / ((p.recipe.profile.weight || p.recipe.profile.target) / 1000), 10) || 0);
    };

    const timeForitem = (p: any) => {
      return parseInt(((piecesForItem(p) / p.recipe.profile.yield) * 60).toFixed(0), 10);
    };

    const realTimeForitem = (goodPieces: any, p: any) => {
      return parseInt(((goodPieces / p.recipe.profile.yield) * 60).toFixed(0), 10);
    };

    const { lang } = this.context;
    const { history, print } = this.props;
    const planningItem = history.planning.planningItems.find(e => e.id === this.props.planningItemId);
    const { order } = planningItem;
    const isLybra = this.props.history.device.profile.__typename === 'Lybra';

    let json;
    try {
      json = JSON.parse(history.status);
    } catch (reason) {
      return <div>Production status cannot be retrieved</div>;
    }

    const item = history.planning.planningItems.filter(p => p.id === this.props.planningItemId)[0];
    if (!item) return <div>Item not found</div>;

    if (window.VERBOSE_LIVE_MSG) {
      console.log(json);  // eslint-disable-line
    }

    let operatorCode = 'N/A';
    if (json && json[order] && json[order].user_code) {
      operatorCode = json[order].user_code;
    }

    let startTime = '';
    if (json && json[order] && json[order].start_time) {
      startTime = moment(json[order].start_time).format('DD/MM/YYYY HH:mm');
    }
    let endTime = '';
    if (json && json[order] && json[order].end_time) {
      endTime = moment(json[order].end_time).format('DD/MM/YYYY HH:mm');
    }
    let workingTime = '';
    if (json && json[order] && json[order].working_time) {
      workingTime = moment.utc(json[order].working_time).format('HH:mm:ss');
    }

    let green = 0;
    if (json && json[order] && json[order].lybra && json[order].lybra.green_pieces) {
      green = json[order].lybra.green_pieces;
    }
    let redBlueNegative = 0;
    if (json && json[order] && json[order].lybra && json[order].lybra.red_blue_negative_pieces) {
      redBlueNegative = json[order].lybra.red_blue_negative_pieces;
    }
    let greenBlueNegative = 0;
    if (json && json[order] && json[order].lybra && json[order].lybra.green_blue_negative_pieces) {
      greenBlueNegative = json[order].lybra.green_blue_negative_pieces;
    }
    let redBluePositive = 0;
    if (json && json[order] && json[order].lybra && json[order].lybra.red_blue_positive_pieces) {
      redBluePositive = json[order].lybra.red_blue_positive_pieces;
    }
    let greenBluePositive = 0;
    if (json && json[order] && json[order].lybra && json[order].lybra.green_blue_positive_pieces) {
      greenBluePositive = json[order].lybra.green_blue_positive_pieces;
    }
    let goodPiecesTotalWeight = 0;
    if (json && json[order] && json[order].lybra && json[order].lybra.good_pieces && json[order].lybra.good_pieces_average_weight) {
      goodPiecesTotalWeight = json[order].lybra.good_pieces && (json[order].lybra.good_pieces * json[order].lybra.good_pieces_average_weight) / 1000;
    }
    let goodPiecesAverageWeight = 0;
    if (json && json[order] && json[order].lybra && json[order].lybra.good_pieces_average_weight) {
      goodPiecesAverageWeight = json[order].lybra.good_pieces_average_weight;
    }
    let goodPieces = 0;
    if (json && json[order] && json[order].lybra && json[order].lybra.good_pieces) {
      goodPieces = json[order].lybra.good_pieces;
    }
    let actualTime = null;
    if (json && json[order] && json[order].lybra && json[order].lybra.actual_production_time) {
      actualTime = json[order].lybra.actual_production_time;
    }

    const targetPieces = piecesForItem(item) || item.recipe.profile.yield;
    let breakingYield = null;
    if (goodPieces > 0) {
      if (isLybra) {
        breakingYield = ((goodPieces / (goodPieces + greenBlueNegative + greenBluePositive)) * 100).toFixed(2);
      } else {
        breakingYield = ((goodPieces / (goodPieces + redBluePositive + redBlueNegative)) * 100).toFixed(2);
      }
    }

    let timeEstimate = timeForitem(item);
    if (goodPieces > 0) {
      timeEstimate = realTimeForitem(goodPieces, item);
    }

    let productionYield = null;
    if (actualTime) {
      productionYield = ((timeEstimate / actualTime) * 100).toFixed(2);
    }

    const weight = (item.recipe.profile.weight || item.recipe.profile.target);
    const b1 = isLybra ? (weight + item.recipe.profile.backStep) : (weight + item.recipe.profile.lybraBackStep1) - 1;
    const b2 = weight + item.recipe.profile.lybraBackStep1;
    const f1 = isLybra ? (weight + item.recipe.profile.forwardStep) : weight + item.recipe.profile.lybraForwardStep1;
    const f2 = weight + item.recipe.profile.lybraForwardStep1 + item.recipe.profile.lybraForwardStep2;
    const f3 = weight + item.recipe.profile.lybraForwardStep1 + item.recipe.profile.lybraForwardStep2 + 1;
    const minRange = isLybra ? weight + item.recipe.profile.backStep : weight + (item.recipe.profile.lybraBackStep1 || 0);
    const maxRange = isLybra ? weight + item.recipe.profile.forwardStep : weight + (item.recipe.profile.lybraForwardStep1 || 0) + (item.recipe.profile.lybraForwardStep2 || 0);
    return (
      <div className="page full-page" style={print ? { padding: 0 } : {}}>
        { print ? null : <Breadcrumbs steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${history.device.model} - ${history.device.serial}`, url: `/devices/${history.device.id}` }, { title: lang('device', 'production').s, url: `/devices/${history.device.id}/production` }, { title: 'Lybra', url: `/production/${history.id}` }]} relative /> }

        <div className="grid-x">
          <div className="cell small-12">{ print ? null : <Link target="_blank" to={`/print/planning-item/${history.id}/${item.id}`} className="outline-button float-right show-for-large">{lang('global', 'print').s}</Link> }</div>
        </div>

        <div style={{ margin: '1rem 0' }}>
          <div><b>{`${lang('planning', 'planning-ref').s}: `}</b>{history.planning.number}</div>
          <div><b>{`${lang('production', 'start').s}: `}</b>{moment(history.createdAt).format('YYYY-MM-DD')}</div>
          <div><b>{`${lang('production', 'operator').s}: `}</b>{operatorCode}</div>
        </div>
        <div className="action-header"><div className="subtitle">{lang('recipe', 'recipe').s}</div></div>
        <div className="grid-x">
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('recipe', 'number').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{item.recipe.number}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('recipe', 'description').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{item.recipe.description}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('recipe', 'pieces-to-do').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{targetPieces}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('recipe', 'range-min').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{minRange}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('recipe', 'target').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{weight}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('recipe', 'range-max').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{maxRange}</span>
          </div>
        </div>
        <div className="action-header" style={{ marginTop: '1rem' }}><div className="subtitle">{lang('production', 'feedback').s}</div></div>
        <div className="grid-x">
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('production', 'start').s}</span>
            <span style={{ flex: '1' }} className={styles.item}>{startTime}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('production', 'end').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{endTime}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('production', 'duration').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{workingTime}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('production', 'pieces').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{goodPieces}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('production', 'avg-weight').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{goodPiecesAverageWeight}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('production', 'total-kg').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{goodPiecesTotalWeight.toFixed(3)}</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('production', 'breaking-yield').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{breakingYield || '-'} %</span>
          </div>
          <div className="small-12 large-4 cell">
            <span style={{ flex: '1' }} className={styles.header}>{ lang('production', 'production-yield').s }</span>
            <span style={{ flex: '1' }} className={styles.item}>{productionYield || '-'} %</span>
          </div>
        </div>
        <div className="grid-x">
          {
            isLybra ?
            (
              <div className={`cell grid-x ${recipeStyles.lybra}`}>
                <div className={recipeStyles['lybra-header']}><span className={recipeStyles.red} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.red} /></div>
                <div className={recipeStyles['lybra-header']}><span className={recipeStyles.red} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.red} /></div>
                <div className={recipeStyles['lybra-inputs']}>
                  <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                    <span>{greenBlueNegative}</span>
                    <span>&le;{b1 ? `${b1 - 1}gr` : ''}</span>
                  </div>
                  <div className={recipeStyles['lybra-cell']} style={{ flex: '2' }}>
                    <span>{goodPieces}</span>
                    <div style={{ display: 'flex' }}>
                      <span style={{ flex: '1' }}>{b1 ? `${b1}gr` : ''}</span>
                      <span style={{ flex: '1' }}>{weight ? `${weight}gr` : ''}</span>
                      <span style={{ flex: '1' }}>{f1 ? `${f1}gr` : ''}</span>
                    </div>
                  </div>
                  <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                    <span>{greenBluePositive}</span>
                    <span>&ge;{f1 ? `${f1 + 1}gr` : ''}</span>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`cell grid-x ${recipeStyles.lybra}`}>
                <div className={recipeStyles['lybra-header']}><span className={recipeStyles.blue} /><span className={recipeStyles.blue} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.blue} /><span className={recipeStyles.blue} /></div>
                <div className={recipeStyles['lybra-header']}><span className={recipeStyles.red} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.green} /><span className={recipeStyles.red} /></div>
                <div className={recipeStyles['lybra-inputs']}>
                  <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                    <span>{redBlueNegative}</span>
                    <span>{b1 ? `<${b1}gr` : ''}</span>
                  </div>
                  <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                    <span>{greenBlueNegative}</span>
                    <span>{b2 ? `${b2}gr` : ''}</span>
                  </div>
                  <div className={recipeStyles['lybra-cell']} style={{ flex: '2' }}>
                    <span>{green}</span>
                    <div style={{ display: 'flex' }}>
                      <span style={{ flex: '1' }}>{weight}</span>
                      <span style={{ flex: '1' }}>{f1 ? `${f1}gr` : ''}</span>
                    </div>
                  </div>
                  <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                    <span>{greenBluePositive}</span>
                    <span>{f2 ? `${f2}gr` : ''}</span>
                  </div>
                  <div className={recipeStyles['lybra-cell']} style={{ flex: '1' }}>
                    <span>{redBluePositive}</span>
                    <span>{f3 ? `>${f3}gr` : ''}</span>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
    );
  }
}

export default LybraReport;
