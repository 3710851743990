import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';

// import ApolloClient from 'apollo-boost';
import { ApolloProvider } from 'react-apollo';
import { ApolloClient } from 'apollo-client';
import { ApolloLink } from 'apollo-link';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { withClientState } from 'apollo-link-state';
import { createUploadLink } from 'apollo-upload-client';
import SecureLS from 'secure-ls';

// flowlint-line-ignore
import createHistory from 'history/createBrowserHistory';
import { ConnectedRouter, routerMiddleware } from 'react-router-redux';

import introspectionQueryResultData from './fragmentTypes.json';

import actions from './state/actions';
import initialState from './state/initial-state';
import routes from './routes';
import configureStore from './state/store';
import App from './containers/App';

import './styles/styles.global.scss';

require('./images/favicon.ico');

document.addEventListener('DOMContentLoaded', () => {
  const history = createHistory();
  const middleware = routerMiddleware(history);

  const store = configureStore(initialState, middleware);

  // Listen to changes and pesist the session store
  store.subscribe(() => {
    const session = Object.assign({}, store.getState().session);
    delete session.authenticating;
    delete session.error;
    const ls = new SecureLS({ encodingType: 'aes' });
    ls.set('session', JSON.stringify(session));
  });

  const uploadLink = createUploadLink({
    uri: window.configs.GRAPHQL_ENDPOINT
  });

  const middlewareAuthLink = new ApolloLink((operation, forward) => {
    let session;
    try {
      const ls = new SecureLS({ encodingType: 'aes' });
      session = ls.get('session');
      session = JSON.parse(session || '');
    } catch (e) {
      // do nothing
    }
    if (session && session.access_token) {
      operation.setContext({
        headers: {
          authorization: `Bearer ${(session.access_token : any)}`,
        }
      });
    }
    return forward(operation);
  });

  const fragmentMatcher = new IntrospectionFragmentMatcher({
    introspectionQueryResultData
  });

  // https://www.apollographql.com/docs/react/advanced/fragments.html
  const memoryCache = new InMemoryCache({ fragmentMatcher });

  const stateLink = withClientState({
    cache: memoryCache
  });

  const logoutLink = onError(({ networkError }) => {
    if (networkError && networkError.statusCode === 401) {
      store.dispatch(actions.session.logout());
    }
  });

  const client = new ApolloClient({
    link: ApolloLink.from([
      middlewareAuthLink,
      stateLink,
      logoutLink,
      uploadLink // Note: upload-link is a dropin replacement for http-link
    ]),
    cache: memoryCache
  });

  render(
    <ApolloProvider client={client} >
      <Provider store={store} >
        <ConnectedRouter history={history}>
          <App>
            {routes}
          </App>
        </ConnectedRouter>
      </Provider>
    </ApolloProvider>,
    (document.getElementById('app'): any)
  );
});
