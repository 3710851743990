export const ApiEndpoints = {
  LOGIN: 'LOGIN',
  ME: 'ME'
};
export type ApiEndpointsType = $Values<typeof ApiEndpoints>;

export default {
  api: {
    LOGIN: ':endpoint/login',
    ME: ':endpoint/me'
  }
};
