import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import moment from 'moment';
import querystring from 'query-string';

import Spinner from '../common/spinner/Spinner';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import Pager from '../common/pager/Pager';

import queries from '../../state/queries';

import styles from './Aside.scss';
import QueryError from '../common/errors/QueryError';

type Props = {
  print?: boolean,
  deviceId: string
}

type State = {
  donwloadingId?: string
};

class LybraWeights extends Component<Props, State> {
  static contextTypes = {
    push: PropTypes.func,
    dispatch: PropTypes.func,
    lang: PropTypes.func,
    can: PropTypes.func,
    routing: PropTypes.object,
    client: PropTypes.object
  }

  state = {
    donwloadingId: undefined
  }

  onPage = (page: number) => {
    const { dispatch, push, routing } = this.context;

    dispatch(push({ pathname: routing.location.pathname, search: `?page=${page}` }));
  }


  onDownload = async (kind: string, id: string, model: string, date: string) => {
    const { client } = this.context;
    this.setState({ donwloadingId: id });
    const response = await client.query({ query: queries.devices.lybraWeightReport, variables: { id }, fetchPolicy: 'network-only' });
    this.setState({ donwloadingId: undefined });

    if (response.data && response.data.lybraWeightReport) {
      const { payload } = response.data.lybraWeightReport;
      let content = '';
      if (kind === 'Autonoma') {
        content = 'Date,Name,Weight,Y/N,Target,Range,Tare,Rng_Mno\n';
      } else {
        content = 'Date,Name,Weight,Y/N,Target,Tol.\n';
      }

      // eslint-disable-next-line no-return-assign
      payload.forEach((i) => {
        if (kind === 'Autonoma') {
          content += `${i.date},${i.recipeName},${i.weight},${i.y_n},${i.targetWeight},${i.range},${i.tare},${i.rng_mno}\n`;
        } else {
          content += `${i.date},${i.recipeName},${i.weight},${i.y_n},${i.targetWeight},${i.tolerance}\n`;
        }
      });
      // flowlint-line-ignore
      if (!(typeof Android === 'undefined' || Android === null)) {
        Android.downloadConfig(content);
      } else {
        const download = document.createElement('a');
        download.setAttribute('target', '_blank');
        const file = new Blob([content], { type: 'text/plain' });
        download.href = URL.createObjectURL(file);
        download.download = `report_${model}_${date}.csv`;
        // flowlint-line-ignore
        document.body.appendChild(download);
        download.click();
        setTimeout(() => {
          // flowlint-line-ignore
          document.body.removeChild(download);
        }, 500);
      }
    }
  };

  render() {
    const { lang, routing } = this.context;
    const { print } = this.props;

    const page = querystring.parse(routing.location.search).page || 1;

    return (
      <Query query={queries.devices.lybraWeightReports} variables={{ id: this.props.deviceId, page: parseInt(page, 10) }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.deviceId) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          return (
            <div className={`${print ? 'page-print' : 'page'} full-page`}>
              <div className="grid-x" style={{ width: '100%' }}>
                { print ? null : <Breadcrumbs relative steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${data.device.model} - ${data.device.serial}`, url: `/devices/${this.props.deviceId || ''}` }]} /> }
                { print ? null : <div className="spacer" /> }
                <div className="title cell small-12 medium-6"><span>{lang('lybraReports', 'title').s}</span></div>
                {/* <div className="cell small-12 medium-6 show-for-large">{ print ? null : <Link target="_blank" to={`/print/weight-reports/${deviceId}`} className="outline-button float-right">{lang('global', 'print').s}</Link> }</div> */}
                <div className="spacer" />

                <div className="title cell small-12 medium-6">
                  <div className="subtitle">{ lang('device', 'device').s }</div>
                  <span className="subtitle" style={{ color: 'black' }}>{ `${data.device.model} - ${data.device.serial}` }</span>
                </div>

                <div className={`${print ? styles['container--print'] : styles['container--full-page']} ${styles['table-container']} small-12 cell`}>
                  <div style={{ minWidth: '30rem' }} className={`${styles.header} ${styles['table-row-small']}`}>
                    <span className={styles['table-column--xsmall']}>{lang('global', 'date').s}</span>
                    <span className={styles['table-column--xsmall']}>{lang('lybraReports', 'count').s}</span>
                    <span className={styles['table-column--xsmall']}>{lang('lybraReports', 'type').s}</span>
                    <span style={{ flex: 0.2 }} className={styles['table-column--xsmall']} />
                  </div>
                  { data.lybraWeightReports.results.map((r) => {
                    const raised = moment(r.date);
                    const dateString = raised.format('DD/MM/YYYY');
                    return (
                      <div style={{ minWidth: '30rem' }} className={`${styles.item} ${styles['table-row-small']}`} key={r.id}>
                        <span className={styles['table-column--xsmall']}>{dateString}</span>
                        <span className={styles['table-column--xsmall']}>{r.weighsCount}</span>
                        <span className={styles['table-column--xsmall']}>{lang('lybraReports', r.lybraKind.toLowerCase()).s}</span>
                        {
                          this.state.donwloadingId === r.id
                            ? <span style={{ flex: 0.2, cursor: 'pointer' }} className={styles['table-column--xsmall__action']}><Spinner size={8} /></span>
                            : <span style={{ flex: 0.2, cursor: 'pointer' }} className={styles['table-column--xsmall__action']} onClick={this.onDownload.bind(null, r.lybraKind, r.id, data.device.serial, dateString)}>{lang('lybraReports', 'download').s}</span>
                        }
                      </div>
                    );
                    })
                  }
                </div>
                { print ? null : <Pager current={parseInt(page, 10)} pages={data.lybraWeightReports.totalPages} onPage={this.onPage} /> }
              </div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default LybraWeights;
