import _ from 'lodash';
import moment from 'moment';
import Notifications from 'react-notification-system-redux';

import constants from '../globals/constants';

type CallbackType = ?(state: any, action?: any) => any;

export type Reducer = {
  types: string | [string, string, string];
  stateKey?: string;
  baseState?: [?any, ?any, ?any];
  data?: [?CallbackType | boolean, ?CallbackType | boolean, ?CallbackType | boolean];
  callbacks?: [CallbackType, CallbackType, CallbackType];
}

export const notificationSuccess = (message: string) => {
  const notification = {};
  notification.message = message;
  return Notifications.info(notification);
};

export const parseErrorMessage = (error: any) => {
  let msg = 'ERROR';
  if (typeof error === 'string') msg = error;
  else if (_.isArray(error)) msg = error.join(', ');
  else if (_.isError(error)) {
    msg = error.message;
    if (error.response && typeof error.response === 'string') {
      msg = msg.concat(', ').concat(error.response);
    } else if (error.response && typeof error.response === 'object' && error.response.errors && _.isArray(error.response.errors)) {
      msg = msg.concat(', ').concat(error.response.errors.join(', '));
    }
  }

  return msg;
};

export const getApiEndpoint = (endpoint : string) => {
  return constants.api[endpoint].replace(':endpoint', window.configs.ENDPOINT);
};

export const formatElapsedTime = (elapsedTime: number, mu: string = 'seconds') => {
  const d = moment.duration(elapsedTime, mu);
  return `${d.days()}gg ${d.hours()}h ${d.minutes()}m ${d.seconds()}s`;
};
