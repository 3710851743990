import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';

import Queries from '../../state/queries';

import Spinner from '../common/spinner/Spinner';
import DetailForm from './DetailForm';
import QueryError from '../common/errors/QueryError';

type Props = {
  customerId?: string,
  onCompleted: Function
}

type State = {};

class Detail extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  render() {
    const { lang } = this.context;
    return (
      <Query query={Queries.customers.detail} variables={{ id: this.props.customerId }} skip={!this.props.customerId} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading && this.props.customerId) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;

          return <DetailForm customer={data ? data.customer : null} onCompleted={this.props.onCompleted} />;
        }}
      </Query>
    );
  }
}

export default Detail;
