import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

type Props = {}
type State = {}

class NotFoundPage extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  render() {
    const { lang } = this.context;

    return (
      <div className="vertical flex-1 center" style={{ padding: '2rem' }}>
        <h3>{lang('global', 'page_not_found').s}</h3>
        <div className="spacer" />
        <Link to="/" className="primary text-upcase">{lang('global', 'go_back_to_home').s}</Link>
      </div>
    );
  }
}

export default NotFoundPage;
