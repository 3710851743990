import React, { Component } from 'react';
import { connect } from 'react-redux';

import initialState from '../state/initial-state';
import Home from '../components/home/Home';

type Props = {
  session: typeof initialState.session,
};
type State = {};

class HomePage extends Component<Props, State> {
  render() {
    const { session } = this.props;
    return <Home session={session} />;
  }
}

function mapStateToProps(state) {
  return {
    session: state.session,
  };
}

// flowlint-line-ignore
export default connect(mapStateToProps, (dispatch: Dispatch) => ({ dispatch }))(HomePage);
