import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';
import Select from 'react-select';
import IPut from 'iput';

import styles from './Config.scss';
import Spinner from '../common/spinner/Spinner';
import TabPanel from '../common/tabpanel/TabPanel';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  deviceId?: string
}

type State = {
  ssid: string,
  password: string,
  fancybox: number,
  hmi: string,
  plc: string
};

class Config extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = {
    ssid: '',
    password: '',
    fancybox: 2,
    hmi: null,
    plc: null
  }

  onTextChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ [field]: e.target.value });
  }

  onSelect = (option: { label: string, value: number }) => {
    this.setState({ fancybox: option.value });
  }

  onDownload = (content: string) => {
    // flowlint-line-ignore
    if (!(typeof Android === 'undefined' || Android === null)) {
      Android.downloadConfig(content);
    } else {
      const download = document.createElement('a');
      download.setAttribute('target', '_blank');
      const file = new Blob([content], { type: 'text/plain' });
      download.href = URL.createObjectURL(file);
      download.download = 'configuration.json';
      // flowlint-line-ignore
      document.body.appendChild(download);
      download.click();
      setTimeout(() => {
        // flowlint-line-ignore
        document.body.removeChild(download);
      }, 500);
    }
  }

  onIPChange = (type: string, ip: string) => {
    this.setState({ [type]: ip });
  };

  render() {
    const { lang } = this.context;
    const { fancybox } = this.state;

    return (
      <TabPanel tabsPosition="bottom" tabs={[lang('global', 'configuration-tab-title').s, lang('global', 'help-tab-title').s]}>
        <Query query={queries.devices.configs} variables={{ id: this.props.deviceId }} fetchPolicy="network-only">
          {({ loading, error, data }) => {
            if (loading && this.props.deviceId) return <div className="page grid-x"><Spinner absolute /></div>;
            if (error) return <div className="page grid-x"><QueryError lang={lang} error={error.toString()} /></div>;

            if (!data) return null;

            let configs;
            try {
              configs = JSON.parse(data.deviceConfigs);
            } catch (e) {
              configs = {};
            }

            const defaultHmiIp = data.device.profile.__typename === 'Lybra' ? '192.168.50.91' : '192.168.50.81';
            const defaultPlcIp = data.device.profile.__typename === 'Lybra' ? '192.168.50.90' : '192.168.50.80';

            if (fancybox === 2) {
              configs = { plc: { ip: this.state.plc || defaultPlcIp }, hmi: { ipAddress: this.state.hmi || defaultHmiIp }, spacebunny: { ...configs } };
            } else {
              configs = { wifi: { ssid: this.state.ssid, password: this.state.password }, ...configs };
            }

            const string = JSON.stringify(configs, null, 2);
            const kinds = [{ value: 1, label: 'FancyBox' }, { value: 2, label: 'FancyBox 2' }];

            return (
              <div className="page grid-x">
                <Breadcrumbs steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${data.device.model} - ${data.device.serial}`, url: `/devices/${this.props.deviceId || ''}` }]} relative />
                <div className="title-action small-12 cell">
                  <div className="outline-button float-right" onClick={this.onDownload.bind(null, string)}>Download</div>
                </div>
                <form className="grid-x cell">
                  <label htmlFor="fancybox" className="cell small-12">
                    <div>FBX</div>
                    <Select classNamePrefix="Select" options={kinds} value={kinds.filter(e => e.value === fancybox)} onChange={this.onSelect} clearable={false} searchable={false} />
                  </label>
                  {
                    fancybox === 1 ? (
                      <label htmlFor="ssid" className="cell small-12 medium-6">
                        <div>{ lang('device', 'ssid').s }</div>
                        <input type="text" name="ssid" value={this.state.ssid} onChange={this.onTextChange.bind(null, 'ssid')} />
                      </label>
                    ) : null
                  }
                  {
                    fancybox === 1 ? (
                      <label htmlFor="password" className="cell small-12 medium-6">
                        <div>{ lang('device', 'password').s }</div>
                        <input type="text" name="password" value={this.state.password} onChange={this.onTextChange.bind(null, 'password')} />
                      </label>
                    ) : null
                  }
                  {
                    fancybox === 2 ? (
                      <label htmlFor="plcIp" className="cell small-12 medium-6">
                        <div>{ lang('device', 'plcIp').s }</div>
                        <IPut onChange={this.onIPChange.bind(null, 'plc')} defaultValue={defaultPlcIp} />
                      </label>
                    ) : null
                  }
                  {
                    fancybox === 2 ? (
                      <label htmlFor="hmiIp" className="cell small-12 medium-6">
                        <div>{ lang('device', 'hmiIp').s }</div>
                        <IPut onChange={this.onIPChange.bind(null, 'hmi')} defaultValue={defaultHmiIp} />
                      </label>
                    ) : null
                  }
                </form>
                <pre className={`${styles.code} small-12 cell`}>
                  {string}
                </pre>
              </div>
            );
          }}
        </Query>
        <aside className="grid-x">
          <div className="subtitle">{ lang('device', 'config-instruction-title').s }</div>
          <span className="subtitle--dark">{ lang('device', 'config-instruction').s }</span>
          <div className="subtitle" style={{ marginTop: '1rem' }}>{ lang('device', 'config-instruction-title-2').s }</div>
          <span className="subtitle--dark">{ lang('device', 'config-instruction-2').s }</span>
        </aside>
      </TabPanel>
    );
  }
}

export default Config;
