import React, { Component } from 'react';
import { Query } from 'react-apollo';
import PropTypes from 'prop-types';

import Spinner from '../common/spinner/Spinner';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  deviceId?: string
}

type State = {
};

class PrintHeader extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = {
  }

  render() {
    const { lang } = this.context;

    return (
      <Query query={queries.devices.basic} variables={{ id: this.props.deviceId }} fetchPolicy="network-only">
        {({ loading, error, data }) => {
          if (loading) return <Spinner absolute />;
          if (error) return <QueryError lang={lang} error={error.toString()} />;
          if (!data) return null;

          return (
            <div style={{ margin: '1rem 0' }}>
              <div><i>{data.device.customer.name}</i></div>
              <div><b>Serial: </b>{data.device.serial}</div>
              <div><b>Model: </b>{data.device.model}</div>
            </div>
          );
        }}
      </Query>
    );
  }
}

export default PrintHeader;
