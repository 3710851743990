import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { confirmAlert } from 'react-confirm-alert';
import Select from 'react-select';
import omitDeep from 'omit-deep-lodash';

import styles from './DetailForm.scss';
import MutationError from '../common/errors/MutationError';
import Spinner from '../common/spinner/Spinner';
import Checkbox from '../common/form/Checkbox';
import NumericInput from '../common/form/NumericInput';
import TabPanel from '../common/tabpanel/TabPanel';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';

const createCalybraRecipe = gql`
  mutation createCalybraRecipe($device: DeviceRelationAttributes!, $description: String, $profile: CalybraRecipeAttributes!) {
    createCalybraRecipe(attributes: { device: $device, description: $description }, profile: $profile) {
      recipe {
        id
      }
    }
  }
`;

const updateCalybraRecipe = gql`
  mutation updateCalybraRecipe($id: ID!, $device: DeviceRelationAttributes!, $description: String, $profile: CalybraRecipeAttributes!) {
    updateCalybraRecipe(id: $id, attributes: { device: $device, description: $description }, profile: $profile) {
      recipe {
        id
      }
    }
  }
`;

const createLybraRecipe = gql`
  mutation createLybraRecipe($device: DeviceRelationAttributes!, $description: String, $profile: LybraRecipeAttributes!) {
    createLybraRecipe(attributes: { device: $device, description: $description }, profile: $profile) {
      recipe {
        id
      }
    }
  }
`;

const updateLybraRecipe = gql`
  mutation updateLybraRecipe($id: ID!, $device: DeviceRelationAttributes!, $description: String, $profile: LybraRecipeAttributes!) {
    updateLybraRecipe(id: $id, attributes: { device: $device, description: $description }, profile: $profile) {
      recipe {
        id
      }
    }
  }
`;

const recipeDeleteMutation = gql`
  mutation deleteRecipe($id: ID!) {
    deleteRecipe(id: $id) { id }
  }
`;

type Props = {
  recipe?: any,
  device: any,
  meta: any,
  onCompleted: Function
}

type State = {
  values: any,
  errors: any,
  warnings: any
}

class DetailForm extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    dispatch: PropTypes.func,
    push: PropTypes.func
  }

  state = {
    values: {
      id: null,
      device: {},
      division: 'single',
      profile: { }
    },
    errors: {},
    warnings: {}
  }

  componentWillMount() {
    if (this.props.recipe) {
      this.setState({ values: { ...this.props.recipe, device: { id: this.props.recipe.device.id } } });
    } else {
      /* eslint-disable*/
      if (this.props.device.profileType === 'CalybraProfile') {
        const { meta } = this.props;
        const defaultPressing = meta.filter(m => m.default)[0].value;
        this.setState({ values: { ...this.props.recipe, profile: { division: 'single', mass: 1, duster1: true, duster2: false, correction: 0, duster1Speed: 100, duster2Speed: 100, separatorSpeed: 100, exitSpeed: 100, rounderSpeed: 100, lybraBackStep1: 0, lybraForwardStep1: 0, lybraForwardStep2: 0, pressingTime: defaultPressing } } });
      } else {
        this.setState({ values: { ...this.props.recipe, profile: { yield: 0, target: 0, backStep: 0, forwardStep: 0 } } });
      }
      /* eslint-enable */
    }
  }

  onClick = (action: Function, device: any, e: SyntheticMouseEvent<*> | SyntheticTouchEvent<*>) => {
    e.preventDefault();
    const variables = omitDeep({ ...this.state.values, device: { id: this.props.device.id } }, '__typename');

    if (this.props.device.profileType === 'CalybraProfile') {
      // B1A
      if (device.profile.exit === 'fontal_single') {
        variables.profile.separatorSpeed = null;
      }

      // B1B
      if (device.profile.exit !== 'side' || device.profile.rounder === false) {
        variables.profile.rounderSpeed = null;
      }

      // B2A
      if (device.profile.exit !== 'side') {
        variables.profile.exitSpeed = null;
      }
    }

    const errors = this.validate(variables, this.props.device.profileType);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    // As of 2023-03-13 Forward step 1 defaults to (forwardStep2 / 2)
    if (variables.profile.lybraForwardStep2 !== undefined) {
      variables.profile.lybraForwardStep1 = Math.floor(variables.profile.lybraForwardStep2 / 2);
    }

    action({ variables });
  }

  onRecipeDelete = (action: Function) => {
    confirmAlert({
      title: this.context.lang('recipe', 'delete-confirm-title').toString(),
      message: this.context.lang('recipe', 'delete-confirm').toString(),
      buttons: [
        {
          label: this.context.lang('global', 'yes').toString(),
          onClick: () => action({ variables: { id: (this.props.recipe || {}).id } })
        },
        {
          label: this.context.lang('global', 'no').toString()
        }
      ]
    });
  }

  onDeleteComplete = () => {
    const { dispatch, push } = this.context;

    dispatch(push(`/devices/${this.props.device.id}/production`));
  }

  onTextChange = (field: string, root: boolean, e: SyntheticInputEvent<HTMLInputElement>) => {
    if (root) {
      this.setState({ values: { ...this.state.values, [field]: e.target.value } });
    } else {
      this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: e.target.value } } });
    }
  }

  onNumberChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    let value = parseInt(e.target.value, 10);

    if (e.target.max) {
      const max = parseInt(e.target.max, 10);
      if (value > max) {
        value = max;
      }
    }

    if (e.target.min) {
      const min = parseInt(e.target.min, 10);
      if (value < min) {
        value = min;
      }
    }

    this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: value } } });
  }

  onFloatChange = (field: string, e: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: parseFloat(e.target.value) } } });
  }

  onCheckboxChange = (field: string, checked: boolean) => {
    this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: checked } } });
  }

  onSelect = (field: string, option: { label: string, value: string }) => {
    this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: option.value } } });
  }

  onSelectId = (field: string, option: { label: string, value: string }) => {
    this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, [field]: { id: option.value } } } });
  }

  onDeviceSelect = (option: { label: string, value: string }) => {
    const { device } = this.props;
    let { division } = device.profile.version;

    if (device.profile.version.division === 'both') {
      division = 'single';
    }

    this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, division, device: { id: option.value } } } });
  }

  onDivisionSelect = (option: { label: string, value: string }) => {
    const { division } = this.state.values;
    if (division !== option.value) {
      this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, division: option.value, weight: null, yield: null } } });
    }
  }

  onPressingTimeSelect = (option: { label: string, value: string }) => {
    this.setState({ values: { ...this.state.values, profile: { ...this.state.values.profile, pressingTime: option.value, yield: null } } });
  }

  onWeightChange = (device: any, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { division } = this.state.values.profile;

    let warning;
    if (division === 'single' && (e.target.value < device.profile.singleWeightMin || e.target.value > device.profile.singleWeightMax)) {
      warning = 'invalid';
    }
    if (division === 'double' && (e.target.value < device.profile.doubleWeightMin || e.target.value > device.profile.doubleWeightMax)) {
      warning = 'invalid';
    }

    this.setState({ ...this.state, values: { ...this.state.values, profile: { ...this.state.values.profile, weight: parseInt(e.target.value, 10) } }, warnings: { ...this.state.warnings, weight: warning } });
  }

  onYieldChange = (device: any, e: SyntheticInputEvent<HTMLInputElement>) => {
    const { division } = this.state.values.profile;
    const yieldMax = this.getMaxYield(division);

    let warning;
    if (division === 'single' && (e.target.value < device.profile.singleYieldMin || e.target.value > yieldMax)) {
      warning = 'invalid';
    }
    if (division === 'double' && (e.target.value < device.profile.doubleYieldMin || e.target.value > yieldMax)) {
      warning = 'invalid';
    }

    this.setState({ ...this.state, values: { ...this.state.values, profile: { ...this.state.values.profile, yield: parseInt(e.target.value, 10) } }, warnings: { ...this.state.warnings, yield: warning } });
  }

  getRecipeForm = (values: any) => {
    const { device, meta } = this.props;
    const { lang } = this.context;
    const { errors, warnings } = this.state;

    if (this.props.device.profileType === 'CalybraProfile') {
      let divisions = [{ label: lang('device', 'single').s, value: 'single' }, { label: lang('device', 'double').s, value: 'double' }];
      if (device.profile.version.division === 'single') {
        divisions = [{ label: lang('device', 'single').s, value: 'single' }];
      }
      if (device.profile.version.division === 'double') {
        divisions = [{ label: lang('device', 'double').s, value: 'double' }];
      }
      const pressingTimes = meta.map((m) => { return { label: lang('recipe', `pressing-${m.key}`).s, value: m.value }; });

      return (
        <div className="cell grid-x">
          <label htmlFor="number" className="cell small-12 medium-6">
            <div>{ lang('recipe', 'number').s }</div>
            <input type="text" name="number" value={values.number || ''} placeholder={lang('recipe', 'autogenerated').s} readOnly />
          </label>
          <label htmlFor="description" className="cell small-12 medium-6">
            <div>{ lang('recipe', 'description').s }</div>
            <input type="text" name="description" value={values.description || ''} onChange={this.onTextChange.bind(null, 'description', true)} />
          </label>
          <label htmlFor="division" className="cell small-12 medium-6">
            <div>{ lang('recipe', 'division').s }</div>
            <Select classNamePrefix="Select" options={divisions} value={divisions.filter(e => e.value === values.profile.division)} onChange={this.onDivisionSelect} clearable={false} searchable={false} />
          </label>
          <label htmlFor="weight" className="cell small-12 medium-6">
            <div>{`${lang('recipe', 'weight').s} (gr)`}</div>
            <NumericInput className={errors.weight || warnings.weight ? 'error' : ''} name="weight" value={values.profile.weight} onChange={this.onWeightChange.bind(null, device)} />
          </label>
          <label htmlFor="mass" className="cell small-12 medium-6">
            <div>{`${lang('recipe', 'mass').s} (K)`}</div>
            <NumericInput float name="mass" value={values.profile.mass} onChange={this.onFloatChange.bind(null, 'mass')} />
          </label>
          <label htmlFor="pressingTimes" className="cell small-12 medium-6">
            <div>{ lang('recipe', 'pressing').s }</div>
            <Select classNamePrefix="Select" options={pressingTimes} value={pressingTimes.filter(e => e.value === values.profile.pressingTime)[0]} onChange={this.onPressingTimeSelect} clearable={false} searchable={false} />
          </label>
          <label htmlFor="yield" className="cell small-12 medium-6">
            <div>{ lang('recipe', 'yield').s }</div>
            <NumericInput className={(errors.yield || warnings.yield) ? 'error' : ''} name="yield" value={values.profile.yield} onChange={this.onYieldChange.bind(null, device)} />
          </label>
          { /* B1A */ }
          <div className="cell grid-x" style={device.profile.exit === 'frontal_single' ? { display: 'none' } : {}}>
            <label htmlFor="separatorSpeed" className="cell small-12 medium-6">
              <div>{`${lang('recipe', 'separatorSpeed').s} (%)`}</div>
              <NumericInput name="separatorSpeed" value={values.profile.separatorSpeed} onChange={this.onNumberChange.bind(null, 'separatorSpeed')} />
            </label>
          </div>
          { /* B1B */ }
          <div className="cell grid-x" style={(device.profile.exit !== 'side' || device.profile.rounder === false) ? { display: 'none' } : {}}>
            <label htmlFor="rounderSpeed" className="cell small-12 medium-6">
              <div>{`${lang('recipe', 'rounderSpeed').s} (%)`}</div>
              <NumericInput name="rounderSpeed" value={values.profile.rounderSpeed} onChange={this.onNumberChange.bind(null, 'rounderSpeed')} />
            </label>
          </div>
          { /* B2 */ }
          <div className="cell grid-x" style={device.profile.exit !== 'side' ? { display: 'none' } : {}}>
            <label htmlFor="exitSpeed" className="cell small-12 medium-6">
              <div>{`${lang('recipe', 'exitSpeed').s} (%)`}</div>
              <NumericInput name="exitSpeed" value={values.profile.exitSpeed} onChange={this.onNumberChange.bind(null, 'exitSpeed')} />
            </label>
          </div>
          <label htmlFor="correction" className="cell small-12 medium-6">
            <div>{ lang('recipe', 'correction').s }</div>
            <div className="grid-x">
              <div className="small-10 medium-8 large-9 cell">
                <NumericInput name="correction" style={{ width: '100%' }} value={values.profile.correction} onChange={this.onNumberChange.bind(null, 'correction')} />
              </div>
              <div className={`${styles.correction} small-2 medium-4 large-3 cell`}>
                <img src={require('../../images/correction.svg')} alt="" />
              </div>
            </div>
          </label>
          <div className="cell grid-x" style={device.profile.dusters > 0 ? {} : { display: 'none' }}>
            <label htmlFor="duster1" className="cell small-12 medium-6">
              <Checkbox title={lang('recipe', 'duster1').s} checked={values.profile.duster1} onChange={this.onCheckboxChange.bind(null, 'duster1')} />
            </label>
            <label htmlFor="duster1Speed" className="cell small-12 medium-6" style={values.profile.duster1 ? {} : { display: 'none' }}>
              <div>{`${lang('recipe', 'duster1Speed').s} (%)`}</div>
              <NumericInput name="duster1Speed" value={values.profile.duster1Speed} onChange={this.onNumberChange.bind(null, 'duster1Speed')} />
            </label>
          </div>
          <div className="cell grid-x" style={device.profile.dusters > 1 ? {} : { display: 'none' }}>
            <label htmlFor="duster2" className="cell small-12 medium-6">
              <Checkbox title={lang('recipe', 'duster2').s} checked={values.profile.duster2} onChange={this.onCheckboxChange.bind(null, 'duster2')} />
            </label>
            <label htmlFor="duster2Speed" className="cell small-12 medium-6" style={values.profile.duster2 ? {} : { display: 'none' }}>
              <div>{`${lang('recipe', 'duster2Speed').s} (%)`}</div>
              <NumericInput name="duster2Speed" value={values.profile.duster2Speed} onChange={this.onNumberChange.bind(null, 'duster2Speed')} />
            </label>
          </div>
        </div>
      );
    }
    // Lybra form instead
    return (
      <div className="cell grid-x">
        <label htmlFor="number" className="cell small-12 medium-6">
          <div>{ lang('recipe', 'number').s }</div>
          <input type="text" name="number" value={values.number || ''} placeholder={lang('recipe', 'autogenerated').s} readOnly />
        </label>
        <label htmlFor="description" className="cell small-12 medium-6">
          <div>{ lang('recipe', 'description').s }</div>
          <input type="text" name="description" value={values.description || ''} onChange={this.onTextChange.bind(null, 'description', true)} />
        </label>
        <label htmlFor="target" className="cell small-12 medium-6">
          <div>{ lang('recipe', 'target').s }</div>
          <NumericInput name="target" value={values.profile.target} onChange={this.onNumberChange.bind(null, 'target')} />
        </label>
        <label htmlFor="yield" className="cell small-12 medium-6">
          <div>{ lang('recipe', 'yield').s }</div>
          <NumericInput name="yield" value={values.profile.yield} onChange={this.onNumberChange.bind(null, 'yield')} />
        </label>
      </div>
    );
  }

  getMaxYield = (division: string) => {
    const { device, meta } = this.props;
    const { values } = this.state;
    const { profile } = values;

    const double = division === 'double';
    let yieldMax = double ? device.profile.doubleYieldMax : device.profile.singleYieldMax;
    const pressing = meta.filter(p => p.value === (profile.pressingTime || 0))[0];
    if (pressing) {
      yieldMax = double ? pressing.yieldDouble : pressing.yieldSingle;
    }
    return yieldMax;
  }

  getDeviceInfo = () => {
    const { device } = this.props;
    const { lang } = this.context;

    const warnings = Object.keys(this.state.warnings).filter(x => this.state.warnings[x]);
    const double = this.state.values.profile.division === 'double';
    const yieldMax = this.getMaxYield(this.state.values.profile.division);

    if (!device) {
      return <div>Please select a device</div>;
    }
    return (
      <div className={`cell small-12 ${styles.info}`}>
        <div className="subtitle">{ lang('device', 'device').s }</div>
        <span className="subtitle">{ `${device.model} - ${device.serial}` }</span>
        <div className="subtitle">{ lang('device', 'version').s }</div>
        <span className="subtitle">{ device.profile.version.version }</span>
        <div className="subtitle">{ lang('device', 'weightIncrement').s }</div>
        <span className="subtitle">{ lang('device', device.profile.weightIncrement).s }</span>
        <div className="subtitle">{ lang('device', 'exit').s }</div>
        <span className="subtitle">{ lang('device', device.profile.exit).s }</span>
        <div className="subtitle">{ lang('device', 'dusters').s }</div>
        <span className="subtitle">{ device.profile.dusters }</span>
        <div className={`${styles['info-box']} ${warnings.length > 0 ? styles['info-box--warning'] : ''}`}>
          <div className="subtitle">{ lang('recipe', 'weightRange').s }</div>
          <span className="subtitle">{`${double ? device.profile.doubleWeightMin : device.profile.singleWeightMin} - ${double ? device.profile.doubleWeightMax : device.profile.singleWeightMax} gr`}</span>
          <div className="subtitle">{ lang('recipe', 'yieldRange').s }</div>
          <span className="subtitle">{`${double ? device.profile.doubleYieldMin : device.profile.singleYieldMin} - ${yieldMax} pz/h`}</span>
        </div>
        { warnings.length > 0
          ? <div className={styles.warning}>{lang('recipe', 'warning').toString()}: {warnings.join(', ')}</div>
          : null
        }
      </div>
    );
  }

  getLybraForm = (values: any) => {
    const { device } = this.props;
    const { lang } = this.context;

    if (device.profileType === 'CalybraProfile') {
      if (!device.profile.lybra || !device.profile.lybra.id) {
        return null;
      }

      const b1 = (values.profile.weight + values.profile.lybraBackStep1) - 1;
      const b2 = values.profile.weight + values.profile.lybraBackStep1;
      const f1 = values.profile.weight + Math.floor(values.profile.lybraForwardStep2 / 2);
      const f2 = values.profile.weight + values.profile.lybraForwardStep2;
      const f3 = values.profile.weight + values.profile.lybraForwardStep2 + 1;

      return (
        <div className={`cell grid-x ${styles.lybra}`}>
          <div className="subtitle">{ lang('device', 'lybra').s }</div>
          <div className={styles['lybra-header']}><span className={styles.blue} /><span className={styles.blue} /><span className={styles.green} /><span className={styles.green} /><span className={styles.blue} /><span className={styles.blue} /></div>
          <div className={styles['lybra-header']}><span className={styles.red} /><span className={styles.green} /><span className={styles.green} /><span className={styles.green} /><span className={styles.green} /><span className={styles.red} /></div>
          <div className={styles['lybra-inputs']}>
            <div className={styles['lybra-cell']}>
              <span className={styles['lybra-empty']} />
              <span>{b1 ? `<${b1}gr` : ''}</span>
            </div>
            <div className={styles['lybra-cell']}>
              <div className={styles['input-container']}>
                <NumericInput name="lybraBackStep1" value={values.profile.lybraBackStep1} onChange={this.onNumberChange.bind(null, 'lybraBackStep1')} negativeOnly />
              </div>
              <span>{b2 ? `${b2}gr` : ''}</span>
            </div>
            <div className={styles['lybra-cell']}>
              <span className={styles['lybra-center']}>{values.profile.weight}</span>
            </div>
            <div className={styles['lybra-cell']}>
              <div className={styles['input-container']}>
                {Math.floor(values.profile.lybraForwardStep2 / 2)}
              </div>
              <span>{f1 ? `${f1}gr` : ''}</span>
            </div>
            <div className={styles['lybra-cell']}>
              <div className={styles['input-container']}>
                <NumericInput name="lybraForwardStep2" value={values.profile.lybraForwardStep2} onChange={this.onNumberChange.bind(null, 'lybraForwardStep2')} min={0} />
              </div>
              <span>{f2 ? `${f2}gr` : ''}</span>
            </div>
            <div className={styles['lybra-cell']}>
              <span className={styles['lybra-empty']} />
              <span>{f3 ? `>${f3}gr` : ''}</span>
            </div>
          </div>
        </div>
      );
    }

    const b = values.profile.target + values.profile.backStep;
    const f = values.profile.target + values.profile.forwardStep;

    return (
      <div className={`cell grid-x ${styles.lybra}`}>
        <div className={styles['lybra-header']}><span className={styles.red} /><span className={styles.green} /><span className={styles.green} /><span className={styles.green} /><span className={styles.red} /></div>
        <div className={styles['lybra-inputs']}>
          <div className={styles['lybra-cell']}>
            <span className={styles['lybra-empty']} />
            <span>&le;{b ? `${b - 1}gr` : ''}</span>
          </div>
          <div className={styles['lybra-cell']}>
            <div className={styles['input-container']}>
              <NumericInput name="backStep" value={values.profile.backStep} onChange={this.onNumberChange.bind(null, 'backStep')} negativeOnly />
            </div>
            <span>{b ? `${b}gr` : ''}</span>
          </div>
          <div className={styles['lybra-cell']}>
            <span className={styles['lybra-center']}>{values.profile.target}</span>
          </div>
          <div className={styles['lybra-cell']}>
            <div className={styles['input-container']}>
              <NumericInput name="forwardStep" value={values.profile.forwardStep} onChange={this.onNumberChange.bind(null, 'forwardStep')} min={0} />
            </div>
            <span>{f ? `${f}gr` : ''}</span>
          </div>
          <div className={styles['lybra-cell']}>
            <span className={styles['lybra-empty']} />
            <span>&ge;{f ? `${f + 1}gr` : ''}</span>
          </div>
        </div>
      </div>
    );
  }

  validate = (values: any, type: string) => {
    const errors = {};
    ['description'].forEach((f) => {
      if (!values[f] || `${values[f]}`.trim().length === 0) {
        errors[f] = 'empty';
      }
    });

    if (type === 'CalybraProfile') {
      ['weight', 'yield'].forEach((f) => {
        if (values.profile[f] === null || values.profile[f] === undefined || `${values.profile[f]}`.trim().length === 0) {
          errors[f] = 'empty';
        }
      });
    } else if (type === 'LybraProfile') {
      ['target'].forEach((f) => {
        if (values.profile[f] === null || values.profile[f] === undefined || `${values.profile[f]}`.trim().length === 0) {
          errors[f] = 'empty';
        }
      });
    }
    return errors;
  }

  render() {
    const { recipe, onCompleted, device } = this.props;
    const { lang } = this.context;
    const { values } = this.state;

    let mutation = createCalybraRecipe;
    if (recipe) {
      mutation = device.profileType === 'CalybraProfile' ? updateCalybraRecipe : updateLybraRecipe;
    } else {
      mutation = device.profileType === 'CalybraProfile' ? createCalybraRecipe : createLybraRecipe;
    }

    return (
      <Mutation mutation={mutation} onCompleted={onCompleted}>
        {(action, { loading, error }) => {
          return (
            <TabPanel tabsPosition="bottom" tabs={[lang('global', 'details-tab-title').s, lang('global', 'info-tab-title').s]}>
              <form className="grid-x page">
                <Breadcrumbs relative steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${device.model} - ${device.serial}`, url: `/devices/${device.id}` }, { title: lang('page', 'production').s, url: `/devices/${device.id}/production` }]} />
                <div className="spacer" />
                <div className="title cell small-12 medium-6">{ recipe ? `${lang('recipe', 'edit').s} ${recipe.description}` : lang('recipe', 'create').s }</div>
                <div className="title-action cell small-12 medium-6">
                  {loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onClick.bind(null, action, device)} value={recipe ? 'Update' : 'Create'} />}
                </div>
                <MutationError graphQLError={error} errors={this.state.errors} lang={lang} langKey="recipe" />
                { this.getRecipeForm(values) }
                { this.getLybraForm(values) }
                { recipe ?
                  <div className="grid-x small-12 cell centered" style={{ marginTop: '2rem' }}>
                    <Mutation mutation={recipeDeleteMutation} onCompleted={this.onDeleteComplete}>
                      {(deleteAction, result) => {
                        return result.loading ? <Spinner /> : <div className="outline-button--subtle small-10 medium-4 cell" onClick={this.onRecipeDelete.bind(null, deleteAction)}>{lang('recipe', 'delete').s}</div>;
                      }}
                    </Mutation>
                  </div>
                  : null
                }
              </form>
              <aside className="grid-x">
                { (!values.id) ? null : <Link target="_blank" to={`/print/recipes/${values.id}`} className="small-12 cell print-button show-for-large">{lang('global', 'print').s}</Link> }
                <div className="spacer" />
                { this.props.device.profileType === 'CalybraProfile' ? this.getDeviceInfo() : null }
              </aside>
            </TabPanel>
          );
        }}
      </Mutation>
    );
  }
}

export default DetailForm;
