import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import Spinner from '../common/spinner/Spinner';
import LinkCard from '../common/card/LinkCard';

import { Permissions } from '../../state/authorization';
import queries from '../../state/queries';
import Pager from '../common/pager/Pager';
import QueryError from '../common/errors/QueryError';

type Props = {
  customerId: ?string
}

type State = {
  page: number,
  order: string,
  search: string,
  debounce: boolean
}

class Devices extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func,
    can: PropTypes.func
  }

  state = { page: 1, order: 'model_ASC', search: '', debounce: false }

  onSearch = (e: SyntheticInputEvent<HTMLInputElement>) => {
    if (this.interval) {
      clearTimeout(this.interval);
    }

    this.setState({ search: e.target.value, debounce: true, page: 1 });

    this.interval = setTimeout(() => {
      this.setState({ debounce: false });
    }, 200);
  }

  onPage = (page: number) => {
    this.setState({ page });
  }

  interval = null;

  render() {
    const { lang, can } = this.context;
    const { page, order, search } = this.state;

    return (
      <div className="page full-page">
        <div className="grid-x">
          <div className="title medium-8 cell">{ lang('devices', 'devices').s }</div>
          <input type="text" placeholder="Search..." className="small-12 medium-4 cell search" value={this.state.search} onChange={this.onSearch} />
          <div className="spacer" />
        </div>
        <Query query={queries.devices.listSearch} variables={{ page, order, search, customerId: this.props.customerId }} fetchPolicy="network-only" skip={this.state.debounce}>
          {({ loading, error, data }) => {
            if (loading) return <Spinner absolute />;
            if (error) return <QueryError lang={lang} error={error.toString()} />;
            if (!data) return null;

            return (
              <div>
                <div className="action-header">
                  <div className="subtitle">{ lang('devices', 'devices').s }</div>
                  { can(Permissions.devices.devicesCreate) ? <Link to="/devices/new" className="outline-button">{ lang('devices', 'create').s }</Link> : null }
                </div>
                <div className="grid-x">
                  {
                    data.devices.results.map(d => <LinkCard className="small-12 medium-3 cell" title={`${d.model} - ${d.serial}`} tag={lang('devices', d.profileType).s} tagColor={d.profileType === 'CalybraProfile' ? 'tag-bg-orange' : 'tag-bg-yellow'} extra={d.customer.name} key={d.id} url={can(Permissions.devices.devicesDetail) ? `/devices/${d.id}` : `/devices/${d.id}/production`} />)
                  }
                </div>
                <Pager pages={data.devices.totalPages} current={page} onPage={this.onPage} />
              </div>
            );
          }}
        </Query>
      </div>
    );
  }
}

export default Devices;
