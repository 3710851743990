import React, { Component } from 'react';
import { Query, Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import PropTypes from 'prop-types';

import Spinner from '../common/spinner/Spinner';
import Breadcrumbs from '../common/breadcrumbs/Breadcrumbs';
import queries from '../../state/queries';
import QueryError from '../common/errors/QueryError';

type Props = {
  deviceId?: string,
  onCompleted: Function
}

type State = {
  errors: any
};

const mutation = gql`
  mutation createMaintenanceRequest($email: String!, $phone: String, $contactName: String!, $message: String!, $snapshot: String!, $alarms: String!, $device: DeviceRelationAttributes!) {
    createMaintenanceRequest(attributes: { email: $email, phone: $phone, contactName: $contactName, message: $message, snapshot: $snapshot, alarms: $alarms, device: $device }) {
      maintenanceRequest {
        id
      }
    }
  }
`;

class Maintenance extends Component<Props, State> {
  static contextTypes = {
    lang: PropTypes.func
  }

  state = {
    errors: {}
  }

  onSubmit = (action: Function, snapshot: string, alarms: string) => {
    // flowlint-line-ignore
    const variables = { email: this.email.value, phone: this.phone.value, contactName: this.contactName.value, message: this.message.value, device: { id: this.props.deviceId } };
    const errors = this.validate(variables);
    if (Object.keys(errors).length > 0) {
      this.setState({ errors });
      return;
    }
    action({ variables: { ...variables, snapshot, alarms } });
  }

  validate = (values: any) => {
    const errors = {};
    ['email', 'message', 'contactName'].forEach((f) => {
      if (!values[f] || values[f].trim().length === 0) {
        errors[f] = 'empty';
      }
    });
    return errors;
  }

  email = null
  phone = null
  contactName = null
  message = null

  render() {
    const { lang } = this.context;
    const { errors } = this.state;

    return (
      <div>
        <Mutation mutation={mutation} onCompleted={this.props.onCompleted}>
          {(action, mutationResult) => {
            return (
              <Query query={queries.devices.maintnanceRequestMetadata} variables={{ id: this.props.deviceId }} fetchPolicy="network-only">
                {({ loading, error, data }) => {
                  if (loading && this.props.deviceId) return <div className="page grid-x"><Spinner absolute /></div>;
                  if (error) return <div className="page grid-x"><QueryError lang={lang} error={error.toString()} /></div>;
                  if (!data) return null;

                  const snapshot = sessionStorage.getItem(`${data.device.remoteId}:data`) || '{}';
                  const alarms = sessionStorage.getItem(`${data.device.remoteId}:alarms`) || '[]';
                  return (
                    <div className="page grid-x">
                      <Breadcrumbs steps={[{ title: lang('devices', 'devices').s, url: '/devices' }, { title: `${data.device.model} - ${data.device.serial}`, url: `/devices/${this.props.deviceId || ''}` }]} relative />
                      <div className="spacer" />
                      <div className="grid-x cell">
                        <div className="title cell small-12 medium-6">{`${data.device.model} - ${data.device.serial}`}</div>
                        <div className="title-action cell small-12 medium-6">
                          {mutationResult.loading ? <Spinner /> : <input className="outline-button float-right" type="submit" onClick={this.onSubmit.bind(null, action, snapshot, alarms)} value={lang('global', 'send').toString()} />}
                        </div>
                      </div>
                      <form className="grid-x cell">
                        <label htmlFor="contactName" className="cell small-12">
                          <div>{ lang('customer', 'contactName').s }</div>
                          <input className={errors.contactName ? 'error' : ''} type="text" name="contactName" defaultValue={`${data.user.firstName} ${data.user.lastName}`} ref={(e) => { this.contactName = e; }} />
                        </label>
                        <label htmlFor="email" className="cell small-12 medium-6">
                          <div>{ lang('global', 'email').s }</div>
                          <input className={errors.email ? 'error' : ''} type="text" name="email" defaultValue={data.user.email} ref={(e) => { this.email = e; }} />
                        </label>
                        <label htmlFor="phone" className="cell small-12 medium-6">
                          <div>{ lang('global', 'phone').s }</div>
                          <input type="text" name="phone" defaultValue={data.user.customer.phone} ref={(e) => { this.phone = e; }} />
                        </label>
                        <label htmlFor="message" className="cell small-12">
                          <div>{ lang('global', 'message').s }</div>
                          <textarea className={errors.message ? 'error' : ''} rows="10" type="text" name="message" ref={(e) => { this.message = e; }} />
                        </label>
                      </form>
                    </div>
                  );
                }}
              </Query>
            );
          }}
        </Mutation>
      </div>
    );
  }
}

export default Maintenance;
